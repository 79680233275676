import React, { useState, useEffect, useContext } from 'react';

import { cfgToast } from '../../../myFunc';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { Button, Container, Form, Icon, Input, Message, Table, } from 'semantic-ui-react';


function CloseCommision(props) {
   const authContext = useContext(AuthContext);
   const [loading, setloading] = useState(false)
   const [commision_id, setcommision_id] = useState(null)
   const [rowData, setRowData] = useState(null);
   const [closeState, setcloseState] = useState(0);
   const [medit, setmedit] = useState(false)




   function getAllItems(user_id) {

      setRowData(null)
      setloading(true)
      ConnectWS("/warehouse/warehouses_elements", "get", authContext.tokenApp, null, { user_id: user_id }, function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);

         } else if (Number(response.status) === 200) {

            if (response.data.length > 0) {
               setRowData(response.data)

            } else {
               setRowData([])

            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);

         }
         setloading(false)


      })
   }


   useEffect(() => {
      setcloseState(0)
      setcommision_id(props.commision_id)
      setmedit(props.edit)
      if (props.jsonCommision) {
         getAllItems(props.jsonCommision.user_id_owner)
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props]);// eslint-disable-line react-hooks/exhaustive-deps

   const submit = e => {
      e.preventDefault();
      setcloseState(1)
   }

   function updateRow(mval, i) {
      let tmprowData = rowData
      tmprowData[i]["quantity_reserves_commision"] = Number(mval)
      setRowData(tmprowData)
   }

   function closeCommisionA() {
      //todo close 
      console.log(rowData)
      //props.setopenModalCC(false)
      //authContext.changeUpdataApp(authContext.updataApp + 1)


      let tmparr = []
      if (rowData) {
         rowData.forEach((row) => {
            if (row.quantity_reserves_commision) {
               tmparr.push({ commision_id: commision_id, warehouse_id: row.warehouse_id, element_id: row.element_id, quantity: row.quantity_reserves_commision })
            }

         })
      }

      if (tmparr.length > 0) {
         ConnectWS("/commisions/elements", "post", authContext.tokenApp, null, tmparr, function (response, logout) {
            if (logout === 1) {
               toast.error("Brak autoryzacji 401", cfgToast);

            } else if (Number(response.status) === 200) {
               toast.success(response.data.detail, cfgToast);
               ConnectWS("/commisions/commision_status", "put", authContext.tokenApp, null, { commision_id: commision_id, status_id: 5 }, function (response, logout) {
                  if (logout === 1) {
                     toast.error(response, cfgToast);
                  } else if (Number(response.status) === 200) {
                     //toast.success(response.data.detail, cfgToast);
                     authContext.changeUpdataApp(authContext.updataApp + 1)
                  } else {
                     toast.error(response, cfgToast);
                     console.log(response);
                  }

               })

               if (medit) {
                  props.setOpen(false)
               } else {
                  props.setopenModalCC(false)
               }


            } else {
               toast.error(response, cfgToast);

            }



         })
      } else {
         ConnectWS("/commisions/commision_status", "put", authContext.tokenApp, null, { commision_id: commision_id, status_id: 5 }, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {
               //toast.success(response.data.detail, cfgToast);
               authContext.changeUpdataApp(authContext.updataApp + 1)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }

         })

         if (medit) {
            props.setOpen(false)
         } else {
            props.setopenModalCC(false)
         }
      }
      return

   }


   return (
      closeState === 0 ?
         <Form onSubmit={submit}>
            <Message >
               <Message.Header>Elementy użyte podczas serwisu</Message.Header>
               <p>
                  Proszę wskazać jakie elementy zostały wykorzystane podczas serwisu.
               </p>
            </Message>

            <Table celled compact size='small'>
               <Table.Header>
                  <Table.Row textAlign='center'>
                     <Table.HeaderCell>Element</Table.HeaderCell>
                     <Table.HeaderCell>Ilość dostępna</Table.HeaderCell>
                     <Table.HeaderCell>Ilość użyta podczas serwisu</Table.HeaderCell>
                  </Table.Row>
               </Table.Header>

               <Table.Body >
                  {rowData ? rowData.map((row, i) =>
                     <Table.Row key={i}>
                        <Table.Cell textAlign='center'>
                           {row.name}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>{row.quantity}</Table.Cell>
                        <Table.Cell textAlign='center'><Input fluid type='number' value={row.quantity_reserves} max={row.quantity} onChange={(e, d) => updateRow(d.value, i)} placeholder='Wprowadź ilość' /></Table.Cell>
                     </Table.Row>
                  ) : ""}


               </Table.Body>
            </Table>
            {loading ? <Container style={{ marginBottom: 10 }} textAlign='center'><Icon name="spinner" loading /></Container> : ""}
            {medit ?
               <Button fluid color='blue'>Edytuj</Button>
               :
               <Button fluid color='orange'>ZAKOŃCZ ZLECENIE</Button>}

         </Form>
         : <>
            <Button fluid onClick={() => setcloseState(0)}>POWRÓT</Button>
            <Table celled compact size='small'>
               <Table.Header>
                  <Table.Row textAlign='center'>
                     <Table.HeaderCell>Element</Table.HeaderCell>
                     <Table.HeaderCell>Ilość dostępna</Table.HeaderCell>
                     <Table.HeaderCell>Ilość użyta podczas serwisu</Table.HeaderCell>
                  </Table.Row>
               </Table.Header>

               <Table.Body >
                  {rowData ? rowData.map((row, i) =>
                     row.quantity_reserves_commision ?
                        <Table.Row key={i}>
                           <Table.Cell textAlign='center'>
                              {row.name}
                           </Table.Cell>
                           <Table.Cell textAlign='center'>{row.quantity}</Table.Cell>
                           <Table.Cell textAlign='center'>{row.quantity_reserves_commision}</Table.Cell>
                        </Table.Row>
                        : ""
                  ) : ""}


               </Table.Body>
            </Table>
            {medit ?
               <Button fluid color='blue' onClick={() => closeCommisionA()}>Zapisz</Button>
               :
               <Button fluid color='red' onClick={() => closeCommisionA()}>POTWIERDZAM I ZAMYKAM ZLECENIE</Button>}
         </>
   );
}

export default CloseCommision;