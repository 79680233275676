import React, { useEffect, useState } from 'react';
import 'beautiful-react-diagrams/styles.css';
import Diagram, { useSchema as usSchema, createSchema, validateSchema, validateNodes, validateNode, validateLinks, validateLink, validatePort } from 'beautiful-react-diagrams';
import { Card, Grid, Image, Message, Segment } from 'semantic-ui-react';
import fopng from '../../../assets/images/fo.png';
import labelpng from '../../../assets/images/label.png';
import styles from './SwitchDiagram.module.css';
import { ToggleButton } from 'primereact/togglebutton';
import { ScrollPanel } from 'primereact/scrollpanel';
import useWindowDimensions from '../../../hooks/useWindowDimensions';


export function ErrorFallback({ error, resetErrorBoundary }) {
   return (
      <Message warning>
               <Message.Header>Błąd</Message.Header>
               <p>Nie udało utworzyć się graficznego połaczenia.</p>
            </Message>
   )
}


export function prepareJsonTabToDiag(tmp, tmp2, check2nd) {

   let nodes = []
   let links = []
   let rowprew = {}
   let rowprew2nd = {}

   // zabezpieczenie jezeli jest jedna tablica
   if (tmp2.length < 1) {
      tmp2 = tmp
   }

   tmp.forEach((row, i) => {
      tmp2.forEach((rowj, j) => {
         if (i === j) {
            //start wyjscia
            if (i === 0) {
               nodes.push({
                  id: 'node-' + (i + 1),
                  content: row.pointAID,
                  inputs: [],
                  outputs: check2nd === false ? [
                     { id: row.pointAID + "-" + row.pointAPort + "-" + i + "o", status: row.aliveA, content: row.pointAID },
                  ] : [
                     { id: row.pointAID + "-" + row.pointAPort + "-" + i + "o", status: row.aliveA, content: row.pointAID },
                     { id: rowj.pointAID + "-" + rowj.pointAPort + "-" + j + "o", status: rowj.aliveA, content: rowj.pointAID }
                  ],
                  data: { ip: "START", id: "", status: 1, connectionsType: row.connectionsType }
               })
            }
            //end
            else if ((tmp.length - 1) === i) {
               nodes.push({
                  id: 'node-' + (i + 1),
                  content: 'Przełacznica',
                  inputs: check2nd === false ? [
                     { id: rowprew.pointBID + "-" + rowprew.pointBPort + "-" + i + "i", status: rowprew.aliveB, content: rowprew.pointBPort },
                  ] : [
                     { id: rowprew.pointBID + "-" + rowprew.pointBPort + "-" + i + "i", status: rowprew.aliveB, content: rowprew.pointBPort },
                     { id: rowprew2nd.pointBID + "-" + rowprew2nd.pointBPort + "-" + i + "i", status: rowprew2nd.aliveB, content: rowprew2nd.pointBPort }
                  ],
                  outputs: check2nd === false ? [
                     { id: row.pointAID + "-" + row.pointAPort + "-" + i + "o", status: row.aliveA, content: row.pointAPort },
                  ] : [
                     { id: row.pointAID + "-" + row.pointAPort + "-" + i + "o", status: row.aliveA, content: row.pointAPort },
                     { id: rowj.pointAID + "-" + rowj.pointAPort + "-" + i + "o", status: rowj.aliveA, content: rowj.pointAPort }
                  ],
                  data: { ip: row.ip ? row.ip : "", id: row.pointAID, status: 1, connectionsType: rowprew.connectionsType }
               })
               nodes.push({
                  id: 'node-' + (i + 2),
                  content: rowj.pointBID,
                  inputs: check2nd === false ? [
                     { id: row.pointBID + "-" + row.pointBPort + "-" + (i + 1) + "i", status: row.aliveB, content: row.pointBID },
                  ] : [
                     { id: row.pointBID + "-" + row.pointBPort + "-" + (i + 1) + "i", status: row.aliveB, content: row.pointBID },
                     { id: rowj.pointBID + "-" + rowj.pointBPort + "-" + (i + 1) + "i", status: rowj.aliveB, content: rowj.pointBID }
                  ],
                  outputs: [],
                  data: { ip: "KONIEC", id: "", status: 1, connectionsType: row.connectionsType }
               })
            }
            //loop node
            else {
               nodes.push({
                  id: 'node-' + (i + 1),
                  content: 'Przełacznica',
                  inputs: check2nd === false ? [
                     { id: rowprew.pointBID + "-" + rowprew.pointBPort + "-" + i + "i", status: rowprew.aliveB, content: rowprew.pointBPort },
                  ] : [
                     { id: rowprew.pointBID + "-" + rowprew.pointBPort + "-" + i + "i", status: rowprew.aliveB, content: rowprew.pointBPort },
                     { id: rowprew2nd.pointBID + "-" + rowprew2nd.pointBPort + "-" + i + "i", status: rowprew2nd.aliveB, content: rowprew2nd.pointBPort }
                  ],
                  outputs: check2nd === false ? [
                     { id: row.pointAID + "-" + row.pointAPort + "-" + i + "o", status: row.aliveA, content: row.pointAPort },
                  ] : [
                     { id: row.pointAID + "-" + row.pointAPort + "-" + i + "o", status: row.aliveA, content: row.pointAPort },
                     { id: rowj.pointAID + "-" + rowj.pointAPort + "-" + i + "o", status: rowj.aliveA, content: rowj.pointAPort }
                  ],
                  data: { ip: row.ip ? row.ip : "", id: row.pointAID, status: 1, connectionsType: row.connectionsType }
               })
            }

            links.push({ input: row.pointAID + "-" + row.pointAPort + "-" + i + "o", output: row.pointBID + "-" + row.pointBPort + "-" + (i + 1) + "i", label: row.connectionsType })
            if (check2nd) {
               links.push({ input: rowj.pointAID + "-" + rowj.pointAPort + "-" + i + "o", output: rowj.pointBID + "-" + rowj.pointBPort + "-" + (i + 1) + "i", label: rowj.connectionsType })
            }

            rowprew = row
            rowprew2nd = rowj
         }
      })
   })
   if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log({ nodes: nodes, links: links })
   }
   return { nodes: nodes, links: links }
}



function SwitchDiagram(props) {

   const [showAllPorts, setshowAllPorts] = useState(false);
   const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
   const [showError, setshowError] = useState(false);


   const CustomNode = (props) => {
      const { inputs, outputs } = props;
      return (
         <Card className='clearmp' style={{ width: 100 }} centered color={"grey"}>

            <Card.Content style={{ padding: 5, margin: 0, lineHeight: "5px", textAlign: "center", backgroundColor: "#f5f5f5" }}>
               <Card.Header style={{ fontSize: props.data.connectionsType === "external" ? 14 : 11, fontWeight: "normal" }}>{props.content}<br />{props.data.id}</Card.Header>
               <Card.Meta style={{ fontSize: 11, padding: 2 }}>{props.data.ip}</Card.Meta>
               {props.data.connectionsType === "external" ? <Image src={labelpng} style={{ height: 27, padding: 3 }} /> : <Image src={fopng} style={{ height: 30 }} />}
            </Card.Content>
            <Card.Content extra style={{ padding: 2, margin: 0, backgroundColor: "#e6e6e6" }}>

               <Grid className='clearmp'>
                  <Grid.Row className='clearmp'>
                     <Grid.Column width={8} textAlign="left" style={{ margin: 0, padding: 1 }}>
                        {inputs.map((port) => React.cloneElement(port, {
                           className: `boxname ${styles.box} ${port.props.status === 0 ? styles.boxerr : styles.boxok}`, title: "PORT: " + port.props.content, databeforecontent: props.data.connectionsType === "external" ? "B" : port.props.content
                        }))}
                     </Grid.Column>
                     <Grid.Column width={8} textAlign="right" style={{ margin: 0, padding: 1 }}>
                        {outputs.map((port) => React.cloneElement(port, {
                           className: `boxname ${styles.box} ${port.props.status === 0 ? styles.boxerr : styles.boxok}`, title: "PORT: " + port.props.content, databeforecontent: props.data.connectionsType === "external" ? "A" : port.props.content
                        }))}
                     </Grid.Column>
                  </Grid.Row>
               </Grid>
               {showAllPorts ?

                  <Grid className='clearmp'>
                     <span className={styles.otherports}>POZOSTAŁE</span>
                     <Grid.Row className='clearmp' columns={2}>
                        {props.data.lockPorts ? props.data.lockPorts.map((portname, i) =>
                           <Grid.Column key={i} width={8} textAlign="center" style={{ margin: 0, padding: 1 }} title={"PORT ZAJĘTY: " + portname}>
                              <div className={`${styles.box} ${styles.boxlock}`}>
                                 {portname}
                              </div>
                           </Grid.Column>
                        ) : ""}
                        {props.data.freePorts ? props.data.freePorts.map((portname, i) =>
                           <Grid.Column key={i} width={8} textAlign="center" style={{ margin: 0, padding: 1 }} title={"PORT WOLNY: " + portname}>
                              <div className={`${styles.box} ${styles.boxfree}`}>
                                 {portname}
                              </div>
                           </Grid.Column>
                        ) : ""}
                     </Grid.Row>
                  </Grid>

                  : ""}
            </Card.Content>
         </Card>
      );
   };


   const [initialSchema, setinitialSchema] = useState(createSchema(null));// eslint-disable-line no-unused-vars
   const [schema, setschema] = useState(usSchema(initialSchema)[0]);




   function redrawDiagram(jsontmp) {

      try {
         const schematmp = createSchema(jsontmp)
        // console.log("schema", schematmp)
         setshowError(false)

         validateSchema(schematmp);
         validateNodes(schematmp.nodes)
         validateLinks(schematmp.links)


         for (let j = 0; j < schematmp.nodes.length; j++) {

            validateNode(schematmp.nodes[j])
            for (let k = 0; k < schematmp.nodes[j].inputs.length; k++) {

               validatePort(schematmp.nodes[j].inputs[k])
            }

            for (let k1 = 0; k1 < schematmp.nodes[j].outputs.length; k1++) {

               validatePort(schematmp.nodes[j].outputs[k1])
            }

         }
         for (let i = 0; i < schematmp.links.length; i++) {

            validateLink(schematmp.links[i])
         }


         setschema(schematmp)
      } catch (err) {
         console.log(err)
         setshowError(true)
      }

   }


   function prepareJson(json) {
      for (var i in json.nodes) {
         json.nodes[i].coordinates = [50 + (170 * i), 50];
         json.nodes[i].render = CustomNode;
         json.nodes[i].disableDrag = true;
      }

      return json
   }



   useEffect(() => {
      /*
      let jsontmp = {
         nodes: [
            {
               id: 'node-1',
               content: 'Switch 1',
               coordinates: [50, 50],
               render: CustomNode,
               outputs: [
                  { id: '1-1-o', alignment: 'right', status: 1, content: "1.1" },
               ],
               disableDrag: true,
               data: { ip: "192.168.1.101", status: 1, lockPorts: ["1.2", "1.3", "1.4", "1.5", "1.8", "1.9"], freePorts: ["1.6", "1.7", "1.10", "1.11", "1.12"] }
            },
            {
               id: 'node-2',
               content: 'Switch 2',
               coordinates: [200, 50],
               //render: CustomNode,
               inputs: [
                  { id: '2-1-i', alignment: 'right', status: 2, content: "2.1" },
               ],
               outputs: [
                  { id: '2-1-o', alignment: 'right', status: 2, content: "2.3" },
               ],
               disableDrag: true,
               data: { ip: "192.168.1.102", status: 2, lockPorts: ["2.2", "2.4", "2.5", "2.8", "2.9"], freePorts: ["2.6", "2.7", "2.10", "2.11", "2.12"] }
            },
            {
               id: 'node-3',
               content: 'Switch 3',
               coordinates: [350, 50],
              // render: CustomNode,
               inputs: [
                  { id: '3-1-i', alignment: 'right', status: 1, content: "3.1" },
               ],
               outputs: [
                  { id: '3-3-o', alignment: 'right', status: 1, content: "3.3" },
               ],
               disableDrag: true,
               data: { ip: "192.168.1.103", status: 1, lockPorts: ["3.2", "3.4", "3.5", "3.8", "3.9"], freePorts: ["3.6", "3.7", "3.10", "3.11", "3.12"] }
            },
            {
               id: 'node-4',
               content: 'Switch 4',
               coordinates: [500, 50],
             //  render: CustomNode,
               inputs: [
                  { id: '4-2-i', alignment: 'right', status: 1, content: "4.2" },
               ],
               outputs: [
                  { id: '4-3-o', alignment: 'right', status: 1, content: "4.3" },
               ],
               disableDrag: true,
               data: { ip: "192.168.1.104", status: 1, lockPorts: ["4.1", "4.4", "4.5", "4.8", "4.9"], freePorts: ["4.6", "4.7", "4.10", "4.11", "4.12"] }
            },
            {
               id: 'node-5',
               content: 'Switch 5',
               coordinates: [650, 50],
              // render: CustomNode,
               inputs: [
                  { id: '5-1-i', alignment: 'right', status: 1, content: "5.1" },
               ],
               disableDrag: true,
               data: { ip: "192.168.1.105", status: 1, lockPorts: ["5.2", "5.3", "5.4", "5.5", "5.8", "5.9"], freePorts: ["5.6", "5.7", "5.10", "5.11", "5.12"] }
            },
         ],
         links: [
            { input: '1-1-o', output: '2-1-i' },
            { input: '2-1-o', output: '3-1-i' },
            { input: '3-3-o', output: '4-2-i' },
            { input: '4-3-o', output: '5-1-i' },
         ]
      }
      */

      let json = props.schemaJson ? props.schemaJson : {}
      redrawDiagram(prepareJson(json))
      //console.log("DARW")
      return () => {
         //clearInterval(interval.current) 
      };
   }, [showAllPorts, props.schemaJson]) // eslint-disable-line react-hooks/exhaustive-deps





   return (
      <>
         {props.showLegends ?
            <Segment >

               {770 < width ?
                  <Grid stretched stackable columns={5} >
                     <Grid.Column >
                        <ToggleButton onLabel="UKRYJ WOLNE/ZAJĘTE PORTY" offLabel="POKAŻ WOLNE/ZAJĘTE PORTY" onIcon="pi pi-eye-slash" offIcon="pi pi-eye" checked={showAllPorts} onChange={(e) => setshowAllPorts(e.value)} />
                     </Grid.Column>
                     <Grid.Column><div className={`${styles.box} ${styles.boxfree}`}>PORTY WOLNE</div></Grid.Column>
                     <Grid.Column><div className={`${styles.box} ${styles.boxlock}`}>PORTY ZAJĘTE</div></Grid.Column>
                     <Grid.Column><div className={`${styles.box} ${styles.boxerr}`}>PORTY NIEDZIAŁAJĄCE</div></Grid.Column>
                     <Grid.Column><div className={`${styles.box} ${styles.boxok}`}>PORTY DZIAŁAJĄCE</div></Grid.Column>
                  </Grid> : <Grid stretched stackable columns={1} > <Grid.Column>
                     <ToggleButton onLabel="UKRYJ WOLNE/ZAJĘTE PORTY" offLabel="POKAŻ WOLNE/ZAJĘTE PORTY" onIcon="pi pi-eye-slash" offIcon="pi pi-eye" checked={showAllPorts} onChange={(e) => setshowAllPorts(e.value)} />
                     <div className={`${styles.box} ${styles.boxfree}`}>PORTY WOLNE</div>
                     <div className={`${styles.box} ${styles.boxlock}`}>PORTY ZAJĘTE</div>
                     <div className={`${styles.box} ${styles.boxerr}`}>PORTY NIEDZIAŁAJĄCE</div>
                     <div className={`${styles.box} ${styles.boxok}`}>PORTY DZIAŁAJĄCE</div></Grid.Column>
                  </Grid>}

            </Segment>
            : ""}

        
            {showError ? <Message warning>
               <Message.Header>Błąd</Message.Header>
               <p>Nie udało utworzyć się graficznego połaczenia.</p>
            </Message>
               : <ScrollPanel style={{ width: '100%' }}> <div style={{ paddingBottom: 10, paddingTop: 10, minHeight: 1000 < width ? props.minHeight ? props.minHeight - 120 : 400 : props.minHeight ? props.minHeight - 120 : 400, height: 1, width: calcWidth(schema, width), margin: "0 auto" }}>
                  <Diagram schema={schema} />
               </div></ScrollPanel>}

         

      </>
   );
}


function calcWidth(schema, width) {
   //schema.nodes.length
   let ret = 180 * schema.nodes.length;
   if (ret > width) {
      return ret
   } else {
      //return "99%"
      return ret
   }

}

export default SwitchDiagram;