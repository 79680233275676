import React, { useEffect, useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Button as ButtonPrime } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthContext from '../../../context/authContext';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import { cfgToast } from '../../../myFunc';
function ViewCommisionInputTextAddWorker(props) {
   const authContext = useContext(AuthContext);

   const postfixt = "row";

   function normalizeInput(text) {
      return text + postfixt
   }

   const [isloading, setisloading] = useState(false); // eslint-disable-line no-unused-vars
   const { control, formState: { errors }, handleSubmit, setValue } = useForm();
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const submit = (data) => {
      let tmparr = []
      for (var key in data) {
         if (data.hasOwnProperty(key)) {
            let tmp = key.replaceAll(postfixt, '')
            tmparr.push({
               //"name": "name",
               "commision_field_id": Number(tmp),
               "value": data[key]
             })
         }
      }

      ConnectWS("/commisions/fields", "put", authContext.tokenApp, null, tmparr, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            props.getListArea(props.commision_id)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }


   useEffect(() => {

      if (props.jsonFields !== null && props.jsonFields.length > 0) {
         props.jsonFields.forEach((row) =>
            setValue(normalizeInput(row.commision_field_id), row.value)
         )
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props.tabInputText, authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps



   return (
      <>

         {props.jsonFields !== null && props.jsonFields.length > 0 ?
            <form onSubmit={handleSubmit(submit)} className="p-fluid">
               {props.jsonFields.map((row, i) =>
                  <div className="field" style={{ paddingTop: 10 }}>
                     <span className="p-float-label p-input-icon-right">
                        <Controller name={normalizeInput(row.commision_field_id)} control={control} rules={{ required: 'pole jest wymagane' }} render={({ field, fieldState }) => (
                           <InputTextarea rows={5}  autoResize id={field.name} readOnly={props.checkCommisionIsClose1()} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        <label htmlFor={normalizeInput(row.commision_field_id)} className={classNames({ 'p-error': errors.name })}>{row.name}*</label>
                     </span>
                     {getFormErrorMessage(normalizeInput(row.commision_field_id))}
                  </div>
               )}
               <ButtonPrime loading={isloading} disabled={props.checkCommisionIsClose1()} type="submit" label="ZAPISZ OPISY" className="mt-2 p-button p-button-secondary" />
            </form>
            : ""}

      </>
   );
}

export default ViewCommisionInputTextAddWorker;