import React, { useEffect, useState, useContext } from 'react';
import { Button,  Icon, Message } from 'semantic-ui-react';

import { ConnectWS } from '../../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../../context/authContext';
import { cfgToast } from '../../../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

import { Button as ButtonPrime } from 'primereact/button';

import { Card } from 'primereact/card';

import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

function EditItem(props) {
   const authContext = useContext(AuthContext);
   const defaultValues = {
      element_id: 0,
      name: "",
      description: "",
      short_name: "",
      ean_number: "",
      serial_number: "",
      enabled: 1,
      warehouse_id:0,
      unit:"",
      quantity:0

   }

   const unit = [
      { label: 'Sztuk', value: 'Sztuk' },
      { label: 'Kilometry', value: 'Kilometry' },
      { label: 'Metry', value: 'Metry' },
  ];



 

   const [delObjectQ, setdelObjectQ] = useState(false);

   const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

   const [isloading, setisloading] = useState(false);

   const [element_id, setelement_id] = useState(0);
   const [warehouse_id, setwarehouse_id] = useState(0);
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };



   useEffect(() => {

      if (props.userInfo.allInfo) {
         console.log(props.userInfo.allInfo)
         setValue("name", props.userInfo.allInfo.name)
         setValue("description", props.userInfo.allInfo.description)
         setValue("short_name", props.userInfo.allInfo.short_name)
         setValue("element_id", props.userInfo.allInfo.element_id)
         setelement_id(props.userInfo.allInfo.element_id)
         setwarehouse_id(props.userInfo.allInfo.warehouse_id)
         setValue("ean_number", props.userInfo.allInfo.ean_number)
         //setChecked(props.userInfo.allInfo.enabled ? 1 : 0)
         setValue("serial_number", props.userInfo.allInfo.serial_number)
         setValue("warehouse_id", props.userInfo.allInfo.warehouse_id)
         setValue("unit", props.userInfo.allInfo.unit)
         setValue("quantity", props.userInfo.allInfo.quantity)

    
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props]);// eslint-disable-line react-hooks/exhaustive-deps


   const submit = (data) => {
      setisloading(true)

      ConnectWS("/warehouse/element", "put", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }




   function delObjectQAcc() {
      ConnectWS("/warehouse/warehouses_elements", "delete", authContext.tokenApp, null,{element_id:element_id,warehouse_id:warehouse_id}, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp+1)
            props.setOpen(false)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })
   }

   return (
      delObjectQ ? <Card className="bgcard">
         <Message icon>
            <Icon name='help circle' />
            <Message.Content>
               <Message.Header>Czy chcesz skasować element?</Message.Header>
               Proszę potwierdzić
            </Message.Content>
            <Button color='red' onClick={() => delObjectQAcc()}>TAK</Button>
            <Button onClick={() => setdelObjectQ(false)}>NIE</Button>
         </Message>

      </Card> : <>
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

            <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">
           
                     <Controller name="name" control={control} rules={{ required: 'Nazwa elementu jest wymagana' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nazwa elementu*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">
           
                     <Controller name="description" control={control}  render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>Opis</label>
                  </span>
                  {getFormErrorMessage('description')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">
     
                     <Controller name="short_name" control={control}  render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="short_name" className={classNames({ 'p-error': errors.short_name })}>Krótka nazwa</label>
                  </span>
                  {getFormErrorMessage('short_name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label ">
               
                     <Controller name="ean_number" control={control}  render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="ean_number" className={classNames({ 'p-error': errors.ean_number })}>EAN</label>
                  </span>
                  {getFormErrorMessage('ean_number')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label">
                  
                     <Controller name="serial_number" control={control}  render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="serial_number" className={classNames({ 'p-error': errors.serial_number })}>Numer seryjny</label>
                  </span>
                  {getFormErrorMessage('serial_number')}
               </div>

{/* 
               <div className="field-checkbox" style={{ paddingTop: 10 }}>
                  <Controller name="enabled" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                     <InputSwitch inputId={field.name} onChange={(e) => {
                        field.onChange(e.value ? 1 : 0)
                        setChecked(e.value ? 1 : 0)
                     }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                  )} />
                  <label htmlFor="enabled" className={classNames({ 'p-error': errors.enabled })}>{checked ? "ELEMENT AKTYWNY" : "ELEMENT NIEAKTYWNY"}</label>

               </div>

               
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="warehouse_id" control={control} rules={{ required: 'Magazyn jest wymagany' }} render={({ field, fieldState }) => (
                        <AutoComplete  suggestions={filteredItems} completeMethod={searchItems} virtualScrollerOptions={{ itemSize: 38 }} field="label" dropdown id={field.name} value={field.value} onChange={(e) => {
                           field.onChange(e.value)
                        }} className={classNames({ 'p-invalid': fieldState.invalid })} dropdownAriaLabel="Wybierz magazyn" forceSelection />
                     )} />
                     <label htmlFor="warehouse_id" className={classNames({ 'p-error': errors.warehouse_id })}>Magazyn główny</label>
                  </span>
                  {getFormErrorMessage('warehouse_id')}
               </div>
               */}
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="unit" control={control} rules={{ required: 'Jednostka jest wymagana' }}  render={({ field, fieldState }) => (
                        <Dropdown  options={unit} id={field.name} value={field.value} onChange={(e) => {
                           field.onChange(e.value)
                        }} className={classNames({ 'p-invalid': fieldState.invalid })} placeholder="Jednostka"/>
                  
                     )} />
                     <label htmlFor="unit" className={classNames({ 'p-error': errors.unit })}>Jednostka</label>
                  </span>
                  {getFormErrorMessage('unit')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="quantity" control={control} rules={{ required: 'Ilość jest wymagana.', min: { value: 1, message: 'Błędna ilość min 1' } }}  render={({ field, fieldState }) => (
                                    <InputNumber mode="decimal"  inputId="horizontal" id={field.name} value={field.value} onValueChange={(e) => field.onChange(e.value)} showButtons buttonLayout="horizontal" step={1}
                                    decrementButtonClassName="p-button-danger" className={classNames({ 'p-invalid': fieldState.invalid })} incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                  
                     )} />
                     <label htmlFor="quantity" className={classNames({ 'p-error': errors.quantity })}>Ilość</label>
                  </span>
                  {getFormErrorMessage('quantity')}
               </div>

               <ButtonPrime loading={isloading} type="submit" label="Zapisz dane" className="mt-2 p-button" />
            </form>


         </Card>
         <Button color="grey" style={{ marginTop: 10 }} fluid icon labelPosition='right' onClick={() => setdelObjectQ(true)}>
            <Icon name='trash' />
            KASOWANIE ELEMENTU
         </Button>
      </>
   );
}

export default EditItem;