import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Label, Modal, Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';

import ModalUI from '../../layout/ModalUI';
import AppEditUser from './AppEditUser';
import AGGridActivity from '../AGGrid/AGGridActivity';


function AppPermissions(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);
   const [gridApiP, setGridApiP] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApiP, setGridColumnApiP] = useState(null);// eslint-disable-line no-unused-vars
   const [rowDataP, setRowDataP] = useState(null);
   const [open, setOpen] = useState(false)
   const [propsUiNameData, setpropsUiNameData] = useState(null);

   const [isloading3, setisloading3] = useState(false)
   const [isloading1, setisloading1] = useState(false) // eslint-disable-line no-unused-vars
   const [isloading2, setisloading2] = useState(false) // eslint-disable-line no-unused-vars

   const [form, setForm] = useState({
      userid: 0,
      allInfo: null
   });

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   const onGridReadyP = (params) => {
      setGridApiP(params.api);
      setGridColumnApiP(params.columnApi);
   };

   function getAllPermissions(userid) {
      setisloading2(true)
      if (userid > 0) {
         ConnectWS("/infiber/user_functions", "get", authContext.tokenApp, null, { user_id: userid }, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {
               setRowDataP(response.data)
               SelectedRowTab()
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }
         })
      } else {
         ConnectWS("/infiber/functions", "get", authContext.tokenApp, null, null, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {
               setRowDataP(response.data)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }
            setisloading2(false)
         })
      }


   }

   function getAllUsers() {
      setisloading1(true)
      ConnectWS("/users", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            setRowData(response.data)

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         onSelectionChanged()
         setisloading1(false)
      })
   }

   function SelectedRowTab() {

      if (null !== gridApiP) {
         gridApiP.forEachNode(function (node) {
            node.setSelected(node.data.enabled === 1 ? true : false);
         });
      }
   }

   useEffect(() => {

      getAllUsers()
      getAllPermissions(form.userid)
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   const onSelectionChanged = () => {
      if (gridApi) {
         var selectedRows = gridApi.getSelectedRows();
         if (selectedRows.length === 1) {
            let userid = selectedRows[0]["user_id"]
            setForm({ ...form, userid: userid, allInfo: selectedRows[0] })
            getAllPermissions(userid)
         }
         else {
            setForm({ ...form, userid: 0, allInfo: null })
         }
      }
   };

   const isactiveIcon = props => {
      let ret
      props.data.enabled ? ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="Aktywny" >
         AKTYWNY
      </Label> : ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='red' title="Aktywny" >
         NIEAKTYWNY
      </Label>
      return ret
   };



   function editUser() {

      if (form.userid > 0) {
         setpropsUiNameData("Edycja użytkownika")
         setOpen(true)
      } else {
         toast.warning("Proszę zaznaczyć użytkownika w tabeli", cfgToast);
      }
   }

   function savePermissions() {

      var selectedRows = gridApiP.getSelectedRows();
      let selectedData = selectedRows.map(row => row.function_id);
      let userId = form.userid

      if (selectedData.length > 0) {
         setisloading3(true)
         ConnectWS("/infiber/user_functions", "post", authContext.tokenApp, null, { user_id: userId, functions_id: selectedData }, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {
               toast.success(response.data.detail, cfgToast);
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }
            setisloading3(false)
         })
      } else {
         toast.warning("Proszę zaznaczyć uprawnienia w tabeli", cfgToast);
      }
   }


   function notificationIcon(props) {

      try {
         let ret
         props.data.notification ? ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="Aktywne" >
            AKTYWNE
         </Label> : ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="Nieaktywne" >
            NIEAKTYWNE
         </Label>
         return ret
      } catch (error) {
         return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="Nieaktywne" >
            BRAK
         </Label>
      }

   }

   function userType(props) {

      try {

         if (props.data.type === 0) {
            return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="SYSTEMOWY" >
               SYSTEMOWY
            </Label>
         } else if (props.data.type === 1) {
            return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='blue' title="SERWISANT" >
               SERWISANT
            </Label>
         } else {
            return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="BRAK" >
               BRAK
            </Label>
         }

      } catch (error) {
         return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="BRAK" >
            BRAK
         </Label>
      }

   }




   return (
      <div style={{ minHeight: props.minHeight }}>
         <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}


            centered={false}
         >
            <Modal.Header>
               <ModalUI propsUiName={propsUiNameData} />

            </Modal.Header>

            <Modal.Content className='bg'>
               <AppEditUser userInfo={form} />
            </Modal.Content>
            <Modal.Actions>
               <Button size='tiny' onClick={() => setOpen(false)}>
                  ZAMKNIJ
               </Button>

            </Modal.Actions>
         </Modal>
         <Segment  >
            <Grid stackable style={{ minHeight: props.minHeight }}>
               <Grid.Row>
                  <Grid.Column width={8}>


                     <Grid className='clearmp' >
                        <Grid.Row className='clearmp' stretched >
                           <Grid.Column style={{ paddingLeft: 0 }} width={8}>
                              <Button fluid size='tiny' className='appbtnmtd' onClick={() => getAllUsers()}>Pobierz użytkowników</Button>
                           </Grid.Column>
                           <Grid.Column style={{ paddingRight: 0 }} width={8}>
                              <Button fluid size='tiny' disabled={form.userid > 0 ? false : true} className='appbtnmtd' color='blue' onClick={() => editUser()}>Edytuj użytkownika</Button>
                           </Grid.Column>
                        </Grid.Row >
                     </Grid>
                     <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                     <div
                        id="myGrid"
                        style={{
                           height: props.minHeight - 100,
                           // height:  '100%',
                           width: '100%',
                           marginTop: 5
                        }}
                        className="ag-theme-balham"
                     >
                        <AgGridReact
                           //rowHeight={rowHeightAGG}
                           rowSelection={'single'}
                           onSelectionChanged={onSelectionChanged}
                           suppressRowClickSelection={true}

                           animateRows={true}
                           pagination={true}
                           defaultColDef={{
                              editable: false,
                              sortable: true,
                              filter: false,
                              width: 100,
                              minWidth: 100,
                              resizable: true,
                              floatingFilter: false,
                              flex: 1,
                           }}
                           frameworkComponents={{
                              isactiveIcon: isactiveIcon,
                              notificationIcon: notificationIcon,
                              userType: userType
                           }}

                           getRowId={function (data) {
                              return data.data.user_id

                           }}
                           localeText={AG_GRID_LOCALE_PL}
                           rowData={rowData}
                           onGridReady={onGridReady}
                        >
                           <AgGridColumn headerCheckboxSelection={true}
                              checkboxSelection={true}
                              field="username" headerName='Login' filter='agTextColumnFilter' />
                           <AgGridColumn cellRenderer='isactiveIcon'
                              headerName='Aktywny'
                              width={110}
                              minWidth={110}
                           /> 
                           <AgGridColumn field="email" headerName='Email' filter='agTextColumnFilter' />
                           <AgGridColumn field="phone" headerName='Telefon' filter='agTextColumnFilter' />
                           <AgGridColumn field="name" headerName='Imie' filter='agTextColumnFilter' />
                           <AgGridColumn field="surname" headerName='Nazwisko' filter='agTextColumnFilter' />
                           <AgGridColumn cellRenderer='userType'
                              headerName='Typ'
                              width={110}
                              minWidth={110}
                           />
                           <AgGridColumn cellRenderer='notificationIcon'
                              headerName='Powiadomienia'
                              width={110}
                              minWidth={110}
                           />


                        </AgGridReact>
                     </div>
                  </Grid.Column>
                  <Grid.Column width={8}>

                     <Grid className='clearmp' >
                        <Grid.Row className='clearmp' stretched >
                           <Grid.Column style={{ paddingLeft: 0 }} width={8}>
                              <Button fluid size='tiny' className='appbtnmtd' onClick={() => getAllPermissions(form.userid)}>Pobierz funkcje</Button>
                           </Grid.Column>
                           <Grid.Column style={{ paddingRight: 0 }} width={8}>
                              <Button loading={isloading3} fluid size='tiny' disabled={form.userid > 0 ? false : true} className='appbtnmtd' color='blue' onClick={() => savePermissions()}>Zapisz uprawnienia</Button>
                           </Grid.Column>
                        </Grid.Row >
                     </Grid>
                     <AGGridActivity gridApi={gridApiP} gridColumnApi={gridColumnApiP} />
                     <div
                        id="myGrid"
                        style={{
                           height: props.minHeight - 100,
                           // height:  '100%',
                           width: '100%',
                           marginTop: 5
                        }}
                        className="ag-theme-balham"
                     >
                        <AgGridReact
                           //rowHeight={rowHeightAGG}
                           rowSelection={'multiple'}
                           suppressRowClickSelection={true}

                           animateRows={true}
                           pagination={true}
                           defaultColDef={{
                              editable: false,
                              sortable: true,
                              filter: false,

                              width: 100,
                              minWidth: 100,
                              resizable: true,
                              floatingFilter: false,
                              flex: 1,
                           }}
                           getRowId={function (data) {
                              return data.data.function_id
                           }}
                           localeText={AG_GRID_LOCALE_PL}
                           rowData={rowDataP}
                           onGridReady={onGridReadyP}
                        >
                           <AgGridColumn headerCheckboxSelection={true}
                              checkboxSelection={true}
                              suppressColumnsToolPanel={true} field="category_name" headerName='Kategoria' filter='agTextColumnFilter' />
                           <AgGridColumn field="function_name" headerName='Funkcja' filter='agTextColumnFilter' />
                        </AgGridReact>
                     </div>
                  </Grid.Column>
               </Grid.Row>


            </Grid>



         </Segment>
      </div>
   );
}

export default AppPermissions;