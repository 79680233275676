import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Icon, Label, Modal, Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast } from '../../../myFunc';

import ModalUI from '../../../layout/ModalUI';
import AGGridActivity from '../../AGGrid/AGGridActivity';
import EditClient from './EditClient';
import AddClient from './AddClient';
import InfoClient from './InfoClient';



function Clients(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);


   const [onlyChoose, setonlyChoose] = useState(false)
   const [open, setOpen] = useState(false)
   const [openType, setOpenType] = useState(0)
   const [propsUiNameData, setpropsUiNameData] = useState(null);


   const [isloading1, setisloading1] = useState(false) // eslint-disable-line no-unused-vars


   const [form, setForm] = useState({
      client_id: 0,
      allInfo: null
   });

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };


   function SelectedRowTab() {
      if (null !== gridApi && null !== authContext.clientInfo) {
         gridApi.forEachNode(function (node) {
            if (node.data) {
               node.setSelected(node.data['client_id'] === authContext.clientInfo.client_id);
            }
         });
      }
   }


   function getAllUsers() {
      setisloading1(true)
      ConnectWS("/commisions/client", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (props.onlyActive) {
               let tmparr = []
               response.data.forEach((row, i) => {
                  if (row.enabled) {
                     tmparr.push({ ...row })
                  }

               })
               setRowData(tmparr)
            } else {
               setRowData(response.data)
            }


         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         onSelectionChanged()
         setisloading1(false)
      })
   }

   useEffect(() => {

      if (gridApi !== null) {
         SelectedRowTab()
      }
      return () => { }; // use effect cleanup to set flag false, if unmounted
   });// eslint-disable-line react-hooks/exhaustive-deps


   useEffect(() => {
      if (props.onlyChoose !== null) {
         setonlyChoose(props.onlyChoose)
      }
      getAllUsers()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   const onSelectionChanged = () => {
      if (gridApi) {
         var selectedRows = gridApi.getSelectedRows();
         if (selectedRows.length === 1) {
            let client_id = selectedRows[0]["client_id"]
            setForm({ ...form, client_id: client_id, allInfo: selectedRows[0] })
            authContext.changeClientInfo(selectedRows[0])
         }
         else {
            setForm({ ...form, client_id: 0, allInfo: null })
            authContext.changeClientInfo(null)
         }
      }
   };

   const isactiveIcon = props => {
      if (props.data.enabled) {
         return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="Aktywny" >
            AKTYWNY
         </Label>
      } else {
         return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='red' title="Aktywny" >
            NIEAKTYWNY
         </Label>
      }
   };




   const iconUser = props => {
      return <Icon name='users' color='grey' size='large' className='cursorPointer' onClick={() => shwoInfo(props.data)} />
   };



   function editUser() {

      if (form.client_id > 0) {
         setpropsUiNameData("Edycja klienta")
         setOpen(true)
         setOpenType(1)
      } else {
         toast.warning("Proszę zaznaczyć użytkownika w tabeli", cfgToast);
      }
   }



   function shwoInfo(data) {
      setpropsUiNameData("Informacje o kliencie")
      setForm({ ...form, client_id: 0, allInfo: data })
      setOpen(true)
      setOpenType(3)
   }





   return (
      <div style={{ minHeight: props.minHeight }}>
         <Modal
            onClose={() => setOpen(false)}
            open={open}
            size='large'
            centered={false}
         >
            <Modal.Header>
               <ModalUI propsUiName={propsUiNameData} />

            </Modal.Header>

            <Modal.Content className='bg'>
               {openType === 1 ? <EditClient userInfo={form} setOpen={setOpen} /> : openType === 2 ? <AddClient /> : openType === 3 ? <InfoClient minHeight={props.minHeight} userInfo={form} /> : ""}
            </Modal.Content>
            <Modal.Actions>
               <Button size='tiny' onClick={() => setOpen(false)}>
                  ZAMKNIJ
               </Button>

            </Modal.Actions>
         </Modal>
         <Segment  >
            <Grid stackable style={{ minHeight: props.minHeight }}>
               <Grid.Row>
                  <Grid.Column >

                     {onlyChoose === true ? "" :
                        <Grid className='clearmp' >
                           <Grid.Row className='clearmp' stretched >
                              <Grid.Column style={{ paddingLeft: 0 }} width={8}>
                                 <Button fluid size='tiny' className='appbtnmtd' onClick={() => getAllUsers()}>Pobierz klientów</Button>
                              </Grid.Column>
                              <Grid.Column style={{ paddingRight: 0 }} width={8}>
                                 <Button fluid size='tiny' disabled={form.client_id > 0 ? false : true} className='appbtnmtd' color='blue' onClick={() => editUser()}>Edytuj klienta</Button>
                              </Grid.Column>
                              {/*
                           <Grid.Column style={{ paddingRight: 0 }} width={5}>
                              <Button fluid size='tiny' className='appbtnmtd' color='blue' onClick={() => addUser()}>Dodaj klienta</Button>
                           </Grid.Column>
   */}
                           </Grid.Row >
                        </Grid>
                     }
                     <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                     <div
                        id="myGrid"
                        style={{
                           height: props.minHeight - 100,
                           // height:  '100%',
                           width: '100%',
                           marginTop: 5
                        }}
                        className="ag-theme-balham"
                     >
                        <AgGridReact
                           //rowHeight={rowHeightAGG}
                           rowSelection={'single'}
                           onSelectionChanged={onSelectionChanged}
                           suppressRowClickSelection={true}

                           animateRows={true}
                           pagination={true}
                           defaultColDef={{
                              editable: false,
                              sortable: true,
                              filter: false,
                              width: 100,
                              minWidth: 100,
                              //przyśpiesza odświeżanie
                              //wrapText: true,
                              //autoHeight: true,
                              resizable: true,
                              floatingFilter: false,
                              flex: 1,
                           }}
                           frameworkComponents={{
                              iconUser: iconUser,
                              isactiveIcon: isactiveIcon,
                           }}

                           getRowId={function (data) {
                              return data.data.client_id

                           }}
                           localeText={AG_GRID_LOCALE_PL}
                           rowData={rowData}
                           onGridReady={onGridReady}
                        >
                           <AgGridColumn cellRenderer='iconUser'
                              width={49}
                              minWidth={49}
                              flex={0}
                           />
                           <AgGridColumn headerCheckboxSelection={true}
                              checkboxSelection={true}
                              field="name" headerName='Nazwa' width={550}
                              minWidth={100}
                              flex={0} filter='agTextColumnFilter' />
                           <AgGridColumn field="address" width={200}
                              minWidth={100}
                              flex={0} headerName='Adres' filter='agTextColumnFilter' />
                           <AgGridColumn field="description" headerName='Opis' filter='agTextColumnFilter' />
                           <AgGridColumn field="nip" headerName='Nip' filter='agTextColumnFilter' />
                           <AgGridColumn field="external_id" headerName='Identyfikator' width={120}
                              minWidth={120}
                              flex={0} filter='agTextColumnFilter' />
                           <AgGridColumn field="email" headerName='Email' filter='agTextColumnFilter' />
                           <AgGridColumn field="phone" headerName='Telefon' width={100}
                              minWidth={100}
                              flex={0} filter='agTextColumnFilter' />


                           <AgGridColumn field="technical_contact" headerName='Kontakt techniczny' filter='agTextColumnFilter' />

                           <AgGridColumn cellRenderer='isactiveIcon'
                              headerName='Aktywny'
                              width={110}
                              minWidth={110}
                           />
                        </AgGridReact>
                     </div>
                  </Grid.Column>

               </Grid.Row>


            </Grid>



         </Segment>
      </div>
   );
}

export default Clients;