

import React, { useState, useEffect } from 'react';
import { Placeholder, Segment } from 'semantic-ui-react';

const GetLoadingTemplate = (props) => {
    const [lineNumber, setlineNumber] = useState(1)

    useEffect(() => {
        //getFileTemplates()
        if (props.lineNumber) {
            setlineNumber(props.lineNumber)
        }

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [props.lineNumber]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Segment loading>
            <Placeholder fluid>
                {Array.apply(0, Array(lineNumber)).map(function (x, i) {
                    return <div key={i}>
                        <Placeholder.Header image>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </div>
                })}


            </Placeholder>
        </Segment>
    );
};

export default GetLoadingTemplate;
