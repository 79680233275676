import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId
};

let messaging = null

try{
  let firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
}catch(err){
  console.log(err)
}

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: process.env.REACT_APP_FIREBASE_vapidKey}).then((currentToken) => {
    if (currentToken) {
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        console.log('current token for client: ', currentToken);
      }
      
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});