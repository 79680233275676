import './App.css';

import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'semantic-ui-css/semantic.min.css';
import React, { useReducer } from 'react';
import AuthContext from './context/authContext';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import RouterApp from './layout/RouterApp';
import "primereact/resources/themes/saga-blue/theme.css"; //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import 'react-toastify/dist/ReactToastify.css';
import '/node_modules/primeflex/primeflex.css';
import { ToastContainer } from 'react-toastify';
import { locale, addLocale } from 'primereact/api';
import infiberlogo from '../src/assets/images/in-fiberlb.png';

import moment from 'moment';
import 'moment/locale/pl';

import { ErrorBoundary } from 'react-error-boundary';
import { Header, Icon, Segment } from 'semantic-ui-react';
moment().locale('pl');

function ErrorFallbackApp({ error, resetErrorBoundary }) {
  return (
    <Segment placeholder style={{height:"100vh"}}>
     
    <Header icon>
   
      <Icon name='warning sign' />
      Napotkaliśmy problem - odśwież stronę.
   
     
      <Header.Subheader>    
        Jeśli ten problem będzie się powtarzać, skontaktuj się z administratorem systemu aby uzyskać więcej informacji.
      </Header.Subheader>
      <br/>
      <img src={infiberlogo} alt="IN-FIBER" style={{ width: 120, margin: 5 }} />
    </Header>
    
  </Segment>
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-isAuthenticated': return { ...state, isAuthenticated: action.isAuthenticated };
    case 'set-AppView': return { ...state, appView: action.appView };
    case 'set-UserInfo': return { ...state, userInfo: action.userInfo };
    case 'set-AppViewModal': return { ...state, appViewModal: action.appViewModal };
    case 'set-TokenApp': return { ...state, tokenApp: action.tokenApp };
    case 'set-UpdataApp': return { ...state, updataApp: action.updataApp };
    case 'set-ClientInfo': return { ...state, clientInfo: action.clientInfo };
    case 'login': return { ...state, isAuthenticated: true };
    case 'logout': return { ...state, isAuthenticated: false };
    
    default: console.log('Sorry');
  }
  return state;
}

const initState = {
  isAuthenticated: false,
  userInfo: "",
  appView: null,
  appViewModal: null,
  tokenApp: null,
  updataApp: 1,
  clientInfo:null,
};

function App() {

  const [state, dispatch] = useReducer(reducer, initState);

  addLocale('pl', {
    firstDayOfWeek: 1,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
    dayNamesMin: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    today: 'Dzisiaj',
    clear: 'Wyczyść'
  });
  locale('pl');

  return (
    <CookiesProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <AuthContext.Provider value={{
          isAuthenticated: state.isAuthenticated,
          userInfo: state.userInfo,
          appView: state.appView,
          appViewModal: state.appViewModal,
          tokenApp: state.tokenApp,
          updataApp: state.updataApp,
          clientInfo: state.clientInfo,
          login: () => dispatch({ type: 'login' }),
          logout: () => dispatch({ type: 'logout' }),
          changeUserInfo: (val) => dispatch({ type: 'set-UserInfo', userInfo: val }),
          changeAppView: (val) => dispatch({ type: 'set-AppView', appView: val }),
          changeAppModal: (val) => dispatch({ type: 'set-AppViewModal', appViewModal: val }),
          changeTokenApp: (val) => dispatch({ type: 'set-TokenApp', tokenApp: val }),
          changeUpdataApp: (val) => dispatch({ type: 'set-UpdataApp', updataApp: val }),
          changeClientInfo: (val) => dispatch({ type: 'set-ClientInfo', clientInfo: val }),
        }}>
          <ErrorBoundary FallbackComponent={ErrorFallbackApp}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}>
              
            <RouterApp />
          </ErrorBoundary>

        </AuthContext.Provider>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
