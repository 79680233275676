
import React from 'react';
import NotFoundView from './layout/Ui/404view';
import OrdersCalendars from './components/Orders/OrdersCalendars';
import SwitchAlarms from "./components/Switch/SwitchAlarms";
import SwitchDiagram from './components/Switch/SwitchDiagram/SwitchDiagram';
import AppPermissions from './components/App/AppPermissions';
import AppUserAdd from './components/App/AppUserAdd';
import AppLogs from './components/App/AppLogs';
import Dashboard from './layout/Dashboard';
import AppCurrentEditUser from './components/App/AppCurrentEditUser';
import SwitchDevices from './components/Switch/SwitchDevices';
import SwitchConnectionsWizard from './components/Switch/ConnectionsWizard/SwitchConnectionsWizard';
import SwitchTopology from './components/Switch/SwitchTopology';
import SwitchSites from './components/Switch/SwitchSites';
import SwitchAddDevices from './components/Switch/SwitchAddDevices';
import SwitchPorts from './components/Switch/SwitchPorts';
import SwitchConnections from './components/Switch/SwitchConnections';
import SwitchEventLogs from './components/Switch/SwitchEventLogs';
import AppSettings from './components/App/AppSettings';
import Clients from './components/Commissions/Clients/Client';
import Items from './components/Commissions/Warehouses/Items/Items';
import Commissions from './components/Commissions/Commissions/Commissions';
import AddCommision from './components/Commissions/Commissions/AddCommision';
import Workers from './components/Commissions/Workers/Workers';
import AddWorker from './components/Commissions/Workers/AddWorker';
import AddClient from './components/Commissions/Clients/AddClient';
import AddItem from './components/Commissions/Warehouses/Items/AddItem';
import AppCurrentEditWorker from './components/App/AppCurrentEditWorker';
import AppCurrentWorkerCalendar from './components/Orders/AppCurrentWorkerCalendar';
import CommisionSettingsTab from './components/Commissions/Commissions/CommisionSettings/CommisionSettingsTab';
import CommissionWorkerReport from './components/Commissions/Commissions/CommissionWorkerReport';


export function projectView(View,minHeight) {  
    if(View){
        switch (View.appView) {  
            
            case "switch_alarms": return <SwitchAlarms minHeight={minHeight}/>;
            case "orders_calendars": return <OrdersCalendars minHeight={minHeight}/>;
            case "switch_diagram": return <SwitchDiagram minHeight={minHeight}/>;

            // APP

            
            case "app_settings": return <AppSettings minHeight={minHeight}/>
            case "app_dashboard": return <Dashboard minHeight={minHeight}/>
            case "app_permissions": return <AppPermissions minHeight={minHeight}/>;
            case "app_addUsers": return <AppUserAdd minHeight={minHeight}/>;
            case "app_logs": return <AppLogs minHeight={minHeight}/>;
            case "switch_sites": return <SwitchSites minHeight={minHeight}/>;
            case "switch_devices": return <SwitchDevices minHeight={minHeight}/>;
            case "switch_connectionsWizard": return <SwitchConnectionsWizard minHeight={minHeight}/>;
            case "switch_topology": return <SwitchTopology minHeight={minHeight}/>;
            case "switch_addDevices": return <SwitchAddDevices minHeight={minHeight}/>;
            case "switch_ports": return <SwitchPorts minHeight={minHeight}/>;
            case "switch_connections": return <SwitchConnections minHeight={minHeight}/>;
            case "switch_eventLogs": return <SwitchEventLogs minHeight={minHeight}/>;
            case "commission_workers": return <Workers minHeight={minHeight} onlyChoose={false}/>;
            case "commission_addWorker": return <AddWorker minHeight={minHeight}/>;
            case "commission_clients": return <Clients minHeight={minHeight} onlyChoose={false}/>;
            case "commission_addClient": return <AddClient minHeight={minHeight}/>;
            case "warehouses_items": return <Items minHeight={minHeight}/>;
            case "warehouses_addItem": return <AddItem minHeight={minHeight}/>;
            case "commission_commissions": return <Commissions minHeight={minHeight}/>;
            case "commission_settings": return <CommisionSettingsTab minHeight={minHeight}/>;
            case "commission_worker_report": return <CommissionWorkerReport minHeight={minHeight}/>;
            
            
            case "commission_addCommission": return <AddCommision minHeight={minHeight} dateFrom={View.props ? JSON.parse(View.props).dateFrom : null} user_id={View.props ? JSON.parse(View.props).user_id : null}/>;
            
            // Bez uprawnień
            case "AppCurrentEditUser": return <AppCurrentEditUser minHeight={minHeight}/>; // zaszyte w app
            case "AppCurrentEditWorker": return <AppCurrentEditWorker minHeight={minHeight}/>; // zaszyte w app
            case "AppCurrentWorkerCalendar": return <AppCurrentWorkerCalendar minHeight={minHeight}/>; // zaszyte w app
            
            
            
            
            default: return <NotFoundView minHeight={minHeight}/>
        }
    }
    
}