import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';
import AGGridActivity from '../AGGrid/AGGridActivity';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';

import { filterParams } from '../AGGrid/functions';


function SwitchPorts(props) {
   const authContext = useContext(AuthContext);

   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };




   let interval = useRef(null);
   const [value1, setValue1] = useState('Live');// eslint-disable-line no-unused-vars



   useEffect(() => {
      getData()
      if (value1 === "Live") {
         interval.current = setInterval(() => {
            getData()
         }, 10000);
      } else {
         clearInterval(interval.current)
      }

      return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
   }, [value1, authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   function getData() {

      ConnectWS("/infiber/ports", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               let tmparr1 = []
               response.data.forEach(element => {
                  tmparr1.push({ ...element, stateAlive: stateAlive(element.alive) })
               })
               setRowData(tmparr1)
            } else {
               setRowData([])
            }

         } else {
            setRowData([])
            toast.error(response, cfgToast);
            console.log(response);
         }



      })
   }



   const stateLed = props => {

      switch (props.data.led) {

         case 0: return <Icon name="lightbulb" color='grey' size='large' title="LED JEST WYŁĄCZONY" />;
         case 1: return <Icon name="lightbulb" color='orange' size='large' title="LED OCZEKUJE NA POTWIERDZENIE" />;
         case 2: return <Icon name="lightbulb" color='green' size='large' title="LED JEST WŁĄCZONY" />;
         default: return <Icon name="lightbulb" color='black' size='large' title="LED JEST WYŁĄCZONY" />
      }

   };


   function stateAlive(alive) {

      switch (alive) {
         case 0: return "ERROR";
         case 1: return "OK";
         default: return "-";
      }

   };



   function clikcLedA(port_id, statusenable) {
      ConnectWS("/infiber/ports", "put", authContext.tokenApp, null, { port_id: port_id, enable: statusenable === 0 ? 1 : 0 }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {


         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }
         getData()


      })
   }

   const clikcLed = props => {

      return <Button onClick={() => clikcLedA(props.data.port_id, props.data.led)}
         content=''
         icon='lightbulb'
         label={{ as: 'a', basic: true, pointing: 'left', style: { width: 120 }, content: props.data.led > 0 ? 'WŁĄCZONE' : "WYŁĄCZONE" }}
         labelPosition='right'
         size='tiny'
         color={props.data.led ? 'green' : "grey"}

      />
   };

   function clikcNotificationA(port_id, statusenable) {
      ConnectWS("/infiber/ports", "put", authContext.tokenApp, null, { port_id: port_id, notification: statusenable === 0 ? 1 : 0 }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {


         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }
         getData()


      })
   }

   const clikcNotification = props => {

      return <Button onClick={() => clikcNotificationA(props.data.port_id, props.data.notification)}
         content=''
         icon='alarm'
         label={{ as: 'a', basic: true, pointing: 'left', style: { width: 120 }, content: props.data.notification > 0 ? 'WŁĄCZONE' : "WYŁĄCZONE" }}
         labelPosition='right'
         size='tiny'
         color={props.data.notification ? 'green' : "red"}

      />
   };

   const cellClass = (params) => {

      if (params.column.colId === "port_number") {
         return params.data.alive === 0 ? 'cell-red' : params.data.alive === 1 ? 'cell-green' : '';
      } else {
         return ""
      }

   };


   const cellClassLR = (params) => {

      if (params.column.colId === "local_id" || params.column.colId === "remote_id") {
         
            return params.data.local_id === params.data.remote_id ? 'cell-green' : 'cell-red'
        

      } else {
         return ""
      }

   };

   const statusIcon = props => {


         if (props.data.alive === 1) {
            return <Icon name="hdd" color='green' size='large' />;
         } else {
            return <Icon name="hdd" color='red' size='large' />;
         }
      


   };

   return (
      <>
         <Segment className='bgcard'>
            <Grid stackable >
               <Grid.Row >

                  <Grid.Column width={16} >
                     <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                  </Grid.Column>
               </Grid.Row>
            </Grid>

            <div
               id="myGrid"
               style={{
                  height: props.minHeight - 80,
                  // height:  '100%',
                  width: '100%',
                  marginTop: 5
               }}
               className="ag-theme-balham"
            >
               <AgGridReact

                  animateRows={true}
                  pagination={true}
                  defaultColDef={{
                     editable: false,
                     sortable: true,
                     filter: false,
                     width: 120,
                     minWidth: 120,
                     wrapText: true,
                     autoHeight: true,
                     resizable: true,
                     floatingFilter: false,
                     flex: 1,
                  }}
                  frameworkComponents={{
                     stateLed: stateLed,
                     clikcLed: clikcLed,
                     statusIcon: statusIcon,
                     clikcNotification: clikcNotification
                  }}

                  getRowId={function (data) {
                     return data.data.port_id

                  }}
                  localeText={AG_GRID_LOCALE_PL}
                  rowData={rowData}
                  onGridReady={onGridReady}
               >

                  <AgGridColumn cellRenderer='statusIcon'
                     width={50}
                     minWidth={50}
                     flex={0}
                  />
                  <AgGridColumn field="site_name" headerName='Nazwa obiektu' filter='agTextColumnFilter' />
                  <AgGridColumn field="cabinet_name" headerName='Szafa' filter='agTextColumnFilter' />
                  <AgGridColumn field="device_name" headerName='Urządzenie' filter='agTextColumnFilter' />
                  <AgGridColumn field="id" headerName='Id' filter='agTextColumnFilter' />


                  <AgGridColumn field="port_number" width={100}
                     minWidth={100} flex={0} headerName='Port' cellClass={cellClass} filter='agNumberColumnFilter' />
                  <AgGridColumn field="port_type" width={100}
                     minWidth={100} flex={0} headerName='Typ Port' filter='agNumberColumnFilter' />
                  <AgGridColumn field="local_id" width={170} cellClass={cellClassLR}
                     minWidth={170} flex={0} headerName='Połaczenie oczekiwane' filter='agTextColumnFilter' />
                  <AgGridColumn field="remote_id" width={150} cellClass={cellClassLR}
                     minWidth={150} flex={0} headerName='Połaczenie aktualne' filter='agTextColumnFilter' />

                  <AgGridColumn field="modified_at" width={150}
                     minWidth={150} flex={0} headerName='Ostatni odczyt' filter='agDateColumnFilter' filterParams={filterParams} />
                  <AgGridColumn field='stateAlive' width={80}
                     minWidth={80} flex={0} headerName='Status' filter='agTextColumnFilter' />
                  <AgGridColumn cellRenderer='clikcNotification' headerName='Powiadomienie'
                     width={180}
                     minWidth={180}
                     flex={0}
                  />
                  <AgGridColumn cellRenderer='stateLed' headerName='LED'
                     width={45}
                     minWidth={45}
                     flex={0}
                  />


                  <AgGridColumn cellRenderer='clikcLed'
                     width={180}
                     minWidth={180}
                     flex={0}
                  />


               </AgGridReact>
            </div>
         </Segment>
      </>
   );
}

export default SwitchPorts;