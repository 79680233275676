import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { cfgToast } from '../../../../myFunc';
import { AG_GRID_LOCALE_PL } from '../../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AGGridActivity from '../../../AGGrid/AGGridActivity';
import { ConnectWS } from '../../../../ConnectWS/ConnectWS';
import AuthContext from '../../../../context/authContext';

import {  Header, Icon, Label,  Segment } from 'semantic-ui-react';





function InfoItem(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);


   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   function getItemsHistory(props) {  
      let element_id = props.userInfo.allInfo.element_id;
      ConnectWS("/warehouse/get_element_history", "get", authContext.tokenApp, null, {element_id:element_id}, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
              setRowData(response.data)

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }


   useEffect(() => {
      getItemsHistory(props)
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps

   
   

   return (
      <>
         <Segment textAlign='center' basic style={{ margin: 0, padding: 0 }}>
            <Header as='h4' icon>
               <Icon name='barcode'/>
               {props.userInfo.allInfo.name} {props.userInfo.allInfo.surname}

            </Header>
         </Segment>
         <Segment>
            <Label color='blue' ribbon>
               HISTORIA ELEMENTU
            </Label>
            <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                     <div
                        id="myGrid"
                        style={{
                           height: (props.minHeight - 300)>300 ? props.minHeight - 300 : 300,
                           // height:  '100%',
                           width: '100%',
                           marginTop: 5
                        }}
                        className="ag-theme-balham"
                     >
                        <AgGridReact
                           //rowHeight={rowHeightAGG}
                           rowSelection={'single'}
                           animateRows={true}
                           pagination={true}
                           defaultColDef={{
                              editable: false,
                              sortable: true,
                              filter: false,
                              width: 120,
                              minWidth: 120,
                              wrapText: true,
                              autoHeight: true,
                              resizable: true,
                              floatingFilter: false,
                              flex: 1,
                           }}
                          
                           

                           localeText={AG_GRID_LOCALE_PL}
                           rowData={rowData}
                           onGridReady={onGridReady}
                        >
                           {/*
                           <AgGridColumn cellRenderer='warehouseTMP'
                              width={49}
                              minWidth={49}
                              flex={0}
                           />
                        */}
                                           
                           <AgGridColumn field="name" headerName='Element' filter='agTextColumnFilter' />
                           <AgGridColumn field="quantity" headerName='Ilość' filter='agTextColumnFilter' />
                           <AgGridColumn field="source_warehouse_name" headerName='Magazyn z' filter='agTextColumnFilter' />
                           <AgGridColumn field="destination_warehouse_name" headerName='Magazyn do' filter='agTextColumnFilter' />
                           <AgGridColumn field="username" headerName='Użytkownik' filter='agTextColumnFilter' />
                           <AgGridColumn field="created_at" headerName='Data wykonania' filter='agTextColumnFilter'   width={150}
                              minWidth={150}
                              flex={0}/>
          
                          
                                
                        </AgGridReact>
                     </div>
         </Segment>
      </>
   );
}

export default InfoItem;