import React, { useEffect, useState, useContext } from 'react';
import { Grid, Icon, Label, Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast, changeFormatDate, getColorStatus } from '../../../myFunc';
import { Dropdown } from 'primereact/dropdown';
import AGGridActivity from '../../AGGrid/AGGridActivity';
import { Calendar } from 'primereact/calendar';
import { Button as ButtonPrime } from 'primereact/button';
import CurrentCommisions from './CurrentCommisions';
import ViewCommision from './ViewCommision';

function Commissions(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);
   const [isWorker, setWorker] = useState(false);

   const [mCommisionStatus, setmCommisionStatus] = useState([])
   const [comStatus, setcomStatus] = useState({code: 0, name: "Wszystkie" }) // eslint-disable-line no-unused-vars

   const [openType, setOpenType] = useState(0) // eslint-disable-line no-unused-vars


   const [isloading1, setisloading1] = useState(false) // eslint-disable-line no-unused-vars
   const [isloading, setisloading] = useState(false);// eslint-disable-line no-unused-vars

   const [form, setForm] = useState({
      dateFrom: new Date(Date.now() - (3600 * 1000 * 24 * 7)),
      dateTo: new Date(Date.now() + (3600 * 1000 * 24 * 7)),
   });

   const [form1, setForm1] = useState({
      commision_id: 0,
      allInfo: null
   });

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      if(rowData===null){
         params.api.showNoRowsOverlay();
      }
      
   };



   function getAllCommissions() {
      setisloading1(true)
      //const items = Array.from({ length: 100 }).map((_, i) => ({ commision_id: i + 1, iddb: i + 1, idforeign: (i + 1) + 5000, name: `Nazwa ${i + 1}`, opis: `Opis ${i + 1}`, client: `klient ${i + 1}`, typzlecenia: "Serwis", status: "Zakończony", datadodania: "2023-03-03 12:00:00", datastartu: "2023-03-03 12:00:00", datakonca: "2023-03-03 12:00:00", datazatwierdzenia: "2023-03-03 12:00:00", datamodyfikacji: "2023-03-03 12:00:00", overtime: Math.floor(2 * Math.random()), statusCommissions: Math.floor(5 * Math.random()) }));
      //setRowData(items)
      if (gridApi && gridApi.destroyCalled === false) {
         gridApi.showLoadingOverlay();
      }
      ConnectWS("/commisions", "get", authContext.tokenApp, null, { scheduled_start_at: changeFormatDate(form.dateFrom), scheduled_end_at: changeFormatDate(form.dateTo) ,status_id:comStatus.code!==0 ? comStatus.code : null }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
            if (gridApi && gridApi.destroyCalled === false) {
               gridApi.showNoRowsOverlay();
            }
         } else if (Number(response.status) === 200) {
            if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
               if (gridApi && gridApi.destroyCalled === false) {
                  gridApi.showNoRowsOverlay();
               }
            } else {
               if(response.data.length>0){
                  setRowData(response.data)
                  if (gridApi && gridApi.destroyCalled === false) {
                     gridApi.hideOverlay();
                  }
               }else{
                  if (gridApi && gridApi.destroyCalled === false) {
                     gridApi.showNoRowsOverlay();
                  }
               }
               
            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);
            if (gridApi && gridApi.destroyCalled === false) {
               gridApi.showNoRowsOverlay();
            }
         }

         setisloading1(false)
      })
   }


   function getCommisionStatus() {
      ConnectWS("commisions/status", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // setdataFileTemplates(response.data)
            let tmparr = []
            tmparr.push({code: 0, name: "Wszystkie" })
            response.data.forEach((row, i) => {
               if (row.action !== 8 && row.action !== 9) {
                  tmparr.push({code: row.commision_status_id, name: row.status })
               }
            })
           
            setmCommisionStatus(tmparr)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }



   useEffect(() => {
      if (authContext.userInfo && null !== authContext.userInfo.worker && authContext.userInfo.worker === 1) {
         setWorker(true)
      } else {
         setWorker(false)
      }
      getCommisionStatus()
      getAllCommissions()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps

   const overtime = props => {
      if (props.data.overtime === 1) {
         return <Icon name='check' color='grey' size='large' />
      } else {
         return <Icon name='x' color='grey' size='large' />
      }
   };

   function OpenViewCommision(data) {
      setForm1({ ...form1, commision_id: data.commision_id, allInfo: data })
      setOpenType(1)
   }

   const iconUCommission = props => {
      return <Label onClick={() => OpenViewCommision(props.data)} className='cursorPointer' style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center", backgroundColor: getColorStatus(props.data.status_id), color: "#fff" }} title={props.data.status} >
         {props.data.status}
      </Label>
   };

   



   return (
      <div style={{ minHeight: props.minHeight }}>

         {openType === 1 ? <Segment  >
            <ButtonPrime disabled={form1.commision_id > 0 ? false : true} label="Powrót" className="p-button-secondary" onClick={() => setOpenType(0)}></ButtonPrime>
            <ViewCommision isWorker={isWorker} commision_id={form1.commision_id} setOpenType={setOpenType} />
         </Segment>
            :
            <Segment  >
               {isWorker ? <CurrentCommisions /> : ""}
               <Grid stackable style={{ minHeight: props.minHeight }}>
                  <Grid.Row>
                     <Grid.Column >
                        <Grid className='clearmp' stackable >
                           <Grid.Row stretched >

                              <Grid.Column style={{ paddingLeft: 0 }} width={4} className="p-fluid">
                                 <span className="p-float-label">
                                    <Calendar value={form.dateFrom} onChange={(e) => setForm({ ...form, dateFrom: e.value })} dateFormat="yy-mm-dd" showIcon />
                                    <label htmlFor="date">Data od:</label>
                                 </span>
                              </Grid.Column>
                              <Grid.Column style={{ paddingRight: 0 }} width={4} className="p-fluid">
                                 <span className="p-float-label">
                                    <Calendar value={form.dateTo} onChange={(e) => setForm({ ...form, dateTo: e.value })} minDate={form.dateFrom} dateFormat="yy-mm-dd" showIcon />
                                    <label htmlFor="date">Data do:</label>
                                 </span>
                              </Grid.Column>
                              <Grid.Column style={{ paddingRight: 0 }} width={4} className="p-fluid">
                                 <span className="p-float-label">
                                    <  Dropdown value={comStatus} options={mCommisionStatus} onChange={(e) => setcomStatus(e.value)} style={{height:32}} optionLabel="name"  />
                                    <label htmlFor="date">Status zlecenia:</label>
                                 </span>
                              </Grid.Column>
                              <Grid.Column style={{ paddingRight: 0 }} width={4} className="p-fluid">
                                 <ButtonPrime loading={isloading} onClick={() => getAllCommissions()} label="Pobierz zlecenia" className="p-button" />
                              </Grid.Column>

                           </Grid.Row >
                        </Grid>
                        <AGGridActivity quickSearchHidden={false} gridApi={gridApi} gridColumnApi={gridColumnApi} />
                        <div
                           id="myGrid"
                           style={{
                              height: props.minHeight - 130,
                              // height:  '100%',
                              width: '100%',
                              marginTop: 5
                           }}
                           className="ag-theme-balham"
                        >
                           <AgGridReact
                              //rowHeight={rowHeightAGG}

                              suppressRowClickSelection={true}

                              animateRows={true}
                              pagination={true}
                              defaultColDef={{
                                 editable: false,
                                 sortable: true,
                                 filter: false,
                                 width: 100,
                                 minWidth: 100,
                                //przyśpiesza odświeżanie
                              //wrapText: true,
                              //autoHeight: true,
                                 resizable: true,
                                 floatingFilter: false,
                                 flex: 1,
                              }}
                              frameworkComponents={{
                                 iconUCommission: iconUCommission,
                                 overtime: overtime,
                               
                              }}

                              getRowId={function (data) {
                                 return data.data.commision_id

                              }}
                              localeText={AG_GRID_LOCALE_PL}
                              rowData={rowData}
                              onGridReady={onGridReady}
                           >
                              <AgGridColumn cellRenderer='iconUCommission'
                                 width={120}
                                 minWidth={120}
                                 maxWidth={120}
                                 flex={0}
                                 filter='agTextColumnFilter'
                                 headerName='Status'
                                 filterParams={{
                                    valueGetter: params => {
                                       return params.data.status
                                    }
                                 }}
                              />
                              <AgGridColumn field="commision_id" headerName='Identyfikator baza' width={50}
                                 minWidth={50}
                                 flex={0} filter='agTextColumnFilter' />
                              <AgGridColumn
                                 field="extserviceid" headerName='Identyfikato zewnetrzny' width={100}
                                 minWidth={100}
                                 flex={0} filter='agTextColumnFilter' />

                              <AgGridColumn field="client_name" headerName='Klient' width={550}
                                 minWidth={100}
                                 flex={0} filter='agTextColumnFilter' />
                              <AgGridColumn field="worker" headerName='Serwisant' width={150}
                                 minWidth={100}
                                 flex={0} filter='agTextColumnFilter' />
                              <AgGridColumn field="name" headerName='Nazwa zlecenia' filter='agTextColumnFilter' />
                              <AgGridColumn field="type" headerName='Typ zlecenia' width={130}
                                 minWidth={100}
                                 flex={0} filter='agTextColumnFilter' />
                              <AgGridColumn field="scheduled_start_at" width={140}
                                 minWidth={140}
                                 flex={0} headerName='Data rozpoczęcia' filter='agTextColumnFilter' />
                              <AgGridColumn field="scheduled_end_at" width={140}
                                 minWidth={140}
                                 flex={0} headerName='Data zakończenia' filter='agTextColumnFilter' />

                              <AgGridColumn cellRenderer='overtime'
                                 headerName='Nadgodziny'
                                 width={49}
                                 minWidth={49}
                                 flex={0}
                              />

                              <AgGridColumn field='images'
                                 headerName='Zdjęcia'
                                 width={70}
                                 minWidth={70}
                                 flex={0}
                              />
                              <AgGridColumn field='comment'
                                 headerName='Opis instalatora'
                                 width={100}
                                 minWidth={100}
                                 maxWidth={1000}
                                 flex={0}
                              />


                           </AgGridReact>
                        </div>
                     </Grid.Column>

                  </Grid.Row>


               </Grid>



            </Segment>
         }
      </div>
   );
}

export default Commissions;