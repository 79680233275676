import React, { useEffect, useState, useContext } from 'react';
import { Button,Icon,   Message } from 'semantic-ui-react';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast } from '../../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

import { Button as ButtonPrime } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Card } from 'primereact/card';



function EditClient(props) {
   const authContext = useContext(AuthContext);
   const defaultValues = { 
      client_id: 0,
      name: "",
      address: "",
      description: "",
      nip: "",
      enabled: 1,
      email: "",
      phone: "",
      technical_contact: ""

   }
   

   const [delObjectQ, setdelObjectQ] = useState(false);
   const [client_id, setclient_id] = useState(0);

   const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });
  
   const [isloading, setisloading] = useState(false);

   const [checked, setChecked] = useState(false);


   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };



   useEffect(() => {
     
      if (props.userInfo.allInfo) {
         console.log(props.userInfo.allInfo)
         setValue("name", props.userInfo.allInfo.name)
         setValue("enabled", props.userInfo.allInfo.enabled ? 1 : 0)
         setValue("address", props.userInfo.allInfo.address)
         setValue("description", props.userInfo.allInfo.description)
         setValue("nip", props.userInfo.allInfo.nip)
         setValue("client_id", props.userInfo.allInfo.client_id)
         setclient_id(props.userInfo.allInfo.client_id)
         setValue("phone", props.userInfo.allInfo.phone)
         setValue("email", props.userInfo.allInfo.email)
         setValue("technical_contact", props.userInfo.allInfo.technical_contact)
         setChecked(props.userInfo.allInfo.enabled ? 1 : 0)

      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props]);// eslint-disable-line react-hooks/exhaustive-deps


   const submit = (data) => {
      setisloading(true)

      ConnectWS("/commisions/client", "put", authContext.tokenApp, null,data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp+1)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }

  

   function delObjectQAcc() {
      ConnectWS("/commisions/client", "delete", authContext.tokenApp, null,{client_id:client_id}, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp+1)
            props.setOpen(false)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })
   }

   return (
      delObjectQ ? <Card className="bgcard">
      <Message icon>
         <Icon name='help circle' />
         <Message.Content>
            <Message.Header>Czy chcesz skasować klienta?</Message.Header>
            Proszę potwierdzić
         </Message.Content>
         <Button color='red' onClick={() => delObjectQAcc()}>TAK</Button>
         <Button onClick={() => setdelObjectQ(false)}>NIE</Button>
      </Message>

   </Card> :  <>
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

            <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="name" control={control} rules={{ required: 'Nazwa klienta jest wymagana' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nazwa klienta*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="address" control={control} rules={{ required: 'Adres klienta jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="address" className={classNames({ 'p-error': errors.address })}>Adres klienta*</label>
                  </span>
                  {getFormErrorMessage('address')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="nip" control={control}  render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="nip" className={classNames({ 'p-error': errors.nip })}>NIP</label>
                  </span>
                  {getFormErrorMessage('nip')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="description" control={control} rules={{ required: 'Opis jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>Opis*</label>
                  </span>
                  {getFormErrorMessage('description')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="phone" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Telefon</label>
                  </span>
                  {getFormErrorMessage('phone')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="technical_contact" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="technical_contact" className={classNames({ 'p-error': errors.technical_contact })}>Kontakt techniczny</label>
                  </span>
                  {getFormErrorMessage('technical_contact')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-envelope" />
                     <Controller name="email" control={control}
                        rules={{ required: 'Email jest wymagany.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Błędny email, przykład: example@email.com' } }}
                        render={({ field, fieldState }) => (
                           <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                     <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>Email*</label>
                  </span>
                  {getFormErrorMessage('email')}
               </div>
                     

               <div className="field-checkbox" style={{ paddingTop: 10 }}>
                  <Controller name="enabled" control={control} rules={{ required: false }} render={({ field, fieldState }) => (
                     <InputSwitch inputId={field.name} onChange={(e) => {
                        field.onChange(e.value ? 1 : 0)
                        setChecked(e.value ? 1 : 0)
                     }} checked={field.value ? true : false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                  )} />
                  <label htmlFor="enabled" className={classNames({ 'p-error': errors.enabled })}>{checked ? "KLIENT AKTYWNY" : "KLIENT NIEAKTYWNY"}</label>

               </div>

               <ButtonPrime loading={isloading} type="submit" label="Zapisz dane" className="mt-2 p-button" />
            </form>


         </Card>
         <Button color="grey" style={{ marginTop: 10 }} fluid icon labelPosition='right' onClick={() => setdelObjectQ(true)}>
            <Icon name='trash' />
            KASOWANIE KLIENTA
         </Button>
      </>
   );
}

export default EditClient;