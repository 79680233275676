import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { cfgToast, changeFormatDate } from '../../myFunc';
import { Grid, Label } from 'semantic-ui-react';
import { Button as ButtonPrime } from 'primereact/button';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { SelectButton } from 'primereact/selectbutton';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AGGridActivity from '../AGGrid/AGGridActivity';
import { filterParams } from '../AGGrid/functions';

function AppLogs(props) {

   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   const statusEvent = props => {
      switch (props.data.level) {
         case "info": return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center" }} color='blue'>INFO</Label>;
         case "error": return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center" }} color='red'>ERROR</Label>;
         case "warn": return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center" }} color='orange'>WARNING</Label>;
         default: return "-"
      }


   };

   const authContext = useContext(AuthContext);
   const [value1, setValue1] = useState('Off');
   const options = ['Off', 'Live'];
   const [form, setForm] = useState({
      dateFrom: new Date(Date.now() - (3600 * 1000 * 24)),
      dateTo: new Date(),
   });
   const [isloading, setisloading] = useState(false);

   function getData() {
      
      if(gridApi && value1 !== "Live" && gridApi.destroyCalled === false){
         gridApi.showLoadingOverlay();
      }
      let dateFrom = changeFormatDate(form.dateFrom) + " 00:00:00"
      let date_to = changeFormatDate(form.dateTo) + " 23:59:59"
      if (value1 === "Live") {
         setForm({
            dateFrom: new Date(Date.now() - (3600 * 1000 * 24)),
            dateTo: new Date(),
         })
         dateFrom = changeFormatDate(new Date(Date.now() - (3600 * 1000 * 24))) + " 00:00:00"
         date_to = changeFormatDate(new Date()) + " 23:59:59"
      }

      setisloading(true)
      let data = { date_from: dateFrom, date_to: date_to }
      ConnectWS("/infiber/logs", "get", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
            if(gridApi && value1 !== "Live"){
               gridApi.showNoRowsOverlay();
            }
         } else if (Number(response.status) === 200) {
            if(gridApi && value1 !== "Live"){
               gridApi.hideOverlay();
            }
            if(response.data.length>0){
               setRowData(response.data)
              }else{
               setRowData([])
              }
         } else {
            if(gridApi && value1 !== "Live"){
               gridApi.showNoRowsOverlay();
            }
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)

      })

   }

   let interval = useRef(null);

   useEffect(() => {
      getData()
      if (value1 === "Live") {

         interval.current = setInterval(() => {
            getData()
         }, 5000);
      } else {
         clearInterval(interval.current)
      }

      return () => { clearInterval(interval.current) }; // use effect cleanup to set flag false, if unmounted
   }, [value1]);// eslint-disable-line react-hooks/exhaustive-deps



   return (

      <Card className="bgcard" style={{ marginBottom: 10, minHeight: props.minHeight }}>

         <Grid stackable>
            <Grid.Row >
               <Grid.Column width={5} className="p-fluid">
                  <span className="p-float-label">
                     <Calendar value={form.dateFrom} onChange={(e) => setForm({ ...form, dateFrom: e.value })} dateFormat="yy-mm-dd" showIcon />
                     <label htmlFor="date">Data od:</label>
                  </span>
               </Grid.Column>

               <Grid.Column width={5} className="p-fluid">
                  <span className="p-float-label">
                     <Calendar value={form.dateTo} onChange={(e) => setForm({ ...form, dateTo: e.value })} dateFormat="yy-mm-dd" showIcon />
                     <label htmlFor="date">Data do:</label>
                  </span>
               </Grid.Column>

               <Grid.Column width={2} className="p-fluid">

                  <SelectButton style={{ height: 33 }} value={value1} options={options} onChange={(e) => e.value ? setValue1(e.value) : ""} />

               </Grid.Column>
               <Grid.Column width={4} className="p-fluid">
                  <ButtonPrime loading={isloading} disabled={value1 === "Live"} onClick={() => getData()} label="Pobierz dane" className="p-button" />

               </Grid.Column>
            </Grid.Row>
         </Grid>
         <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
         <div
            id="myGrid"
            style={{
               height: props.minHeight - 130,
               // height:  '100%',
               width: '100%',
               marginTop: 5
            }}
            className="ag-theme-balham"
         >
            <AgGridReact
               //  rowHeight={rowHeightAGG}
               rowSelection={'single'}

               suppressRowClickSelection={true}
               animateRows={true}
               pagination={true}
               defaultColDef={{
                  editable: false,
                  sortable: true,
                  filter: false,
                  width: 100,
                  minWidth: 100,
                  wrapText: true,
                  autoHeight: true,
                  resizable: true,
                  floatingFilter: false,
                  flex: 1,
               }}
               frameworkComponents={{
                  statusEvent: statusEvent
               }}

               getRowId={function (data) {
                  return data.data.event_id

               }}
               localeText={AG_GRID_LOCALE_PL}
               rowData={rowData}
               onGridReady={onGridReady}
            >
               <AgGridColumn cellRenderer='statusEvent'
                  width={110}
                  minWidth={110}
                  flex={0}
               />

               <AgGridColumn field="created_at" headerName='Data' filter="agDateColumnFilter"
                  filterParams={filterParams}
                  width={170}
                  minWidth={170}
                  flex={0}
               />

               <AgGridColumn field="ip"  headerName='IP' width={170}
                  minWidth={170}
                  flex={0} filter='agTextColumnFilter' />
               <AgGridColumn field="username" headerName='Użytkownik'  filter='agTextColumnFilter' />
               <AgGridColumn field="description" headerName='Szczegóły' filter='agTextColumnFilter' />


            </AgGridReact>
         </div>



      </Card>

   );
}

export default AppLogs;