import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Header, Icon, Image, Modal } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import { projectView } from '../projectViewAll';
import infiberlogolb from '../assets/images/in-fiberlb.png';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { smallViewTel } from '../myFunc';

const LayoutConcent = (props) => {
    const authContext = useContext(AuthContext);
    const [open, setOpen] = useState(false)
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars


    useEffect(() => {
        if (authContext.appViewModal && (authContext.appViewModal.appModal === true || authContext.appViewModal.appModal === 1)) {
            setOpen(true)
        }
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [authContext.appView, authContext.appViewModal]);

    function closeModal() {
        authContext.changeAppModal("")
        setOpen(false)
        
    }

    return (
        <div style={{ width: "100%", position: "relative", minHeight: props.appHeight - 100 }}>
            <Modal
                onClose={() => closeModal()}
                onOpen={() => setOpen(true)}
                open={open}
                centered={false}
                size="large"
            >
                <Modal.Header>
                    <Grid verticalAlign='middle'>
                        <Grid.Row >
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as={smallViewTel<width ? 'h3' : 'h4'} textAlign='center'>
                                    <Icon name={authContext.appViewModal && authContext.appViewModal.appViewIcon ? authContext.appViewModal.appViewIcon : "chevron circle right"} color="blue" />
                                    <Header.Content>{authContext.appViewModal && authContext.appViewModal.appViewName ? authContext.appViewModal.appViewName : "nNn"}</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" >
                                <Image floated='right' size='small' centered src={infiberlogolb} alt="IN_FIBER" />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Modal.Header>

                <Modal.Content className='bg'>
                    {projectView(authContext.appViewModal, props.appHeight - 100)}
                </Modal.Content>
                <Modal.Actions>
                    <Button size='tiny' onClick={() => closeModal()}>
                        ZAMKNIJ
                    </Button>
                   
                </Modal.Actions>
            </Modal>
            {projectView(authContext.appView, props.appHeight - 100)}
        </div>
    );
}

export default LayoutConcent;