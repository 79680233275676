import { useContext } from "react";
import AuthContext from "../context/authContext";
import {  SCezarencrypt } from "../myFunc";

export default function useAuth(){
    const authContext = useContext(AuthContext);
    const auth=authContext.isAuthenticated;
    const setAuth = (isAuthenticated,tokenData = null,userInfo=null) => {
        if(isAuthenticated){
              
            if(tokenData){
                window.sessionStorage.setItem('token',tokenData);   
                authContext.changeTokenApp(tokenData) 
            }
            if(userInfo){
                window.sessionStorage.setItem('userInfo',SCezarencrypt(JSON.stringify(userInfo))); 
                authContext.changeUserInfo(userInfo)    
            }  
           
            authContext.login(); 
        }else{
            authContext.logout();
            window.sessionStorage.clear();
            window.localStorage.clear();
            authContext.changeTokenApp(null)
            
        }
        
        
    }
    return [auth,setAuth]
}