

import React from 'react';
import {  Grid, Header, Icon, Image } from 'semantic-ui-react';
import infiberlogolb from '../assets/images/in-fiberlb.png';
import { smallViewTel } from '../myFunc';
import useWindowDimensions from '../hooks/useWindowDimensions';

const ModalUIHeader = (props) => {
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

    return (
        <Grid verticalAlign='middle'>
            <Grid.Row >
                <Grid.Column width={12} verticalAlign="middle">
                    <Header as={smallViewTel < width ? 'h3' : 'h4'} textAlign='center'>
                        <Icon name="chevron circle right" color="blue" />
                        <Header.Content>{props.propsUiName ? props.propsUiName : ""}</Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right" >
                    <Image floated='right' size='small' centered src={infiberlogolb} alt="IN_FIBER" />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ModalUIHeader;
