import React, { useEffect, useState, useContext } from 'react';
import { Button,  Icon, Label, Message,  Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';


import AGGridActivity from '../AGGrid/AGGridActivity';
import AddPermissions from '../Commissions/Workers/AddPermissions';





function GetUserPower(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);
   const [appType, setappType] = useState(0);
   const [appDelRow, setappDelRow] = useState(0);

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };



   function getAllItems(user_id) {
      if (gridApi && gridApi.destroyCalled === false) {
         gridApi.showLoadingOverlay();
      }
      setRowData(null)
      ConnectWS("/users/user_power", "get", authContext.tokenApp, null, { user_id: user_id }, function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);
            if (gridApi) {
               gridApi.showNoRowsOverlay();
            }
         } else if (Number(response.status) === 200) {

            if (response.data.length > 0) {
               setRowData(response.data)
               if (gridApi) {
                  gridApi.hideOverlay();
               }
            } else {
               setRowData([])
               if (gridApi) {
                  gridApi.showNoRowsOverlay();
               }
            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);
            if (gridApi) {
               gridApi.showNoRowsOverlay();
            }
         }


      })
   }



   useEffect(() => {
      if (props.user_id && appType===0) {
         getAllItems(props.user_id)
      }


      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [props.user_id,authContext.updataApp,appType]);// eslint-disable-line react-hooks/exhaustive-deps



   const statusIconFD = props => {

      if (props.data.status === 0) {
         return <Icon name="circle" title="Przyszłe" color='blue' size='large' />;
      } else {
         return <Icon name="circle" title="Wygasło" color='grey' size='large' />;
      }
   };

   const delRowIconCF = props => {
      const DelObjectRowCF = (data) => {
         setappType(1)
         setappDelRow(data.user_power_id)
         
      };

      return <Icon name="trash" color='grey' size='large' className='cursorPointer' onClick={()=>DelObjectRowCF(props.data)} />
   };

   function DelObjectRowACC() {
      ConnectWS("/users/user_power", "delete", authContext.tokenApp, null, [appDelRow], function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            setappType(0)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   function addInfoFD() {
      setappType(2)
   }

   return (
      <div style={{ minHeight: props.minHeight }}>
         <Segment  >
            <Label color='blue' ribbon>
               UPRAWNIENIA
            </Label>

            {appType === 0 ? <>

               <Button fluid size='tiny' color="blue" className='appbtnmtd' onClick={() => addInfoFD()}>Dodaj uprawnienie</Button>
               <Button fluid size='tiny' className='appbtnmtd' onClick={() => getAllItems(props.user_id)}>Pobierz uprawnienia</Button>
               <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
               <div
                  id="myGrid"
                  style={{
                     height: props.minHeight - 100,
                     // height:  '100%',
                     width: '100%',
                     marginTop: 5
                  }}
                  className="ag-theme-balham"
               >
                  <AgGridReact
                     //rowHeight={rowHeightAGG}
                     rowSelection={'single'}
                     // onSelectionChanged={onSelectionChanged}
                     suppressRowClickSelection={true}

                     animateRows={true}
                     pagination={true}
                     defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: false,
                        width: 100,
                        minWidth: 100,
                        wrapText: true,
                        autoHeight: true,
                        resizable: true,
                        floatingFilter: false,
                        flex: 1,
                     }}

                     frameworkComponents={{
                        statusIconFD: statusIconFD,
                        delRowIconCF: delRowIconCF,
                     }}

                     getRowId={function (data) {
                        return data.data.user_power_id
                     }}
                     localeText={AG_GRID_LOCALE_PL}
                     rowData={rowData}
                     onGridReady={onGridReady}
                  >

                     <AgGridColumn cellRenderer='statusIconFD'
                        width={50}
                        minWidth={50}
                        flex={0}
                     />

                     <AgGridColumn field="name" headerName='Opis' filter='agTextColumnFilter' />
                     <AgGridColumn field="start_at" headerName='Od' filter='agTextColumnFilter' width={130}
                        minWidth={130} flex={0} />
                     <AgGridColumn field="end_at" headerName='Do' filter='agTextColumnFilter'
                        width={130}
                        minWidth={130} flex={0} />
                        <AgGridColumn field="created_at" headerName='Utworzone' filter='agTextColumnFilter'
                        width={140}
                        minWidth={140} flex={0} />
                     <AgGridColumn cellRenderer='delRowIconCF'
                        width={50}
                        minWidth={50}
                        flex={0}
                     />



                  </AgGridReact>
               </div>
            </>
               :
               appType === 1 ?
               
                     <Message icon>
                        <Icon name='help circle' />
                        <Message.Content>
                           <Message.Header>Czy chcesz skasować rekord w uprawnieniach?</Message.Header>
                           Proszę potwierdzić
                        </Message.Content>
                        <Button color='red' onClick={() => DelObjectRowACC()}>TAK</Button>
                        <Button onClick={() => setappType(0)}>NIE</Button>
                     </Message>

               : appType === 2 ?
                     <>
                        <AddPermissions user_id={props.user_id} setappType={setappType} />
                        <br />
                        <Button fluid size='tiny' className='appbtnmtd' onClick={() => setappType(0)}>WSTECZ</Button>
                     </> : "n/n"

            }

         </Segment>
      </div>
   );
}

export default GetUserPower;