import React, { useState, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import { Grid, Icon, Segment, Button as ButtonSU } from 'semantic-ui-react';
import AGGridActivity from '../AGGrid/AGGridActivity';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_PL } from '../AGGrid/LanguageAGG';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authContext';
import { cfgToast } from '../../myFunc';
import SwitchServerRack from './SwitchServerRack/SwitchServerRack';
import { confirmAlert } from 'react-confirm-alert'; // Import


function SwitchSites(props) {
   const authContext = useContext(AuthContext);
   const [open, setOpen] = useState(null)
   const [editData, seteditData] = useState(false)
   const defaultValues = {
      site_id: '',
      site_name: '',
      address: '',
      room: '',
      lat: '000',
      lon: '000'
   }
   const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
   const [isloading, setLoading] = useState(false);// eslint-disable-line no-unused-vars

   const submit = (data) => {
      ConnectWS("/infiber/site", editData ? "put" : "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            getData()
            reset()

            if (editData) {
               seteditData(false)
            }
         } else if (Number(response.status) === 204) {

         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };



   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);

   };

   const statusIcon = props => {
      return <Icon name="building" size='large' />
   };

   const delRowIcon = props => {
      const DelObjectRow = () => {
         confirmAlert({
            title: 'Czy chcesz skasować rekord?',
            message: 'Proszę potwierdzić',
            buttons: [
               {
                  label: 'Tak',
                  onClick: () => DelObjectRowACC(props.data)
               },
               {
                  label: 'Nie',
                  onClick: () => ''
               }
            ]
         });
      };

      return <Icon name="trash" color='grey' size='large' className='cursorPointer' onClick={DelObjectRow} />
   };

   function DelObjectRowACC(data) {


      ConnectWS("/infiber/site", "delete", authContext.tokenApp, null, { site_id: data.site_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            getData()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   const editRowIcon = props => {
      const editRowIconA = () => {
         //seteditDeviceData(props.data)
         //setOpen(true)
         seteditData(true)
         setValue("site_name", props.data.site_name)
         setValue("address", props.data.address)
         setValue("room", props.data.room)
         setValue("site_id", props.data.site_id)

      };

      return <Icon name="edit" color='grey' size='large' className='cursorPointer' onClick={editRowIconA} />
   };


   const showRack = props => {

      return <Button className="p-button-secondary" onClick={(() => setOpen(props.data))}>
         <Icon name='server' />
         Pokaż szafy
      </Button>

   };

   useEffect(() => {
      getData()

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps



   function getData() {
     
      ConnectWS("/infiber/site", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {

            
            if (response.data.length > 0) {
               setRowData(response.data)
            } else {
               setRowData([])
            }
         } else if (Number(response.status) === 204) {
            
            setRowData([])
         } else {
            
            setRowData([])
            toast.error(response, cfgToast);
            console.log(response);
         }
      })
   }



   function clearForm() {
      reset()
      seteditData(false)
   }

   return (
      <>
         {open !== null ?
            <Segment style={{ padding: 25 }} className='bgcard'>
               <SwitchServerRack dataSite={JSON.stringify(open)} />
               <ButtonSU style={{marginTop:10}} fluid size='tiny' onClick={() => setOpen(null)}>
                  ZAMKNIJ
               </ButtonSU>
            </Segment>
            : <>

               <Segment style={{ padding: 25 }} className='bgcard'>

                  <form onSubmit={handleSubmit(submit)} className="p-fluid">



                     <Grid stackable>
                        <Grid.Row >
                           <Grid.Column width={4} className="p-fluid">

                              <span className="p-float-label ">
                                 <Controller name="site_name" control={control} rules={{ required: 'Nazwa jest wymagana', pattern: { value: /^.{3,253}$/i, message: 'Min 3 znaki' } }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                 )} />
                                 <label htmlFor="site_name" className={classNames({ 'p-error': errors.site_name })}>Nazwa*</label>
                              </span>
                              {getFormErrorMessage('site_name')}

                           </Grid.Column>

                           <Grid.Column width={4} className="p-fluid">

                              <span className="p-float-label">
                                 <Controller name="address" control={control} rules={{ required: 'Adres jest wymagany', pattern: { value: /^.{3,253}$/i, message: 'Min 3 znaki' } }}

                                    render={({ field, fieldState }) => (

                                       <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                                    )}
                                 />
                                 <label htmlFor="address" className={classNames({ 'p-error': errors.address })}>Adres*</label>
                              </span>
                              {getFormErrorMessage('address')}


                           </Grid.Column>

                           <Grid.Column width={4} className="p-fluid">

                              <span className="p-float-label">
                                 <Controller name="room" control={control} rules={{ required: 'Pomieszczenie jest wymagane', pattern: { value: /^.{1,253}$/i, message: 'Min 1 znak' } }}

                                    render={({ field, fieldState }) => (

                                       <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                                    )}
                                 />
                                 <label htmlFor="room" className={classNames({ 'p-error': errors.room })}>Pomieszczenie*</label>
                              </span>
                              {getFormErrorMessage('room')}


                           </Grid.Column>




                           <Grid.Column width={editData ? 2 : 4} className="p-fluid">
                              <Button type="submit" label={editData ? "Modyfikuj obiekt" : "Dodaj obiekt"} loading={isloading} />

                           </Grid.Column>
                           {editData ?
                              <Grid.Column width={2} className="p-fluid">
                                 <Button label="Wyczyść" onClick={() => clearForm()} loading={isloading} className="p-button-secondary" />
                              </Grid.Column>
                              : ""}
                        </Grid.Row>
                     </Grid>








                  </form>
               </Segment>
               <Segment className='bgcard'>
                  <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                  <div
                     id="myGrid"
                     style={{
                        height: props.minHeight - 180,
                        // height:  '100%',
                        width: '100%',
                        marginTop: 5
                     }}
                     className="ag-theme-balham"
                  >
                     <AgGridReact

                        animateRows={true}
                        pagination={true}
                        defaultColDef={{
                           editable: false,
                           sortable: true,
                           filter: false,
                           width: 100,
                           minWidth: 100,
                           wrapText: true,
                           autoHeight: true,
                           resizable: true,
                           floatingFilter: false,
                           flex: 1,
                        }}
                        frameworkComponents={{
                           statusIcon: statusIcon,
                           showRack: showRack,
                           delRowIcon: delRowIcon,
                           editRowIcon: editRowIcon
                        }}

                        getRowId={function (data) {
                           return data.data.site_id

                        }}
                        localeText={AG_GRID_LOCALE_PL}
                        rowData={rowData}
                        onGridReady={onGridReady}
                     >
                        <AgGridColumn cellRenderer='statusIcon'
                           width={45}
                           minWidth={45}
                           flex={0}
                        />

                        <AgGridColumn field="site_name" headerName='Nazwa' filter='agTextColumnFilter'
                        />
                        <AgGridColumn field="address" headerName='Adress' filter='agTextColumnFilter' />
                        <AgGridColumn field="room" headerName='Pokój' filter='agTextColumnFilter' />
                        <AgGridColumn cellRenderer='editRowIcon'
                           width={45}
                           minWidth={45}
                           flex={0}
                        />
                        <AgGridColumn cellRenderer='delRowIcon'
                           width={45}
                           minWidth={45}
                           flex={0}
                        />
                        <AgGridColumn cellRenderer='showRack'
                           width={150}
                           minWidth={150}
                           flex={0}
                        />

                     </AgGridReact>
                  </div>
               </Segment>
            </>
         }
      </>
   );
}

export default SwitchSites;