import React, { useState, useEffect, useContext } from 'react';

import { cfgToast, changeFormatDateTimeDBns } from '../../../myFunc';

import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { Button, Dropdown, Grid, Icon, Label, Segment, Table, } from 'semantic-ui-react';
import { Button as ButtonPrime } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Editor } from 'primereact/editor';
import Clients from '../Clients/Client';
import { Image } from 'primereact/image';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import AGGridActivity from '../../AGGrid/AGGridActivity';
import AddFileCommisionToCommision from './AddFileCommisionToCommision';
import CloseCommision from './CloseCommision';

function EditCommision(props) {
   const authContext = useContext(AuthContext);
   const [jsonData, setjsonData] = useState(null)
   const [jsonString, setjsonString] = useState(null)
   const [jsonMethod, setjsonMethod] = useState(null)
   const [jsonInputType, setjsonInputType] = useState(null)
   const [jsonWorkers, setjsonWorkers] = useState(null)
   const [commision_id, setcommision_id] = useState(null)


   const [mCommisionStatus, setmCommisionStatus] = useState([])
   const [mCommisionType, setmCommisionType] = useState([])

   const mOvertimeType = [
      { key: 1, value: 1, text: 'TAK' },
      { key: 2, value: 0, text: 'NIE' },
   ]

   const [form, setForm] = useState({
      mdate: null,
      minput: null,
      mcomboStatus: null,
      mcomboTypCommision: null,
      mdescription: null,
      movertime: null,
      mclient: null,
      mfiles: null,
      mworkers: null,
      mfilesWorkers: null,
      melements: null,
      mfields: null
   });

   const [formFile, setFormFile] = useState({
      file_name: null,
      description: null,
   });


   function getMasterServiceman(tmpcommision_id) {

      //setRowData([{ id: 1, name: "aadsdsd sdaasd  ", otherCommission: "", available: 1 }, { id: 2, name: "aadsdsd sda", otherCommission: "Inne zlecenie", available: 0 }])
      ConnectWS("/users/worker_availability", "get", authContext.tokenApp, null, { not_commision_id: commision_id ? commision_id : tmpcommision_id, date_from: changeFormatDateTimeDBns(props.dateFrom), date_to: changeFormatDateTimeDBns(props.dateTo) }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            setjsonWorkers(response.data)
            //setTimeout(function () { SelectedRowTabMasterServiceman() }, 500);
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         //onSelectionChanged()

      })
   }

   const onSelectionChanged = () => {
      if (gridApi) {
         var selectedRows = gridApi.getSelectedRows();
         if (selectedRows.length === 1) {
            let masterServiceman = selectedRows
            let tmp = []
            masterServiceman.forEach((row) => {
               tmp.push({ user_id: row.user_id, owner: true })
            })
            masterServiceman = tmp
            let tmpmworkers = { ...form.mworkers }
            tmpmworkers["workerOwner"] = masterServiceman
            setForm({ ...form, mworkers: tmpmworkers })
         }
         else {
            let tmpmworkers = { ...form.mworkers }
            tmpmworkers["workerOwner"] = []
            setForm({ ...form, mworkers: tmpmworkers })

         }
      }
   };

   const onSelectionChanged1 = () => {
      if (gridApi1) {
         var selectedRows = gridApi1.getSelectedRows();
         if (selectedRows.length >= 1) {
            let masterServiceman = selectedRows
            let tmp = []
            masterServiceman.forEach((row) => {
               tmp.push({ user_id: row.user_id, owner: false })
            })
            masterServiceman = tmp
            let tmpmworkers = { ...form.mworkers }
            tmpmworkers["worker"] = masterServiceman
            setForm({ ...form, mworkers: tmpmworkers })
         }
         else {
            let tmpmworkers = { ...form.mworkers }
            tmpmworkers["worker"] = []
            setForm({ ...form, mworkers: tmpmworkers })

         }
      }
   };



   function SelectedRowTabMasterServiceman() {
      if (null !== gridApi && gridApi.destroyCalled === false && null !== form.mworkers) {
         gridApi.forEachNode(function (node) {
            if (node.data) {
               if (form.mworkers.workerOwner && form.mworkers.workerOwner.length > 0) {
                  form.mworkers.workerOwner.forEach((row) => {
                     if (node.data['user_id'] === row.user_id) {
                        node.setSelected(true);
                     }
                  })
               }

            }
         });
      }

      if (null !== gridApi1 && gridApi1.destroyCalled === false && null !== form.mworkers) {

         gridApi1.forEachNode(function (node) {
            if (node.data) {
               if (form.mworkers.worker && form.mworkers.worker.length > 0) {
                  form.mworkers.worker.forEach((row) => {
                     if (node.data['user_id'] === row.user_id) {
                        node.setSelected(true);
                     }
                  })
               }
            }
         });
      }
   }

   function getCommisionStatus() {
      ConnectWS("commisions/status", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // setdataFileTemplates(response.data)
            let tmparr = []
            response.data.forEach((row, i) => {
               if (row.action !== 8 && row.action !== 9) {

                  if (props.isWorker === true && (row.action === 1 || row.action === 2)) {
                     tmparr.push({ key: i, value: row.commision_status_id, text: row.status })
                  } else if (props.isWorker !== true) {
                     tmparr.push({ key: i, value: row.commision_status_id, text: row.status })
                  }
               }
            })
            setmCommisionStatus(tmparr)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   function getCommisionType() {
      ConnectWS("commisions/types", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            // setdataFileTemplates(response.data)
            let tmparr = []
            response.data.forEach((row, i) => {
               if (props.isWorker && row.worker === 1) {
                  tmparr.push({ key: i, value: row.commision_type_id, text: row.type })
               } else if (props.isWorker === false) {
                  tmparr.push({ key: i, value: row.commision_type_id, text: row.type })
               }

            })
            setmCommisionType(tmparr)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   useEffect(() => {
      if (gridApi != null && gridApi1 != null) {
         SelectedRowTabMasterServiceman()
      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   },);// eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      setcommision_id(props.commision_id)

      if (props.jsonData) {
         setjsonData(props.jsonData)
      }
      if (props.jsonString) {
         setjsonString(props.jsonString)
      }
      if (props.jsonMethod) {
         setjsonMethod(props.jsonMethod)
      }
      if (props.jsonInputType) {
         setjsonInputType(props.jsonInputType)
         if (props.jsonInputType === "input") {
            setForm({ ...form, minput: props.jsonData[props.jsonString] })
         }
         if (props.jsonInputType === "comboStatus") {
            getCommisionStatus()
            setForm({ ...form, mcomboStatus: props.jsonData[props.jsonString] })
         }
         if (props.jsonInputType === "comboTypCommision") {
            getCommisionType()
            setForm({ ...form, mcomboTypCommision: props.jsonData[props.jsonString] })
         }
         if (props.jsonInputType === "date") {
            setForm({ ...form, mdate: new Date(props.jsonData[props.jsonString]) })
         }
         if (props.jsonInputType === "description") {
            setForm({ ...form, mdescription: props.jsonData[props.jsonString] !== "" ? props.jsonData[props.jsonString] : <></> })
         }
         if (props.jsonInputType === "overtime") {
            setForm({ ...form, movertime: props.jsonData[props.jsonString] })
         }
         if (props.jsonInputType === "client") {
            setForm({ ...form, mclient: props.jsonData[props.jsonString] })
         }
         if (props.jsonInputType === "files") {
            setForm({ ...form, mfiles: props.jsonData })
         }
         if (props.jsonInputType === "fields") {
            setForm({ ...form, mfields: props.jsonData })
         }
         if (props.jsonInputType === "filesWorkers") {
            setForm({ ...form, mfilesWorkers: props.jsonData })
         }
         if (props.jsonInputType === "workers") {
            getMasterServiceman(props.commision_id)
            setForm({ ...form, mworkers: props.jsonData })
         }
         if (props.jsonInputType === "elements") {
            setForm({ ...form, melements: props.jsonData })
         }


      }

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps



   function saveEditData() {
      let jsonTMP = null

      jsonTMP = { ...jsonData }
      if (jsonInputType === "input") {
         jsonTMP[jsonString] = form.minput
      }
      if (jsonInputType === "comboStatus") {
         jsonTMP = { status_id: form.mcomboStatus }
      }
      if (jsonInputType === "comboTypCommision") {
         jsonTMP[jsonString] = form.mcomboTypCommision
      }
      if (jsonInputType === "date") {
         jsonTMP[jsonString] = changeFormatDateTimeDBns(form.mdate)
      }
      if (jsonInputType === "description") {
         jsonTMP[jsonString] = form.mdescription
      }
      if (jsonInputType === "overtime") {
         jsonTMP[jsonString] = form.movertime
      }
      if (props.jsonInputType === "client") {
         jsonTMP[jsonString] = authContext.clientInfo.client_id
      }
      if (jsonInputType === "workers") {
         jsonTMP = { workers: form.mworkers.workerOwner.concat(form.mworkers.worker) }
      }


      ConnectWS(jsonMethod, "put", authContext.tokenApp, null, { commision_id: commision_id, ...jsonTMP }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
      props.setOpen(false)
   }

   // lista pól
   
   function delRowFieldOnList(commision_field_id) {
      ConnectWS("commisions/fields", "delete", authContext.tokenApp, null, { commision_field_id: commision_field_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            props.setOpen(false)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   
   function addFieldToList() {
      ConnectWS("commisions/fields", "post", authContext.tokenApp, null, { commision_id: commision_id, fields_list: [{ name: formFile.file_name }] }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            props.setOpen(false)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   // lista plików
   function delRowFileOnList(commision_file_id) {
      ConnectWS("commisions/files", "delete", authContext.tokenApp, null, { commision_file_id: commision_file_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            props.setOpen(false)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   function addFileToList() {
      ConnectWS("commisions/files", "post", authContext.tokenApp, null, { commision_id: commision_id, file_list: [{ file_name: formFile.file_name, description: formFile.description }] }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            props.setOpen(false)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   const renderHeader = () => {
      return (
         <>
            <span className="ql-formats">
               <button className="ql-bold" aria-label="Bold"></button>
               <button className="ql-italic" aria-label="Italic"></button>
               <button className="ql-underline" aria-label="Underline"></button>
               <button className="ql-list" aria-label="Ordered List" value="ordered"></button>
               <button className="ql-list" aria-label="Unordered List" value="bullet"></button>
            </span>

         </>
      );
   }

   const header = renderHeader();


   // Instalator master
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };


   //subinstalator
   const [gridApi1, setGridApi1] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi1, setGridColumnApi1] = useState(null);// eslint-disable-line no-unused-vars

   const onGridReady1 = (params) => {
      setGridApi1(params.api);
      setGridColumnApi1(params.columnApi);
   };

   const isactiveIcon = props => {
      let ret
      props.data.availability ? ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="DOSTĘPNY" >
         DOSTĘPNY
      </Label> : ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='red' title="NIEDOSTĘPNY" >
         NIEDOSTĘPNY
      </Label>
      return ret
   };

   const cellStyle = (props) => {
      return props.data.availability === 0 ? { 'pointer-events': 'none', opacity: '0.3' } : { opacity: '1' }
   };

   return (
      <>
         {form.minput !== null ?
            <Grid className='clearmp' >
               <Grid.Row className='clearmp' stretched >
                  <Grid.Column width={10}>
                     <span className="p-fluid">
                        <InputText value={form.minput} onChange={(e) => setForm({ ...form, minput: e.target.value })} />
                     </span>
                  </Grid.Column>
                  <Grid.Column width={6}>
                     <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
                  </Grid.Column>
               </Grid.Row>
            </Grid>
            : ""}
         {form.mcomboStatus ?
            <Grid className='clearmp' >
               <Grid.Row className='clearmp' stretched >
                  <Grid.Column width={10}>
                     <Dropdown
                        fluid
                        search
                        selection
                        value={form.mcomboStatus}
                        onChange={(e, d) => setForm({ ...form, mcomboStatus: d.value })}
                        options={mCommisionStatus}

                     />
                  </Grid.Column>
                  <Grid.Column width={6}>
                     <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
                  </Grid.Column>
               </Grid.Row>
            </Grid>
            : ""}
         {form.mcomboTypCommision ?
            <Grid className='clearmp' >
               <Grid.Row className='clearmp' stretched >
                  <Grid.Column width={10}>
                     <Dropdown
                        fluid
                        search
                        selection
                        value={form.mcomboTypCommision}
                        onChange={(e, d) => setForm({ ...form, mcomboTypCommision: d.value })}
                        options={mCommisionType}

                     />
                  </Grid.Column>
                  <Grid.Column width={6}>
                     <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
                  </Grid.Column>
               </Grid.Row>
            </Grid>
            : ""}
         {form.mdate ?
            <Grid className='clearmp' >
               <Grid.Row className='clearmp' stretched >
                  <Grid.Column width={10}>
                     <Calendar value={form.mdate} onChange={(e) => setForm({ ...form, mdate: e.value })} dateFormat="yy-mm-dd" showTime showIcon />
                  </Grid.Column>
                  <Grid.Column width={6}>
                     <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
                  </Grid.Column>
               </Grid.Row>
            </Grid>
            : ""}
         {form.mdescription !== null ?
            <div>
               <Editor headerTemplate={header} style={{ height: '320px' }} value={form.mdescription} onTextChange={(e) => setForm({ ...form, mdescription: e.htmlValue !== null ? e.htmlValue : "" })} />
               <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
            </div>
            : ""}

         {form.movertime !== null ?
            <div>
               <Grid className='clearmp' >
                  <Grid.Row className='clearmp' stretched >
                     <Grid.Column width={10}>
                        <Dropdown
                           fluid
                           search
                           selection
                           value={form.movertime}
                           onChange={(e, d) => setForm({ ...form, movertime: d.value })}
                           options={mOvertimeType}

                        />
                     </Grid.Column>
                     <Grid.Column width={6}>
                        <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
                     </Grid.Column>
                  </Grid.Row>
               </Grid>
            </div>
            : ""}

         {form.mclient !== null ?
            <div>
               <Clients minHeight={500} onlyChoose={true} onlyActive={true} />
               <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
            </div>
            : ""}
         {form.melements !== null ?
            <div>
               <CloseCommision edit={true} setOpen={props.setOpen} commision_id={commision_id} jsonCommision={form.melements} setopenModalCC={null} />

            </div>
            : ""}
         {form.mworkers !== null ?
            <div>
               <Segment className='bgcard'>
                  <Label color='blue' ribbon style={{ marginBottom: 5 }}>
                     Główny serwisant
                  </Label>
                  <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                  <div
                     id="myGrid"
                     style={{
                        height: 300,
                        // height:  '100%',
                        width: '100%',
                        marginTop: 5
                     }}
                     className="ag-theme-balham"
                  >
                     <AgGridReact
                        //rowHeight={rowHeightAGG}
                        rowSelection={'single'}
                        onSelectionChanged={onSelectionChanged}
                        suppressRowClickSelection={true}

                        animateRows={true}
                        pagination={true}
                        defaultColDef={{
                           editable: false,
                           sortable: true,
                           filter: false,
                           width: 100,
                           minWidth: 100,
                           wrapText: true,
                           autoHeight: true,
                           resizable: true,
                           floatingFilter: false,
                           flex: 1,

                        }}
                        frameworkComponents={{
                           isactiveIcon: isactiveIcon,
                        }}

                        getRowId={function (data) {
                           return data.data.user_id

                        }}
                        localeText={AG_GRID_LOCALE_PL}
                        rowData={jsonWorkers}
                        onGridReady={onGridReady}
                     >

                        <AgGridColumn
                           checkboxSelection={true}
                           field="name" headerName='Imie' filter='agTextColumnFilter'
                           cellStyle={cellStyle}
                        />
                        <AgGridColumn
                           field="surname" headerName='Nazwisko' filter='agTextColumnFilter'
                           cellStyle={cellStyle}
                        />




                        <AgGridColumn cellRenderer='isactiveIcon'
                           headerName='Dostępność'
                           width={150}
                           minWidth={150}
                           flex={0}
                        />

                     </AgGridReact>
                  </div>
                  <Label color='blue' ribbon style={{ marginBottom: 5, marginTop: 10 }}>
                     Pozostali serwisanci
                  </Label>
                  <AGGridActivity gridApi={gridApi1} gridColumnApi={gridColumnApi1} />
                  <div
                     id="myGrid1"
                     style={{
                        height: 300,
                        // height:  '100%',
                        width: '100%',
                        marginTop: 5
                     }}
                     className="ag-theme-balham"
                  >
                     <AgGridReact
                        //rowHeight={rowHeightAGG}
                        rowSelection={'multiple'}
                        onSelectionChanged={onSelectionChanged1}
                        suppressRowClickSelection={true}

                        animateRows={true}
                        pagination={true}
                        defaultColDef={{
                           editable: false,
                           sortable: true,
                           filter: false,
                           width: 100,
                           minWidth: 100,
                           wrapText: true,
                           autoHeight: true,
                           resizable: true,
                           floatingFilter: false,
                           flex: 1,
                        }}
                        frameworkComponents={{
                           isactiveIcon: isactiveIcon,
                        }}

                        getRowId={function (data) {
                           return data.data.user_id

                        }}
                        localeText={AG_GRID_LOCALE_PL}
                        rowData={jsonWorkers}
                        onGridReady={onGridReady1}
                     >

                        <AgGridColumn
                           checkboxSelection={true}
                           cellStyle={cellStyle}
                           field="name" headerName='Nazwa' filter='agTextColumnFilter' />
                        <AgGridColumn
                           field="surname" headerName='Nazwisko' filter='agTextColumnFilter'
                           cellStyle={cellStyle}
                        />

                        <AgGridColumn cellRenderer='isactiveIcon'
                           headerName='Dostępność'
                           width={150}
                           minWidth={150}
                           flex={0}
                        />
                     </AgGridReact>
                  </div>
               </Segment>
               <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
            </div>
            : ""}

         {form.mfiles !== null ?
            <div>
               <div className="p-inputgroup">
                  <ButtonPrime label="Dodaj plik do listy" icon="pi pi-plus" iconPos="right" type='button' onClick={() => addFileToList()} disabled={formFile.file_name === null || formFile.file_name.length < 3 || formFile.description === null || formFile.description.lengthDB < 3 ? true : false} className="p-button-secondary" />
                  <InputText placeholder="Nazwa pliku" value={formFile.file_name} onChange={(e) => setFormFile({ ...formFile, file_name: e.target.value })} />
                  <InputText placeholder="Opis pliku do wyświetlenia" value={formFile.description} onChange={(e) => setFormFile({ ...formFile, description: e.target.value })} />
               </div>
               <Table celled compact size='small'>
                  <Table.Header>
                     <Table.Row textAlign='center'>
                        <Table.HeaderCell>Nazwa pliku</Table.HeaderCell>
                        <Table.HeaderCell>Opis pliku</Table.HeaderCell>
                        <Table.HeaderCell>Zdjęcie</Table.HeaderCell>
                        <Table.HeaderCell>Kasowanie</Table.HeaderCell>
                     </Table.Row>
                  </Table.Header>

                  <Table.Body >
                     {form.mfiles.map((row, i) =>
                        <Table.Row key={i}>
                           <Table.Cell textAlign='center'>
                              {row.file_name}
                           </Table.Cell>
                           <Table.Cell textAlign='center'>{row.description}</Table.Cell>
                           <Table.Cell width={1} textAlign='center'>{row.file_path ? <Image src={row.file_path} alt="Image" width="100" preview /> : ""}</Table.Cell>
                           <Table.Cell width={1} textAlign='center'><Icon className='cursorPointer' name="trash" onClick={() => delRowFileOnList(row.commision_file_id)} /></Table.Cell>
                        </Table.Row>
                     )}


                  </Table.Body>
               </Table>
               <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
            </div>
            : ""}


         {form.mfilesWorkers !== null ?
            <AddFileCommisionToCommision commision_id={commision_id} />
            : ""}

         {form.mfields !== null ?
            <div>
                <div className="p-inputgroup">
                  <ButtonPrime label="Dodaj pole do listy" icon="pi pi-plus" iconPos="right" type='button' onClick={() => addFieldToList()} disabled={formFile.file_name === null || formFile.file_name.length < 3 ? true : false} className="p-button-secondary" />
                  <InputText placeholder="Nazwa pola" value={formFile.file_name} onChange={(e) => setFormFile({ ...formFile, file_name: e.target.value })} />
               </div>
               <Table celled compact size='small'>
                  <Table.Header>
                     <Table.Row textAlign='center'>
                        <Table.HeaderCell>Nazwa Pola</Table.HeaderCell>
                        <Table.HeaderCell>Wartość</Table.HeaderCell>
                        <Table.HeaderCell>Kasowanie</Table.HeaderCell>
                     </Table.Row>
                  </Table.Header>

                  <Table.Body >
                     {form.mfields.map((row, i) =>
                        <Table.Row key={i} negative={row.value === null ? true : false}>
                           <Table.Cell textAlign='center'>
                              {row.name}
                           </Table.Cell>
                           <Table.Cell textAlign='center'>{row.value ? <><Icon name="check"/> UZUPEŁNIONA </>: <><Icon name="x"/> BRAK</>}</Table.Cell>
                           <Table.Cell width={1} textAlign='center'><Icon className='cursorPointer' name="trash" onClick={() => delRowFieldOnList(row.commision_field_id)} /></Table.Cell>
                        </Table.Row>
                     )}


                  </Table.Body>
               </Table>
               <Button fluid size='tiny' color='blue' onClick={() => saveEditData()}>Edytuj</Button>
            </div>
            : ""}
      </>
   );
}

export default EditCommision;