import React, { useState, useContext, useEffect } from 'react';
import { Card } from 'primereact/card';
import { toast } from 'react-toastify';
import { cfgToast } from '../../../../myFunc';

import { ConnectWS } from '../../../../ConnectWS/ConnectWS';
import AuthContext from '../../../../context/authContext';

import { Button, Divider, Header, Icon, Label, Message, Segment, Statistic } from 'semantic-ui-react';

import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';

function ChangeWhItem(props) {
   const authContext = useContext(AuthContext);

   const [accChange, setaccChange] = useState(false);

   const [maxValueCount, setmaxValueCount] = useState(0);
   const [items, setitems] = useState([]);
   const [filteredItems, setFilteredItems] = useState(null);

   const searchItems = (event) => {
      //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
      let query = event.query;
      let _filteredItems = [];

      for (let i = 0; i < items.length; i++) {
         let item = items[i];
         if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            _filteredItems.push(item);
         }
      }

      setFilteredItems(_filteredItems);
   }

   const [form, setform] = useState({
      warehouseA: { name: null, currentCount: 0, value: null },
      itemCountChangeAtoB: 0,
      warehouseB: { name: null, currentCount: 0, value: null },
   });

   function changeCountItem(value) {
      setform({ ...form, itemCountChangeAtoB: value}) 
   }




   useEffect(() => {
      console.log(props)
      getWarehouse()
      setform({ ...form, warehouseA: { name: props.userInfo.allInfo.owner_client ? props.userInfo.allInfo.owner_client : props.userInfo.allInfo.owner_user, currentCount: props.userInfo.allInfo.quantity, value: props.userInfo.allInfo } })
      setmaxValueCount(props.userInfo.allInfo.quantity)
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps


   function getWarehouse() {
      ConnectWS("/warehouse", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               let tmparr = []
               response.data.forEach((row, i) => {

                  tmparr.push({ label: row.description, value: row })

               })
               setitems(tmparr);
            }
         } else {
            toast.error(response, cfgToast);
            //  console.log(response);
         }

      })
   }


   function changeItemAcc() {

      let source_warehouse_id = form.warehouseA.value.warehouse_id
      let destination_warehouse_id = form.warehouseB.value.value.warehouse_id
      let element_id = form.warehouseA.value.element_id
      let quantity = form.itemCountChangeAtoB

      ConnectWS("/warehouse/change_warehouse", "put", authContext.tokenApp, null, {source_warehouse_id:source_warehouse_id,destination_warehouse_id:destination_warehouse_id,element_id:element_id,quantity:quantity}, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            props.setOpen(false)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         
      })
   }

   return (

      <>
         <Divider horizontal>
            <Header as='h5'>
               <Icon name='exchange' />
               Przeniesienie elementu [{props.userInfo.allInfo.short_name}] między magazynami
            </Header>
         </Divider>

         <Segment>
            <Label color='blue' ribbon>
               MAGAZYN A
            </Label>
            <Segment textAlign='center' basic style={{ margin: 0, padding: 0 }}>

               <Statistic>
                  <Statistic.Label>MAGAZYN: {form.warehouseA.name}</Statistic.Label>
                  <Statistic.Value>{form.warehouseA.currentCount-form.itemCountChangeAtoB}</Statistic.Value>
               </Statistic>


            </Segment>
         </Segment>
         {accChange ? "" :
            <Segment  textAlign='center' basic>
               Ilość do przeniesienia:<br />
               <InputNumber disabled={form.warehouseB.name===null} mode="decimal" inputId="horizontal" min={0} max={maxValueCount} value={form.itemCountChangeAtoB} onValueChange={(e) => changeCountItem(e.value)} showButtons buttonLayout="horizontal" step={1}
                  decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
            </Segment>
         }
         <Segment>
            <Label color='orange' ribbon>
               MAGAZYN B
            </Label>
            <Segment textAlign='center' basic style={{ margin: 0, padding: 0 }}>


               <AutoComplete disabled={accChange} style={{ width: "100%", marginTop: 10 }} suggestions={filteredItems} completeMethod={searchItems} virtualScrollerOptions={{ itemSize: 38 }} field="label" dropdown value={form.warehouseB.value} onChange={(e) => {
                  setform({ ...form, warehouseB: { name: e.value.value.description, currentCount: 0, value: e.value } })
               }} dropdownAriaLabel="Wybierz magazyn" forceSelection />

               <br />
               {form.warehouseB.name ?
                  <Statistic>
                     <Statistic.Label>MAGAZYN: {form.warehouseB.name}</Statistic.Label>
                     <Statistic.Value>{form.warehouseB.currentCount+form.itemCountChangeAtoB}</Statistic.Value>
                  </Statistic>
                  : ""}


            </Segment>
         </Segment>
         {accChange ? <Card className="bgcard">
            <Message icon>
               <Icon name='help circle' />
               <Message.Content>
                  <Message.Header>Czy chcesz przenieść {form.itemCountChangeAtoB} element między magazynami?</Message.Header>
                  Proszę potwierdzić
               </Message.Content>
               <Button color='red' onClick={() => changeItemAcc()}>TAK</Button>
               <Button onClick={() => setaccChange(false)}>NIE</Button>
            </Message>

         </Card> :
            <Button fluid color='blue' disabled={form.itemCountChangeAtoB < 1} onClick={() => setaccChange(true)}>ZAPISZ ZMIANY</Button>
         }

      </>
   );
}

export default ChangeWhItem;