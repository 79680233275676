
import { InputNumber } from 'primereact/inputnumber';
import { Icon, Table } from 'semantic-ui-react';
import { Dropdown } from 'primereact/dropdown';
import { Button as Buttonprime } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

function TableRows({ rowsData, deleteTableRows, handleChange, offDel,onlySearchId, handleChangeP, openModalGetId }) {



    const connectionTypesDef = ["peer", "internal"];

    return (

        rowsData.map((data, index) => {
            const { pointAID,
                pointAPort,
                pointBID,
                pointBPort,
                connectionsType } = data;
            return (

                <Table.Row key={index}>
                    <Table.Cell><span className="p-fluid"><InputText  disabled={offDel === 1 || onlySearchId ? true : false} value={pointAID} onChange={(evnt) => {
                        handleChange(index, evnt, "pointAID")
                        handleChangeP(index, evnt, "pointAID")
                    }} /></span></Table.Cell>
                    <Table.Cell width={1}><span className="p-fluid"><Buttonprime label="Szukaj" icon="pi pi-search" onClick={() => openModalGetId({open:true,name:"pointAID",index})} className="p-button-secondary" /></span></Table.Cell>
                    <Table.Cell><span className="p-fluid"><InputNumber htmlFor="integeronly" value={pointAPort} onChange={(evnt) => (handleChange(index, evnt, "pointAPort"))} /></span></Table.Cell>
                    <Table.Cell><span className="p-fluid"><InputText  disabled={offDel === 1 || onlySearchId ? true : false} value={pointBID} onChange={(evnt) => {
                        handleChange(index, evnt, "pointBID")
                        handleChangeP(index, evnt, "pointBID")
                    }} /></span></Table.Cell>
                    <Table.Cell width={1}><span className="p-fluid"><Buttonprime label="Szukaj" icon="pi pi-search" onClick={() => openModalGetId({open:true,name:"pointBID",index})} className="p-button-secondary" /></span></Table.Cell>

                    <Table.Cell><span className="p-fluid"><InputNumber htmlFor="integeronly" value={pointBPort} onChange={(evnt) => (handleChange(index, evnt, "pointBPort"))} /></span></Table.Cell>
                    <Table.Cell><span className="p-fluid"><Dropdown value={connectionsType} options={connectionTypesDef} onChange={(evnt) => (handleChange(index, evnt, "connectionsType"))} placeholder="Wybierz typ połączenia" /></span></Table.Cell>
                    <Table.Cell><Icon name="minus square" className={offDel === 1 ? '' : 'cursorPointer'} onClick={() => (offDel === 1 ? "" : deleteTableRows(index))} size="large" /></Table.Cell>

                </Table.Row>
            )
        })


    )

}

export default TableRows;