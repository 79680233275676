

import React, { useContext, useEffect, useRef, useState } from 'react';
import 'react-calendar-timeline/lib/Timeline.css'
import { fetchToken, onMessageListener } from '../firebase';
import { Divider } from 'primereact/divider';
import { Breadcrumb, Divider as Dividersur, Grid, Header, Icon, Label, Menu, Segment, Sidebar } from 'semantic-ui-react'
import useAuth from '../hooks/useAuth';
import { cfgToast, getDateTimeE, loaderAPP, smallViewTel } from '../myFunc';
import { toast } from 'react-toastify';
import useWindowDimensions from '../hooks/useWindowDimensions';
import infiberlogo from '../../src/assets/images/in-fiber.png';
import infiberlogolb from '../../src/assets/images/in-fiberlb.png';
import packageJson from '../../package.json';
import AuthContext from '../context/authContext';
import LayoutConcent from './LayoutConcent';
import MyTimerLive from '../MyTimerLive';
import { ConnectWS } from '../ConnectWS/ConnectWS';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
const Layout = (props) => {

    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const [isTokenFound, setTokenFound] = useState(false);
    const [inFullscreenMode, setInFullscreenMode] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [visibleDialog, setvisibleDialog] = useState({ openDialog: false, header: "", body: "" })

    const [isWorker, setWorker] = useState(false);

    function logoutApp() {
        toast.info('Pomyślne wylogowanie z aplikacji', cfgToast);
        setVisible(false)
        setAuth(false)
    }
    const ref = useRef(null)
    const [visible, setVisible] = useState(authContext.userInfo && null !== authContext.userInfo.worker && authContext.userInfo.worker === 1 ? false : true)
    const [listItemsMenuTab, setlistItemsMenuTab] = useState([]);// eslint-disable-line no-unused-vars

    /*  
     useEffect(() => {
  
      getFunctions()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps
   */

    function getFunctions() {
        if (authContext.userInfo && null !== authContext.userInfo.worker && authContext.userInfo.worker === 1) {
            let arrtmp = [
                {
                    "app_category_id": 3,
                    "category_name": "ZLECENIA-MAGAZYN",
                    "function": [

                        {
                            "app_functions_id": 28,
                            "app_category_id": 3,
                            "function_name": "Zlecenia",
                            "function_icon": "wrench",
                            "function_modal": 0,
                            "function_view": "commission_commissions"
                        }


                    ]
                }
            ]
            setWorker(true)
            setlistItemsMenuTab(arrtmp)
            changeView({ appViewCategory: arrtmp[0].category_name, appView: arrtmp[0].function[0].function_view, appModal: arrtmp[0].function[0].function_modal, appViewName: arrtmp[0].function[0].function_name, appViewIcon: arrtmp[0].function[0].function_icon })
            return
        } else {
            setWorker(false)
        }

        ConnectWS("/infiber/category", "get", authContext.tokenApp, null, null, function (response, logout) {
            if (logout === 1) {

                toast.error('Zaraz nastapi wylogowanie: ' + response, cfgToast);
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (Number(response.status) === 200) {
                let viewOk = 0
                setlistItemsMenuTab(response.data)
                response.data.length > 0 ? response.data.map((myfunction, i) =>
                    myfunction.function ? myfunction.function.forEach((myfunctionsmenu) => {
                        if (viewOk === 0 && (authContext.appView === null)) {
                            viewOk = 1
                            changeView({ appViewCategory: myfunction.category_name, appView: myfunctionsmenu.function_view, appModal: myfunctionsmenu.function_modal, appViewName: myfunctionsmenu.function_name, appViewIcon: myfunctionsmenu.function_icon })

                        }
                    }) : ""
                ) : toast.warning("Brak funkcji", cfgToast);



            } else {
                toast.error(response, cfgToast);

                //addToast('Error przy pobieraniu funkcji: ' + response, { appearance: 'error' });
                console.log(response);

            }
            if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
                setlistItemsMenuTab([
                    {
                        "app_category_id": 2,
                        "category_name": "Testy",
                        "function": [
                            {
                                "app_functions_id": 1,
                                "app_category_id": 2,
                                "function_name": "Itemy",
                                "function_icon": "building",
                                "function_modal": 0,
                                "function_view": "warehouses_items"
                            }

                        ]
                    }
                ])
            }
            setLoading(false)

        })

    }


    useEffect(() => {

        if (Number(process.env.REACT_APP_FIREBASE_ENABLED) === 1) {
            fetchToken(setTokenFound);
        }

        getFunctions()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    function saveFCM() {

        ConnectWS("/users/me/create_fcm", "post", authContext.tokenApp, null, { fcm: isTokenFound }, function (response, logout) {
            if (logout === 1) {
                /*
                toast.error('Zaraz nastapi wylogowanie: ' + response, cfgToast);
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
                */
            } else if (Number(response.status) === 200) {
                //toast.error(response, cfgToast);

            } else {
                toast.error(response, cfgToast);

                //addToast('Error przy pobieraniu funkcji: ' + response, { appearance: 'error' });
                console.log(response);

            }
            setLoading(false)

        })
    }

    useEffect(() => {
        if (isTokenFound) {
            saveFCM()
        }
    }, [isTokenFound]);// eslint-disable-line react-hooks/exhaustive-deps



    onMessageListener().then(payload => {
        //setNotification({ title: payload.notification.title, body: payload.notification.body })
        //setShow(true);
        //toast.info(payload.notification.title + ": " + payload.notification.body, cfgToastFirebase);
        setvisibleDialog({ openDialog: true, header: payload.notification.title, body: payload.notification.body })
        // console.log("onMessageListener", payload);
    }).catch(err => "");





    const changeView = (co) => {
        if (co.appModal === true || co.appModal === 1) {
            authContext.changeAppModal(co);
        } else {
            authContext.changeAppView(co);
            window.sessionStorage.setItem('appView', JSON.stringify(co));
        }
    }


    function toggleFullScreen(elem) {
        // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
            if (elem.requestFullScreen) {
                elem.requestFullScreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullScreen) {
                elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            setInFullscreenMode(true)
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setInFullscreenMode(false)
        }
    }


    return (
        <Sidebar.Pushable className='fullscreen-enabled'>
            <Sidebar
                //stackable
                animation={width < smallViewTel ? 'overlay' : 'push'}
                direction="left"
                onHide={() => width < smallViewTel ? setVisible(false) : ''}
                //vertical
                visible={visible}


            >

                <div style={{ textAlign: "center", marginTop: 10 }}>
                    <img src={infiberlogo} alt="IN-FIBER" style={{ width: 120, margin: 5 }} />
                    <br />
                    <Label size='small' color='blue' >
                        Ver: {packageJson.version}
                    </Label>
                    
                </div>

                <div className='wrapmenu'>

                    {Loading === false ?
                        listItemsMenuTab.length > 0 ? listItemsMenuTab.map((myfunction, i) =>
                            <div key={i}>
                                <Divider align="left" className='tconapp'> <Label>
                                    {myfunction.category_name}
                                </Label> </Divider>
                                <Menu fluid secondary vertical>
                                    {myfunction.function ? myfunction.function.map((myfunctionsmenu, j) =>

                                        <Menu.Item key={j} as='a' onClick={() => changeView({ appViewCategory: myfunction.category_name, appView: myfunctionsmenu.function_view, appModal: myfunctionsmenu.function_modal, appViewName: myfunctionsmenu.function_name, appViewIcon: myfunctionsmenu.function_icon })} >
                                            <Label className={myfunctionsmenu.label === undefined || myfunctionsmenu.label === "" ? "labelhidden" : myfunctionsmenu.label > 0 ? "labelappblink" : "labelapp"}>{myfunctionsmenu.label}</Label>
                                            <Icon name={myfunctionsmenu.function_icon} style={{ marginRight: 15 }} />
                                            {myfunctionsmenu.function_name}
                                        </Menu.Item>

                                    ) : ""}
                                </Menu>
                            </div>
                        ) : ""
                        : loaderAPP

                    }
                    
                     
                </div>
                
                <div className='appinfo'>
                   
                    Copyright by <br /> www.xxxx.pl</div>
            </Sidebar>

            <Sidebar.Pusher style={{ overflowY: 'auto', height: '100%' }} >
                <div ref={ref} style={{ minHeight: height, padding: 0, position: "relative", overflow: "auto", height: height, width: visible && width > smallViewTel ? 'calc(100% - ' + 260 + 'px)' : "100%" }}>
                    <Segment style={{ backgroundColor: "#ffffff", marginBottom: 12, padding: 0, margin: 0 }}>
                        <Grid style={{ margin: 0, padding: 0 }}>
                            {smallViewTel < width ? <>
                                <Grid.Column floated='left' width={7}>
                                    {isWorker ? "" :
                                        <div style={{ float: 'left' }}>
                                            <Icon name="bars" bordered color={visible ? "green" : "blue"} className='cursorPointer' onClick={() => setVisible(visible ? false : true)} />
                                            <Icon name="window maximize" color={inFullscreenMode ? "green" : "black"} onClick={(e) => toggleFullScreen(document.body)} bordered title="PEŁNY EKRAN" className='cursorPointer' />
                                        </div>
                                    }

                                    <div style={{ float: 'left', fontSize: '11px', paddingLeft: 5, paddingTop: 0, lineHeight: '14px', textAlign: 'left' }}>
                                        <Icon name="settings" />IN-XXX-APP<br />
                                        <Icon name="calendar" />{getDateTimeE("DATE", new Date())} <Icon name="time" /><MyTimerLive />
                                    </div>


                                </Grid.Column>
                                <Grid.Column textAlign='center' width={2}>

                                    <img src={infiberlogolb} alt="IN-FIBER" style={{ width: 100, opacity: 0.1 }} />

                                </Grid.Column>

                                <Grid.Column floated='right' width={5} textAlign="right">
                                    {isWorker ? <Icon className='cursorPointer' name="calendar alternate" onClick={() => changeView({ appViewCategory: "App", appView: "AppCurrentWorkerCalendar", appModal: 1, appViewName: "Kalendarz - Instalator", appViewIcon: "calendar alternate" })} /> : ""}
                                    {Number(process.env.REACT_APP_FIREBASE_ENABLED) === 1 ? isTokenFound ? <Icon className="tooltipApp" data-pr-tooltip="Powiadomienia włączone" name="alarm" color="green" /> : <Icon data-pr-tooltip="Powiadomienia wyłaczone" className="alarm" name="alarm mute" color="red" /> : ""}
                                    <span className='cursorPointer' onClick={() => isWorker ? changeView({ appViewCategory: "App", appView: "AppCurrentEditWorker", appModal: 1, appViewName: "Edycja danych - Instalator", appViewIcon: "edit" }) : changeView({ appViewCategory: "App", appView: "AppCurrentEditUser", appModal: 1, appViewName: "Edycja danych", appViewIcon: "edit" })}><Icon name='user' /> {smallViewTel < width ? authContext.userInfo.name.toUpperCase() : ""}</span>
                                    <Icon name='log out' style={{ marginLeft: 5 }} className="cursorPointer" bordered title="Wylogowanie" onClick={() => logoutApp()} />
                                </Grid.Column></>
                                :
                                <Grid.Column >
                                    {isWorker ? "" :
                                        <div style={{ float: 'left' }}>
                                            <Icon name="bars" bordered color={visible ? "green" : "blue"} className='cursorPointer' onClick={() => setVisible(visible ? false : true)} />
                                            <Icon name={inFullscreenMode ? "window minimize" : "window maximize"} onClick={(e) => toggleFullScreen(document.body)} bordered title="PEŁNY EKRAN" className='cursorPointer' />
                                        </div>
                                    }

                                    <div style={{ float: 'left', fontSize: '11px', paddingLeft: 5, paddingTop: 0, lineHeight: '14px', textAlign: 'left' }}>
                                        <Icon name="settings" />IN-XXX-APP<br />
                                        <Icon name="calendar" />{getDateTimeE("DATE", new Date())} <Icon name="time" /><MyTimerLive />
                                    </div>


                                    <div style={{ float: 'right', textAlign: 'right' }}>
                                        {isWorker ? <Icon className='cursorPointer' name="calendar alternate" onClick={() => changeView({ appViewCategory: "App", appView: "AppCurrentWorkerCalendar", appModal: 1, appViewName: "Kalendarz - Instalator", appViewIcon: "calendar alternate" })} /> : ""}
                                        {Number(process.env.REACT_APP_FIREBASE_ENABLED) === 1 ? isTokenFound ? <Icon className="tooltipApp" data-pr-tooltip="Powiadomienia włączone" name="alarm" color="green" /> : <Icon data-pr-tooltip="Powiadomienia wyłaczone" className="alarm" name="alarm mute" color="red" /> : ""}
                                        <span className='cursorPointer' onClick={() => isWorker ? changeView({ appViewCategory: "App", appView: "AppCurrentEditWorker", appModal: 1, appViewName: "Edycja danych - Instalator", appViewIcon: "edit" }) : changeView({ appViewCategory: "App", appView: "AppCurrentEditUser", appModal: 1, appViewName: "Edycja danych", appViewIcon: "edit" })}><Icon name='user' /> {smallViewTel < width ? authContext.userInfo.name.toUpperCase() : ""}</span>
                                        <Icon name='log out' style={{ marginLeft: 5 }} className="cursorPointer" bordered title="Wylogowanie" onClick={() => logoutApp()} />
                                    </div>
                                </Grid.Column>}


                        </Grid>
                    </Segment>

                    <article style={{ padding: "10px 10px 5px 10px" }}>

                        <Dividersur style={{ margin: 0, padding: 0, marginBottom: 10 }} horizontal >

                            <Header as='h5' >
                                <Breadcrumb>
                                    <Breadcrumb.Section ><Icon name="tag" style={{ paddig: 0, margin: 0, marginRight: 5 }} />{authContext.appView ? authContext.appView.appViewCategory : ""} </Breadcrumb.Section>
                                    <Breadcrumb.Divider>/</Breadcrumb.Divider>
                                    <Breadcrumb.Section ><Icon name={authContext.appView ? authContext.appView.appViewIcon : ""} style={{ paddig: 0, margin: 0, marginRight: 5 }} />{authContext.appView ? authContext.appView.appViewName : ""}</Breadcrumb.Section>
                                </Breadcrumb>
                            </Header>
                        </Dividersur>
                        <Dialog header={<span><Icon name="exclamation circle" size='large' /> {visibleDialog.header}</span>} visible={visibleDialog.openDialog} position={'bottom-right'} modal style={{ maxWidth: '50vw', minWidth: '25vw' }} onHide={() => setvisibleDialog({ openDialog: false, header: "", body: "" })}
                            draggable={false} resizable={false}>
                            <p className="mt-0">{visibleDialog.body}</p>
                        </Dialog>
                        <LayoutConcent appHeight={ref.current ? ref.current.clientHeight - 10 : 300} />
                    </article>
                 
                </div>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

export default Layout;
