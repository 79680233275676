import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Icon, Label, Modal, Segment } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast, returnWorkerTab } from '../../../myFunc';

import ModalUI from '../../../layout/ModalUI';
import AGGridActivity from '../../AGGrid/AGGridActivity';
import EditWorker from './EditWorker';
import AddWorker from './AddWorker';
import InfoWorker from './InfoWorker';


function Workers(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);
   const [onlyChoose, setonlyChoose] = useState(false)
   const [open, setOpen] = useState(false)
   const [openType, setOpenType] = useState(0)
   const [propsUiNameData, setpropsUiNameData] = useState(null);

   const [isloading1, setisloading1] = useState(false) // eslint-disable-line no-unused-vars

   const [form, setForm] = useState({
      user_id: 0,
      allInfo: null
   });

   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   function getAllUsers() {
      setisloading1(true)
    
      ConnectWS("/users", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
              setRowData(returnWorkerTab(response.data))
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         onSelectionChanged()
         setisloading1(false)
      })
   }



   useEffect(() => {
      if (props.onlyChoose !== null) {
         setonlyChoose(props.onlyChoose)
      }
      getAllUsers()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps


   const onSelectionChanged = () => {
      if (gridApi) {
         var selectedRows = gridApi.getSelectedRows();
         if (selectedRows.length === 1) {
            let user_id = selectedRows[0]["user_id"]
            setForm({ ...form, user_id: user_id, allInfo: selectedRows[0] })
         }
         else {
            setForm({ ...form, user_id: 0, allInfo: null })
         }
      }
   };

   const isactiveIcon = props => {
      let ret
      props.data.enabled ? ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="Aktywny" >
         AKTYWNY
      </Label> : ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='red' title="Aktywny" >
         NIEAKTYWNY
      </Label>
      return ret
   };

   const holidaysLeft = props => {
      let ret = <></>
      if (props.data.dayEmptyCurrent > 25) {
         ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green'  >
            {props.data.dayEmptyCurrent} DNI
         </Label>
      } else if (props.data.dayEmptyCurrent < 1) {
         ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='red'  >
            {props.data.dayEmptyCurrent} DNI
         </Label>
      } else if (props.data.dayEmptyCurrent < 5) {
         ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='orange'  >
            {props.data.dayEmptyCurrent} DNI
         </Label>
      } else if (props.data.dayEmptyCurrent <= 25) {
         ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='blue'  >
            {props.data.dayEmptyCurrent} DNI
         </Label>
      }

      return ret
   };


   const iconUser = props => {
      return <Icon.Group size='large' className='cursorPointer' onClick={() => shwoInfo(props.data)}>
         <Icon name='user' color='grey' />
         <Icon corner name='wrench' color='grey' />
      </Icon.Group>
      //return <Icon name="user" color='grey' size='large' className='cursorPointer' onClick={() => shwoInfo(props.data)} />
   };





   function editUser() {

      if (form.user_id > 0) {
         setpropsUiNameData("Edycja serwisanta")
         setOpen(true)
         setOpenType(1)
      } else {
         toast.warning("Proszę zaznaczyć użytkownika w tabeli", cfgToast);
      }
   }

   

   function shwoInfo(data) {
      setForm({ ...form, user_id: 0, allInfo: data })
      setpropsUiNameData("Informacje o serwisancie")
      setOpen(true)
      setOpenType(3)
   }




   function notificationIcon(props) {

      try {
         let ret
         props.data.notification ? ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='green' title="Aktywne" >
            AKTYWNE
         </Label> : ret = <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="Nieaktywne" >
            NIEAKTYWNE
         </Label>
         return ret
      } catch (error) {
         return <Label style={{ width: "100%", height: 25, fontSize: 10, textAlign: "center" }} color='grey' title="Nieaktywne" >
            BRAK
         </Label>
      }

   }


   return (
      <div style={{ minHeight: props.minHeight }}>
         <Modal
            onClose={() => setOpen(false)}
            open={open}
            size='large'
            centered={false}
         >
            <Modal.Header>
               <ModalUI propsUiName={propsUiNameData} />

            </Modal.Header>

            <Modal.Content className='bg'>
               {openType === 1 ? <EditWorker userInfo={form} /> : openType === 2 ? <AddWorker /> : openType === 3 ? <InfoWorker minHeight={props.minHeight} userInfo={form} /> : ""}
            </Modal.Content>
            <Modal.Actions>
               <Button size='tiny' onClick={() => setOpen(false)}>
                  ZAMKNIJ
               </Button>

            </Modal.Actions>
         </Modal>
         <Segment  >
            <Grid stackable style={{ minHeight: props.minHeight }}>
               <Grid.Row>
                  <Grid.Column >

                     {onlyChoose === true ? "" :
                        <Grid className='clearmp' >
                           <Grid.Row className='clearmp' stretched >
                              <Grid.Column style={{ paddingLeft: 0 }} width={8}>
                                 <Button fluid size='tiny' className='appbtnmtd' onClick={() => getAllUsers()}>Pobierz serwisantów</Button>
                              </Grid.Column>
                              <Grid.Column style={{ paddingRight: 0 }} width={8}>
                                 <Button fluid size='tiny' disabled={form.user_id > 0 ? false : true} className='appbtnmtd' color='blue' onClick={() => editUser()}>Edytuj serwisanta</Button>
                              </Grid.Column>
                              {/*
                              <Grid.Column style={{ paddingRight: 0 }} width={5}>
                                 <Button fluid size='tiny' className='appbtnmtd' color='blue' onClick={() => addUser()}>Dodaj serwisanta</Button>
                              </Grid.Column>
   */}
                           </Grid.Row >
                        </Grid>
                     }
                     <AGGridActivity gridApi={gridApi} gridColumnApi={gridColumnApi} />
                     <div
                        id="myGrid"
                        style={{
                           height: props.minHeight - 100,
                           // height:  '100%',
                           width: '100%',
                           marginTop: 5
                        }}
                        className="ag-theme-balham"
                     >
                        <AgGridReact
                           //rowHeight={rowHeightAGG}
                           rowSelection={'single'}
                           onSelectionChanged={onSelectionChanged}
                           suppressRowClickSelection={true}

                           animateRows={true}
                           pagination={true}
                           defaultColDef={{
                              editable: false,
                              sortable: true,
                              filter: false,
                              width: 150,
                              minWidth: 100,
                           //przyśpiesza odświeżanie
                              //wrapText: true,
                              //autoHeight: true,
                              resizable: true,
                              floatingFilter: false,
                              flex: 1,
                           }}
                           frameworkComponents={{
                              iconUser: iconUser,
                              isactiveIcon: isactiveIcon,
                              notificationIcon: notificationIcon,
                              holidaysLeft: holidaysLeft,
                           }}

                           getRowId={function (data) {
                              return data.data.user_id

                           }}
                           localeText={AG_GRID_LOCALE_PL}
                           rowData={rowData}
                           onGridReady={onGridReady}
                        >
                           <AgGridColumn cellRenderer='iconUser'
                              width={49}
                              minWidth={49}
                              flex={0}
                           />
                           <AgGridColumn headerCheckboxSelection={true}
                              checkboxSelection={true}
                              field="username" headerName='Login' filter='agTextColumnFilter' />
                           <AgGridColumn field="name" headerName='Imie' filter='agTextColumnFilter' />
                           <AgGridColumn field="surname" headerName='Nazwisko' filter='agTextColumnFilter' />
                           <AgGridColumn cellRenderer='isactiveIcon'
                              headerName='Aktywny'
                              width={110}
                              minWidth={110}
                           />
                           <AgGridColumn field="email" headerName='Email'
                           width={200}
                           minWidth={100}
                           flex={0} filter='agTextColumnFilter' />
                           <AgGridColumn field="phone" headerName='Telefon' width={100}
                              minWidth={100}
                              flex={0} filter='agTextColumnFilter' />

                           <AgGridColumn field="days_off" headerName='Dni wolnych' width={100}
                              minWidth={100}
                              flex={0} filter='agTextColumnFilter' />
                           <AgGridColumn field="working_time" headerName='Czas pracy' width={100}
                              minWidth={100}
                              flex={0} filter='agTextColumnFilter' />
                           <AgGridColumn field="info" headerName='Informacje' filter='agTextColumnFilter' />

                           <AgGridColumn cellRenderer='holidaysLeft'
                              headerName='Pozostało dni wolnych'
                              width={110}
                              minWidth={110}
                           />
                           <AgGridColumn cellRenderer='notificationIcon'
                              headerName='Powiadomienia'
                              width={110}
                              minWidth={110}
                           />


                        </AgGridReact>
                     </div>
                  </Grid.Column>

               </Grid.Row>


            </Grid>



         </Segment>
      </div>
   );
}

export default Workers;