import React, { useState, useEffect } from 'react';


import { Segment, Tab } from 'semantic-ui-react';

import CommisionSettings from './CommisionSettings';
import CommisionSettingsInputType from './CommisionSettingsInputType';

function CommisionSettingsTab(props) {
   const [activeIndex, setactiveIndex] = useState(0);

   useEffect(() => {
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps

   const handleTabChange = (e, { activeIndex }) => setactiveIndex(activeIndex)


   const panes = [
      {
         menuItem: { key: "Szablony plików", icon: 'cog', content: 'Szablony plików' },
         render: () => <Tab.Pane attached={false} style={{ padding: 0 }}>{<CommisionSettings />}</Tab.Pane>
      }, {
         menuItem: { key: "Szablony opisów", icon: 'cog', content: 'Szablony opisów' },
         render: () => <Tab.Pane attached={false} style={{ padding: 0 }}>{<CommisionSettingsInputType />}</Tab.Pane>
      },
   ]

   return (

      <Segment className="bgcard" >
         <Tab menu={{ secondary: true, pointing: true, className: "wrappedtab", color: "blue" }} activeIndex={activeIndex} panes={panes} onTabChange={handleTabChange} />
      </Segment>

   );
}

export default CommisionSettingsTab;