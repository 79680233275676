import axios from '../axios';
import { toast } from 'react-toastify';
import { cfgToast } from '../myFunc';

export function refreshToken(tokendata, callback) {
  if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
    callback({ status: 200 });
    return
  }
  let start = new Date().getTime();
  let method = '/auth/refresh_token'
  if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
    console.log("PYTAM - " + start + " | ", method)
  }
  if (tokendata) {
    let tokendata1 = "Bearer " + tokendata;
    axios.defaults.headers.common['Authorization'] = tokendata1;
  } else {
    axios.defaults.headers.common['Authorization'] = null;
  }

  axios({
    method: 'post', url: method, data: null,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(response => {
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        let end = new Date().getTime();
        let time = end - start;
        console.log("ODP - " + start + " Czas: " + msToTime(time) + " | ", method, response)
      }
      if (response.status === 200) {
        if (response.data.access_token) {
          callback(response.data.access_token, 1);
        }else{
          callback("Wylogowanie z aplikacji", 2);
        }
      }else{
        callback("Wylogowanie z aplikacji.", 2);
      }
    }).catch(err => {
      if (err.response) {
        if (err.response.status === 401) {
          callback(err.response.data.detail, 2);
        } else if (err.response.status === 403) {
          callback("Brak dostępu", 2);
        }  else if (err.response.status === 404) {
          callback("404 - BRAK METODY: " + method, 2);
        } else {
          if(err.response.data.detail){
            callback(JSON.stringify(err.response.data.detail), 2);
          }else{
            callback(JSON.stringify(err.response.data), 2);
          }
          
        }

      } else if (err.request) {
        callback(JSON.stringify(err.message), 2);
      } else {
        callback("Puk Puk - ale brak odpowiedzi z serwera", 2);
      }
    })

}


export function ConnectWS(method, methodtype, tokendata, bodyFormData, bodyJson, callback) {
  if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
    toast.info('Metoda lokalna: '+ method, cfgToast);
    callback({ status: 200,data:{access_token:"asdasdasd"} });
    return
  }

  let start = new Date().getTime();
  if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
    let bodyFormDatatmp = {}
    if (bodyFormData) {
      bodyFormDatatmp.length = 0
      for (let [key, value] of bodyFormData) {
        //console.log('bodyFormData',`${key}: ${value}`)
        bodyFormDatatmp = { ...bodyFormDatatmp, [key]: value }
      }
      console.log("PYTAM-FD - " + start + " | ", method, bodyFormDatatmp)
    } else {
      console.log("PYTAM-JSON - " + start + " | ", method, bodyJson)
    }



  }

  if (tokendata) {
    let tokendata1 = "Bearer " + tokendata;
    axios.defaults.headers.common['Authorization'] = tokendata1;
  } else {
    axios.defaults.headers.common['Authorization'] = null;
  }

  axios({
    method: methodtype, url: method, data: bodyFormData ? bodyFormData : bodyJson, params: bodyFormData ? bodyFormData : bodyJson,
    headers: {
      'Content-Type': bodyFormData ? 'multipart/form-data' : 'application/json'
    }
  })
    .then(response => {
      //setSystemErrors("response", 200, response, method)
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        let end = new Date().getTime();
        let time = end - start;
        console.log("ODP - " + start + " Czas: " + msToTime(time) + " | ", method, response)
      }

      callback(response);
    }).catch(err => {
      if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
        let end = new Date().getTime();
        let time = end - start;
        console.log("ODP[ERR] - " + start + " Czas: " + msToTime(time) + " | ", method)
      }
      console.log("APP ERROR REQ",err)
      if (err.response) {
        if (err.response.status === 401) {
          callback(err.response.data.detail, 1);
        } else if (err.response.status === 403) {
          callback("Brak dostępu", 2);
        }  else if (err.response.status === 404) {
          callback("404 - BRAK METODY: " + method, 2);
        } else {
          if(err.response.data.detail){
            callback(JSON.stringify(err.response.data.detail), 2);
          }else{
            callback(JSON.stringify(err.response.data), 2);
          }
          
        }

      } else if (err.request) {
        callback(JSON.stringify(err.message), 2);
      } else {
        callback("Puk Puk - ale brak odpowiedzi z serwera", 2);
      }

    })
}

function msToTime(duration) {
  var milliseconds = parseInt((duration % 1000))
    , seconds = parseInt((duration / 1000) % 60)
    , minutes = parseInt((duration / (1000 * 60)) % 60)
    , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}



