import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { AG_GRID_LOCALE_PL } from './LanguageAGG';
import { Grid, Icon } from 'semantic-ui-react';
import { InputText } from 'primereact/inputtext';


const AGGrid = (props) => {
  const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
  const [rowData, setRowData] = useState(null);
  const [rowDataHN, setRowDataHN] = useState(null);
  const gridRef = useRef();
  const [heightV, setheightV] = useState(500);
  const [columnDefs, setColumnDefs] = useState([])

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if (props.AGGridData && props.AGGridData.length > 0) {
    }else{
      params.api.showNoRowsOverlay();
    }
  };


  useEffect(() => {

   // console.log('CHANGE BODZIO - GRID', props)
    if (props.AGGridData && props.AGGridData.length > 0) {
      createHN(props.AGGridData)
      setRowData(props.AGGridData)
      if (props.GridHeight) {
        setheightV(props.GridHeight)
      } else {
        setheightV(500)
      }
      setColumnDefs(props.AGGridDataColumnDef)
      if(gridApi){
        gridApi.hideOverlay();
      }
     
    }else{
      if(gridApi){
        gridApi.showNoRowsOverlay();
      }
      setheightV(300)
    }

    //authContext.changeUserName(mview);
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [props.AGGridData]);// eslint-disable-line react-hooks/exhaustive-deps



  function createHN(data) {
    let tmparr = []
    Object.entries(data[0]).forEach(([key, value]) => {
      tmparr.push(key)
    })
    setRowDataHN(tmparr)
  }
  const getParams = () => {
    return {
      skipColumnGroupHeaders: false,
      skipColumnHeaders: false,
    };
  };

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv(getParams());
  }, []);

  function quickSearch(val) {
    if (val.length > 1) {
      gridApi.setQuickFilter(val);
    } else {
      gridApi.setQuickFilter(null);
    }

  }

  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);


  return (<>
    <Grid  >
      <Grid.Row >
        <Grid.Column width={9} >
          <span className="p-float-label p-input-icon-left">
            <i className="pi pi-search" />
            <InputText style={{width:"100%"}} id="lefticon" onChange={(e) => quickSearch(e.target.value)} />
            <label htmlFor="lefticon">Szukaj</label>
          </span>
        </Grid.Column>
        <Grid.Column width={7} textAlign="right">
          
        <Icon bordered name="chevron left" className='cursorPointer iconbutton' onClick={sizeToFit} />
        <Icon bordered name="chevron right" className='cursorPointer iconbutton' onClick={() => autoSizeAll(false)} />
          <Icon bordered name="download" title="POBIERZ PLIK CSV" className='cursorPointer iconbutton' onClick={onBtnExport} />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <div
      id="myGrid"
      style={{
       // height: heightV === 500 ? '500px' : 'calc(100% - ' + heightV + 'px)',
       height: heightV,
        width: '100%',
        marginTop: 5,
        marginBottom: 10,
        paddingBottom: 10
      }}
      className="ag-theme-balham"
    >
      <AgGridReact
        ref={gridRef}
        //rowHeight={rowHeightAGG}
        animateRows={true}
        
        defaultColDef={{
          editable: false,
          sortable: true,
          minWidth: 100,
          filter: false,
          resizable: true,
          floatingFilter: false,
          flex: 1,
        }}

        localeText={AG_GRID_LOCALE_PL}
        suppressExcelExport={true}
        columnDefs={columnDefs}
        rowData={rowData}
        onGridReady={onGridReady}
      >
        {rowDataHN ? rowDataHN.map((k, i) => {
          return <AgGridColumn key={i} field={k} />
        }) : ""}
    
      </AgGridReact>
    </div>
  </>
  );
};

export default AGGrid;