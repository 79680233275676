import React, { useState, useContext } from 'react';
import { Card } from 'primereact/card';
import { toast } from 'react-toastify';
import { cfgToast } from '../../../myFunc';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import AuthContext from '../../../context/authContext';
import { Button as ButtonPrime } from 'primereact/button';

function AddClient(props) {
   const authContext = useContext(AuthContext);
   const defaultValues = {
      name: "",
      address: "",
      description: "",
      external_id: "",
      nip: "",
      email: "",
      phone: "",
      technical_contact: ""

   }
   const [isloading, setisloading] = useState(false);
   const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };




   const submit = (data) => {
      setisloading(true)

      ConnectWS("/commisions/client", "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
            reset()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })


   }


   return (
      <>
         <Card className="bgcard">
            <form onSubmit={handleSubmit(submit)} className="p-fluid">

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="name" control={control} rules={{ required: 'Nazwa klienta jest wymagana' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nazwa klienta*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="address" control={control} rules={{ required: 'Adres klienta jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="address" className={classNames({ 'p-error': errors.address })}>Adres klienta*</label>
                  </span>
                  {getFormErrorMessage('address')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="nip" control={control}  render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="nip" className={classNames({ 'p-error': errors.nip })}>NIP</label>
                  </span>
                  {getFormErrorMessage('nip')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="external_id" control={control}  render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="external_id" className={classNames({ 'p-error': errors.external_id })}>Identyfikator zewnętrzny</label>
                  </span>
                  {getFormErrorMessage('external_id')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-user-plus" />
                     <Controller name="description" control={control} rules={{ required: 'Opis jest wymagany' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>Opis*</label>
                  </span>
                  {getFormErrorMessage('description')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="phone" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>Telefon</label>
                  </span>
                  {getFormErrorMessage('phone')}
               </div>
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-phone" />
                     <Controller name="technical_contact" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="technical_contact" className={classNames({ 'p-error': errors.technical_contact })}>Kontakt techniczny</label>
                  </span>
                  {getFormErrorMessage('technical_contact')}
               </div>

               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-envelope" />
                     <Controller name="email" control={control}
                        rules={{ required: 'Email jest wymagany.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Błędny email, przykład: example@email.com' } }}
                        render={({ field, fieldState }) => (
                           <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                     <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>Email*</label>
                  </span>
                  {getFormErrorMessage('email')}
               </div>
            


               <ButtonPrime loading={isloading} type="submit" label="Dodaj klienta" className="mt-2 p-button" />
            </form>
         </Card>
      </>
   );
}

export default AddClient;