import React, { useState, useEffect, useContext } from 'react';
import { Icon, Segment, Table, Button } from 'semantic-ui-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast } from '../../../myFunc';
import TableRows from './TableRows';
import { Button as Buttonprime } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Step } from 'semantic-ui-react'
import AGGrid from '../../AGGrid/AGGrid';
import { ScrollPanel } from 'primereact/scrollpanel';
import { SelectButton } from 'primereact/selectbutton';
import SwitchDiagram, { ErrorFallback, prepareJsonTabToDiag } from '../SwitchDiagram/SwitchDiagram';
import AGGridModalSwitch from '../../AGGrid/Modal/AGGridModalSwitch';
import { ErrorBoundary } from 'react-error-boundary';

function SwitchConnectionsWizard(props) {
   const authContext = useContext(AuthContext);
   const [isloading, setLoading] = useState(false);// eslint-disable-line no-unused-vars
   const [activeIndex, setActiveIndex] = useState(1);
   const [switchButton, setswitchButton] = useState('Pojedyńcze');
   const [connMaster, setconnMaster] = useState(new Date().getTime());// eslint-disable-line no-unused-vars
   const options = ['Pojedyńcze', 'Podwójne'];
   const [schemaJson, setschemaJson] = useState(null);
   const [onlySearchId, setonlySearchId] = useState(true);// eslint-disable-line no-unused-vars


   function checkSwitchButtonD() {
      if (switchButton === "Podwójne") {
         return true
      } else {
         return false
      }
   }

   const [rowsDataAG, setRowsDataAG] = useState([]);
   const [rowsData, setRowsData] = useState([]);
   const [rowsDataStart, setRowsDataStart] = useState({
      connMaster: connMaster,
      connSet: 1,
      pointAID: null,
      pointAPort: "-",
      pointBID: null,
      pointBPort: null,
      connectionsType: 'external'
   });
   const [rowsDataEnd, setRowsDataEnd] = useState({
      connMaster: connMaster,
      connSet: 1,
      pointAID: null,
      pointAPort: null,
      pointBID: null,
      pointBPort: "-",
      connectionsType: 'external'
   });

   const [rowsDataP, setRowsDataP] = useState([]);
   const [rowsDataStartP, setRowsDataStartP] = useState({
      connMaster: connMaster,
      connSet: 2,
      pointAID: null,
      pointAPort: "--",
      pointBID: null,
      pointBPort: null,
      connectionsType: 'external'
   });
   const [rowsDataEndP, setRowsDataEndP] = useState({
      connMaster: connMaster,
      connSet: 2,
      pointAID: null,
      pointAPort: null,
      pointBID: null,
      pointBPort: "--",
      connectionsType: 'external'
   });


   const addTableRows = () => {

      const rowsInput = {
         connMaster: connMaster,
         connSet: 1,
         pointAID: null,
         pointAPort: null,
         pointBID: null,
         pointBPort: null,
         connectionsType: null
      }
      setRowsData([...rowsData, rowsInput])
      if (checkSwitchButtonD()) {
         addTableRowsP()
      }

   }
   const deleteTableRows = (index) => {
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
      if (checkSwitchButtonD()) {
         deleteTableRowsP()
      }
   }

   const handleChange = (index, evnt, name) => {

      const { value } = evnt.target ? evnt.target : evnt;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
   }



   const addTableRowsP = () => {

      const rowsInput = {
         connMaster: connMaster,
         connSet: 2,
         pointAID: null,
         pointAPort: null,
         pointBID: null,
         pointBPort: null,
         connectionsType: null
      }
      setRowsDataP([...rowsDataP, rowsInput])

   }
   const deleteTableRowsP = (index) => {
      const rows = [...rowsDataP];
      rows.splice(index, 1);
      setRowsDataP(rows);
   }

   const handleChangeP = (index, evnt, name) => {

      const { value } = evnt.target ? evnt.target : evnt;
      const rowsInput = [...rowsDataP];
      rowsInput[index][name] = value;
      setRowsDataP(rowsInput);



   }



   useEffect(() => {
      //getData()

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps


   function ClearAll() {

      let tmpdata = new Date().getTime()
      setRowsDataAG([])
      setconnMaster(tmpdata)
      setRowsDataP([])
      setRowsData([])
      setActiveIndex(1)

      setRowsDataStart({ ...rowsDataStart, connMaster: tmpdata })

      setRowsDataEnd({ ...rowsDataEnd, connMaster: tmpdata })

      setRowsDataStartP({ ...rowsDataStartP, connMaster: tmpdata })
      setRowsDataEndP({ ...rowsDataEndP, connMaster: tmpdata })



   }



   function saveConnections() {
      setLoading(true)
      ConnectWS("/infiber/connection", "post", authContext.tokenApp, null, rowsDataAG, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            ClearAll()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setLoading(false)
      })
   }


   function changeStep(co) {
      if (co < 0 && activeIndex > 1) {
         setActiveIndex(activeIndex + co)
         return
      }

      if (co > 0) {
         if (activeIndex === 1) {
            if (rowsDataStart.pointAID !== null) {

            } else {
               toast.warn("Proszę wpisać punkt startowy", cfgToast)
               return
            }
            if (rowsDataStart.pointBID !== null && rowsDataStart.pointBID.length > 0) {

            } else {
               toast.warn("Proszę wpisać Id punktu B", cfgToast)
               return
            }
            if (rowsDataStart.pointBPort !== null && rowsDataStart.pointBPort > 0) {

            } else {
               toast.warn("Proszę wpisać Port punktu B", cfgToast)
               return
            }


            if (checkSwitchButtonD()) {
               if (rowsDataStartP.pointAID !== null) {

               } else {
                  toast.warn("Proszę wpisać punkt startowy", cfgToast)
                  return
               }
               if (rowsDataStartP.pointBID !== null && rowsDataStartP.pointBID.length > 0) {

               } else {
                  toast.warn("Proszę wpisać Id punktu B", cfgToast)
                  return
               }
               if (rowsDataStartP.pointBPort !== null && rowsDataStartP.pointBPort > 0) {

               } else {
                  toast.warn("Proszę wpisać Port punktu B", cfgToast)
                  return
               }
            }


            setActiveIndex(activeIndex + co)
         }

         if (activeIndex === 2) {
            if (rowsData.length < 1) {
               toast.warn("Proszę wpisać połaczenie", cfgToast)
               return
            }
            let err = 0
            rowsData.forEach((row, i) => {
               Object.keys(row).forEach(function (key) {
                  //console.log(key,row[key]<1)
                  if (row[key] === null || row[key] < 1) {
                     err++
                     return
                  }
               });
               if (err > 0) {
                  return
               }
            })
            if (err > 0) {
               toast.warn("Wartości muszą być większe od 0", cfgToast)
               return
            }

            if (checkSwitchButtonD()) {
               if (rowsDataP.length < 1) {
                  toast.warn("Proszę wpisać połaczenie", cfgToast)
                  return
               }
               let err1 = 0
               rowsDataP.forEach((row, i) => {
                  Object.keys(row).forEach(function (key) {
                     //console.log(key,row[key]<1)
                     if (row[key] === null || row[key] < 1) {
                        err1++
                        return
                     }
                  });
                  if (err1 > 0) {
                     return
                  }
               })
               if (err1 > 0) {
                  toast.warn("Wartości muszą być większe od 0", cfgToast)
                  return
               }
            }


            setActiveIndex(activeIndex + co)
         }

         if (activeIndex === 3) {
            if (rowsDataEnd.pointBID !== null) {

            } else {
               toast.warn("Proszę wpisać punkt końcowy", cfgToast)
               return
            }
            if (rowsDataEnd.pointAID !== null && rowsDataEnd.pointAID.length > 0) {

            } else {
               toast.warn("Proszę wpisać Id punktu A", cfgToast)
               return
            }
            if (rowsDataEnd.pointAPort !== null && rowsDataEnd.pointAPort > 0) {

            } else {
               toast.warn("Proszę wpisać Port punktu A", cfgToast)
               return
            }

            if (checkSwitchButtonD()) {
               if (rowsDataEndP.pointBID !== null) {

               } else {
                  toast.warn("Proszę wpisać punkt końcowy", cfgToast)
                  return
               }
               if (rowsDataEndP.pointAID !== null && rowsDataEndP.pointAID.length > 0) {

               } else {
                  toast.warn("Proszę wpisać Id punktu A", cfgToast)
                  return
               }
               if (rowsDataEndP.pointAPort !== null && rowsDataEndP.pointAPort > 0) {

               } else {
                  toast.warn("Proszę wpisać Port punktu A", cfgToast)
                  return
               }
            }


            let tmp = []
            let tmp1 = []
            tmp.push({ ...rowsDataStart })
            tmp.push(...rowsData)
            tmp.push({ ...rowsDataEnd })
            let finalObj
            if (checkSwitchButtonD()) {
               tmp1.push({ ...rowsDataStartP })
               tmp1.push(...rowsDataP)
               tmp1.push({ ...rowsDataEndP })
               finalObj = tmp.concat(tmp1);
            } else {
               finalObj = tmp;
            }
            //prepareJsonTabToDiag(tmp,tmp1,checkSwitchButtonD())

            setschemaJson(prepareJsonTabToDiag(tmp, tmp1, checkSwitchButtonD()))

            setRowsDataAG(finalObj)


            setActiveIndex(activeIndex + co)
         }

      }
   }

   const [oopenModalGetId, setoopenModalGetId] = useState(false)

   const handleChangeObject = (jsonobject, tmpname) => {

      let pointId = jsonobject.id

      if (tmpname.name === "STARTB") {
         setRowsDataStart({ ...rowsDataStart, pointBID: pointId })
         if (checkSwitchButtonD()) {
            setRowsDataStartP({ ...rowsDataStartP, pointBID: pointId })
         }
      }
      if (tmpname.name === "ENDA") {
         setRowsDataEnd({ ...rowsDataEnd, pointAID: pointId })
         if (checkSwitchButtonD()) {
            setRowsDataEndP({ ...rowsDataEndP, pointAID: pointId })
         }
      }
      if (tmpname.name === "pointAID" || tmpname.name === "pointBID") {
         handleChange(tmpname.index, { value: pointId }, tmpname.name)
         if (checkSwitchButtonD()) {
            handleChangeP(tmpname.index, { value: pointId }, tmpname.name)
         }
      }

   }

   function openModalGetId(tmpname) {
      setoopenModalGetId(tmpname)
   }


   return (
      <>

         <AGGridModalSwitch openModal={oopenModalGetId} handleChangeObject={handleChangeObject} />
         <Segment className='bgcard'>

            <Step.Group ordered widths={4}>
               <Step active={activeIndex === 1 ? true : false} completed={activeIndex > 1 ? true : false}>
                  <Step.Content>
                     <Step.Title>Punkt startowy</Step.Title>
                  </Step.Content>
               </Step>

               <Step active={activeIndex === 2 ? true : false} completed={activeIndex > 2 ? true : false}>
                  <Step.Content>
                     <Step.Title>Połączenia</Step.Title>
                  </Step.Content>
               </Step>

               <Step active={activeIndex === 3 ? true : false} completed={activeIndex > 3 ? true : false}>
                  <Step.Content>
                     <Step.Title>Punkt końcowy</Step.Title>
                  </Step.Content>
               </Step>
               <Step active={activeIndex === 4 ? true : false} completed={activeIndex > 4 ? true : false}>
                  <Step.Content>
                     <Step.Title>Zapisanie</Step.Title>
                  </Step.Content>
               </Step>
            </Step.Group>

            {activeIndex === 1 ?
               <ScrollPanel style={{ width: '100%', height: '100%' }}>
                  <SelectButton style={{ marginTop: 10 }} value={switchButton} options={options} onChange={(e) => setswitchButton(e.value)} />
                  <Table celled padded unstackable color="blue">
                     <Table.Header>
                        <Table.Row>
                           <Table.HeaderCell>Punkt startowy</Table.HeaderCell>
                           <Table.HeaderCell colSpan='2'>Id punktu B</Table.HeaderCell>
                           <Table.HeaderCell>Port punktu B</Table.HeaderCell>
                           <Table.HeaderCell width={3}>Typ połączenia</Table.HeaderCell>

                        </Table.Row>
                     </Table.Header>

                     <Table.Body>
                        <Table.Row>
                           <Table.Cell><span className="p-fluid"><InputText value={rowsDataStart.pointAID} onChange={(evnt) => {
                              setRowsDataStart({ ...rowsDataStart, pointAID: evnt.target.value })
                              setRowsDataStartP({ ...rowsDataStartP, pointAID: evnt.target.value })
                           }} name="pointAID" placeholder='Punktu startowego' /></span></Table.Cell>
                           <Table.Cell><span className="p-fluid"><InputText value={rowsDataStart.pointBID} onChange={(evnt) => {
                              setRowsDataStart({ ...rowsDataStart, pointBID: evnt.value })
                              setRowsDataStartP({ ...rowsDataStartP, pointBID: evnt.value })
                           }} name="pointBID" disabled={onlySearchId} /></span></Table.Cell>
                           <Table.Cell width={1}><span className="p-fluid"><Buttonprime label="Szukaj" icon="pi pi-search" onClick={() => openModalGetId({ open: true, name: "STARTB" })} className="p-button-secondary" /></span></Table.Cell>
                           <Table.Cell><span className="p-fluid"><InputNumber htmlFor="integeronly" value={rowsDataStart.pointBPort} onChange={(evnt) => (setRowsDataStart({ ...rowsDataStart, pointBPort: evnt.value }))} name="pointBPort" /></span></Table.Cell>
                           <Table.Cell><span className="p-fluid"><InputText value={rowsDataStart.connectionsType} name="connectionsType" disabled /></span></Table.Cell>


                        </Table.Row>
                     </Table.Body>
                  </Table>
                  {checkSwitchButtonD() ?
                     <Table celled padded unstackable color="orange">
                        <Table.Header>
                           <Table.Row>
                              <Table.HeaderCell>Punkt startowy</Table.HeaderCell>
                              <Table.HeaderCell colSpan='2'>Id punktu B</Table.HeaderCell>
                              <Table.HeaderCell>Port punktu B</Table.HeaderCell>
                              <Table.HeaderCell width={3}>Typ połączenia</Table.HeaderCell>

                           </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           <Table.Row>
                              <Table.Cell><span className="p-fluid"><InputText disabled={true} value={rowsDataStartP.pointAID} onChange={(evnt) => (setRowsDataStartP({ ...rowsDataStartP, pointAID: evnt.target.value }))} name="pointAID" placeholder='Punktu startowego' /></span></Table.Cell>
                              <Table.Cell><span className="p-fluid"><InputText disabled={true} value={rowsDataStartP.pointBID} onChange={(evnt) => (setRowsDataStartP({ ...rowsDataStartP, pointBID: evnt.value }))} name="pointBID" /></span></Table.Cell>
                              <Table.Cell width={1}><span className="p-fluid"><Buttonprime label="Szukaj" icon="pi pi-search" onClick={() => openModalGetId({ open: true, name: "STARTB" })} className="p-button-secondary" /></span></Table.Cell>
                              <Table.Cell><span className="p-fluid"><InputNumber htmlFor="integeronly" value={rowsDataStartP.pointBPort} onChange={(evnt) => (setRowsDataStartP({ ...rowsDataStartP, pointBPort: evnt.value }))} name="pointBPort" /></span></Table.Cell>

                              <Table.Cell><span className="p-fluid"><InputText value={rowsDataStartP.connectionsType} name="connectionsType" disabled /></span></Table.Cell>


                           </Table.Row>
                        </Table.Body>
                     </Table>
                     : ""}
               </ScrollPanel>
               : activeIndex === 2 ?
                  <ScrollPanel style={{ width: '100%', height: '100%' }}>
                     <Table celled padded unstackable color='blue'>
                        <Table.Header>
                           <Table.Row>
                              <Table.HeaderCell colSpan='2'>Id punktu A</Table.HeaderCell>
                              <Table.HeaderCell>Port punktu A</Table.HeaderCell>
                              <Table.HeaderCell colSpan='2'>Id punktu B</Table.HeaderCell>
                              <Table.HeaderCell>Port punktu B</Table.HeaderCell>
                              <Table.HeaderCell width={2}>Typ połączenia</Table.HeaderCell>
                              <Table.HeaderCell width={1}><Icon name="add square" className='cursorPointer' onClick={addTableRows} size="large" /></Table.HeaderCell>
                           </Table.Row>
                        </Table.Header>

                        <Table.Body>
                           <TableRows rowsData={rowsData} offDel={0} deleteTableRows={deleteTableRows} onlySearchId={onlySearchId} handleChange={handleChange} handleChangeP={handleChangeP} openModalGetId={openModalGetId} />

                        </Table.Body>
                     </Table>
                     {checkSwitchButtonD() ?
                        <Table celled padded unstackable color='orange'>
                           <Table.Header>
                              <Table.Row>
                                 <Table.HeaderCell colSpan='2'>Id punktu A</Table.HeaderCell>
                                 <Table.HeaderCell>Port punktu A</Table.HeaderCell>
                                 <Table.HeaderCell colSpan='2'>Id punktu B</Table.HeaderCell>
                                 <Table.HeaderCell>Port punktu B</Table.HeaderCell>
                                 <Table.HeaderCell width={2}>Typ połączenia</Table.HeaderCell>
                                 <Table.HeaderCell width={1}><Icon name="add square" size="large" /></Table.HeaderCell>
                              </Table.Row>
                           </Table.Header>

                           <Table.Body>
                              <TableRows rowsData={rowsDataP} offDel={1} deleteTableRows={deleteTableRowsP} onlySearchId={onlySearchId} handleChange={handleChangeP} openModalGetId={openModalGetId} />

                           </Table.Body>
                        </Table>
                        : ""}
                  </ScrollPanel>
                  : activeIndex === 3 ?

                     <ScrollPanel style={{ width: '100%', height: '100%' }}>
                        <Table celled padded unstackable color='blue'>
                           <Table.Header>
                              <Table.Row>
                                 <Table.HeaderCell colSpan='2'>Id punktu A</Table.HeaderCell>
                                 <Table.HeaderCell>Port punktu A</Table.HeaderCell>
                                 <Table.HeaderCell>Punkt końcowy</Table.HeaderCell>
                                 <Table.HeaderCell width={2}>Typ połączenia</Table.HeaderCell>

                              </Table.Row>
                           </Table.Header>

                           <Table.Body>
                              <Table.Row>
                                 <Table.Cell><span className="p-fluid"><InputText value={rowsDataEnd.pointAID} onChange={(evnt) => {
                                    setRowsDataEnd({ ...rowsDataEnd, pointAID: evnt.value })
                                    setRowsDataEndP({ ...rowsDataEndP, pointAID: evnt.value })
                                 }} name="pointAID" disabled={onlySearchId} /></span></Table.Cell>
                                 <Table.Cell width={1}><span className="p-fluid"><Buttonprime label="Szukaj" icon="pi pi-search" onClick={() => openModalGetId({ open: true, name: "ENDA" })} className="p-button-secondary" /></span></Table.Cell>

                                 <Table.Cell><span className="p-fluid"><InputNumber htmlFor="integeronly" value={rowsDataEnd.pointAPort} onChange={(evnt) => (setRowsDataEnd({ ...rowsDataEnd, pointAPort: evnt.value }))} name="pointAPort" /></span></Table.Cell>
                                 <Table.Cell><span className="p-fluid"><InputText value={rowsDataEnd.pointBID} onChange={(evnt) => {
                                    setRowsDataEnd({ ...rowsDataEnd, pointBID: evnt.target.value })
                                    setRowsDataEndP({ ...rowsDataEndP, pointBID: evnt.target.value })
                                 }} name="pointBID" placeholder='Punktu końcowy' /></span></Table.Cell>
                                 <Table.Cell><span className="p-fluid"><InputText value={rowsDataEnd.connectionsType} name="connectionsType" disabled /></span></Table.Cell>


                              </Table.Row>
                           </Table.Body>
                        </Table>
                        {checkSwitchButtonD() ?
                           <Table celled padded unstackable color='orange'>
                              <Table.Header>
                                 <Table.Row>
                                    <Table.HeaderCell colSpan='2'>Id punktu A</Table.HeaderCell>
                                    <Table.HeaderCell>Port punktu A</Table.HeaderCell>
                                    <Table.HeaderCell>Punkt końcowy</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Typ połączenia</Table.HeaderCell>

                                 </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                 <Table.Row>
                                    <Table.Cell><span className="p-fluid"><InputNumber htmlFor="integeronly" disabled={true} value={rowsDataEndP.pointAID} onChange={(evnt) => (setRowsDataEndP({ ...rowsDataEndP, pointAID: evnt.value }))} name="pointAID" /></span></Table.Cell>
                                    <Table.Cell width={1}><span className="p-fluid"><Buttonprime label="Szukaj" icon="pi pi-search" onClick={() => openModalGetId({ open: true, name: "ENDA" })} className="p-button-secondary" /></span></Table.Cell>

                                    <Table.Cell><span className="p-fluid"><InputNumber htmlFor="integeronly" value={rowsDataEndP.pointAPort} onChange={(evnt) => (setRowsDataEndP({ ...rowsDataEndP, pointAPort: evnt.value }))} name="pointAPort" /></span></Table.Cell>
                                    <Table.Cell><span className="p-fluid"><InputText value={rowsDataEndP.pointBID} disabled={true} onChange={(evnt) => (setRowsDataEndP({ ...rowsDataEndP, pointBID: evnt.target.value }))} name="pointBID" placeholder='Punktu końcowy' /></span></Table.Cell>
                                    <Table.Cell><span className="p-fluid"><InputText value={rowsDataEndP.connectionsType} name="connectionsType" disabled /></span></Table.Cell>


                                 </Table.Row>
                              </Table.Body>
                           </Table>
                           : ""}
                     </ScrollPanel>

                     : activeIndex === 4 ?
                        <div style={{ marginTop: 5 }}>
                           <AGGrid AGGridData={rowsDataAG} GridHeight={300} AGGridDataColumnDef={[
                              { field: 'pointAID', headerName: "Punktu A" },
                              { field: 'pointAPort', headerName: "Port punktu A" },
                              { field: 'pointBID', headerName: "Punktu B" },
                              { field: 'pointBPort', headerName: "Port punktu B" },
                              { field: 'connectionsType', headerName: "Typ połączenia" }]} />
                           <ErrorBoundary FallbackComponent={ErrorFallback}
                              onReset={() => {
                                 // reset the state of your app so the error doesn't happen again
                              }}>
                              <SwitchDiagram schemaJson={schemaJson} showLegends={false} minHeight={370} />
                           </ErrorBoundary>
                           <span className="p-fluid" >


                              <Buttonprime style={{ marginBottom: 10 }} label="Dodaj połączenie" onClick={() => saveConnections()} loading={isloading} />
                           </span>
                        </div>
                        : ""


            }



            <Button.Group attached='top'  >
               <Button disabled={activeIndex < 2 ? true : false} onClick={() => changeStep(-1)} style={{ marginRight: 2, marginTop: 10 }} icon labelPosition='left'> <Icon name='backward' />WSTECZ</Button>
               <Button disabled={activeIndex > 3 ? true : false} onClick={() => changeStep(+1)} style={{ marginLeft: 2, marginTop: 10 }} icon labelPosition='right'>  <Icon name='forward' />DALEJ</Button>
            </Button.Group>


         </Segment>
      </>
   );
}

export default SwitchConnectionsWizard;