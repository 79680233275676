import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import { cfgToast } from '../../../myFunc';
import AuthContext from '../../../context/authContext';
import '../../../rackserver.css'
import { useForm, Controller } from 'react-hook-form';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button as Buttonprime } from 'primereact/button';

import SwitchServerRackMod from './SwitchServerRackMod';


function SwitchServerRack(props) {
   const authContext = useContext(AuthContext);
   const [rackCountU, setrackCountU] = useState(null)
   const [editServerRack, seteditServerRack] = useState(null)
   const [addServerRack, setaddServerRack] = useState(false)
   const [msite_id, setmsite_id] = useState(0)

 

   function createRackArr(rackDevicetmp) {
      let tmp = []
      let countU = 0
      let slots_numbers_all = rackDevicetmp.slots_numbers
      for (var i = 1; i <= rackDevicetmp.slots_numbers; i++) {
         let ok = 0
         if (rackDevicetmp.slots && rackDevicetmp.slots.length > 0) {
            rackDevicetmp.slots.forEach((row, j,) => { // eslint-disable-line no-loop-func
               if (i === row.slot_nr) {
                  tmp.push({ name: row.label, u_nr: row.u_nr, curr_u: i })
                  ok = 1
                  countU = row.u_nr
               }
            })
         }
         if (ok === 0 && countU === 0) {
            tmp.push({ name: "WOLNE", u_nr: 0, curr_u: i })
            countU = 0
         } else {
            countU--
         }
      }

      let ret = { cabinet_name: rackDevicetmp.cabinet_name, cabinet_id: rackDevicetmp.cabinet_id, cabinet_slots: [...tmp.reverse()],slots_numbers_all:slots_numbers_all }
    
      return ret
   }


   //ADD app_cabinets

   const defaultValues = {
      cabinet_name: '',
      slots_numbers: '',
      site_id: 0,
   }
   const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const submitADDcabinet = (data) => {
      ConnectWS("/infiber/cabinet", "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success("Szafa została dodana", cfgToast);

            setaddServerRack(false)
            // reset()
            getCabinets(msite_id)
         } else if (Number(response.status) === 204) {

         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }


  




   function getCabinets(msite_id) {
      ConnectWS("/infiber/cabinet", "get", authContext.tokenApp, null, { site_id: msite_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               let tmparr = []

               response.data.forEach((row, i) => {
                  if (row.site_id === msite_id) {
                     tmparr.push(createRackArr(row))
                  }

               })
               setrackCountU(tmparr)
            }else{
               setrackCountU(null)
            }
            


            //setrackCountU(createRackArr({ slots_numbers: 40, slots: [{ slot_nr: 1, u_nr: 3, device: "123" }, { slot_nr: 5, u_nr: 1, device: "567" }, { slot_nr: 20, u_nr: 2, device: "332" }] }))
         } else if (Number(response.status) === 204) {

         } else {

            toast.error(response, cfgToast);
            console.log(response);
         }

      })
   }

   function clickEditCabinets(json) {
      seteditServerRack(json)
      //getCabinet_slots(json.cabinet_id)
   }


   

   
   useEffect(() => {
      if (props.dataSite) {
      getCabinets(JSON.parse(props.dataSite).site_id)
      }
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [editServerRack]);// eslint-disable-line react-hooks/exhaustive-deps

 

   useEffect(() => {
      if (props.dataSite) {
         setValue("site_id", JSON.parse(props.dataSite).site_id)
         setmsite_id(JSON.parse(props.dataSite).site_id)
         getCabinets(JSON.parse(props.dataSite).site_id)
      }


      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps

   return (
      <>
         <Header as='h3' textAlign='center'>
            Obiekt: {props.dataSite ? JSON.parse(props.dataSite).site_name : ""}
            <Header.Subheader>
               Pomieszczenie: {props.dataSite ? JSON.parse(props.dataSite).room : ""}
            </Header.Subheader>
         </Header>

         <Divider />
         {editServerRack != null ? <>
         <SwitchServerRackMod seteditServerRack={seteditServerRack} editServerRack={editServerRack} msite_id={msite_id} getCabinets={getCabinets} />
            
         </>
            : addServerRack ? <>
               <Segment style={{ padding: 25 }} className='bgcard'>

                  <form onSubmit={handleSubmit(submitADDcabinet)} className="p-fluid">

                     <Grid stackable >
                        <Grid.Row >
                           <Grid.Column width={6} className="p-fluid">

                              <span className="p-float-label">
                                 <Controller name="cabinet_name" control={control} rules={{ required: 'Nazwa jest wymagana', pattern: { value: /^.{3,253}$/i, message: 'Min 3 znaki' } }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                 )} />
                                 <label htmlFor="cabinet_name" className={classNames({ 'p-error': errors.cabinet_name })}>Nazwa*</label>
                              </span>
                              {getFormErrorMessage('cabinet_name')}

                           </Grid.Column>

                           <Grid.Column width={6} className="p-fluid">

                              <span className="p-float-label">
                                 <Controller name="slots_numbers" control={control} rules={{ required: 'Ilość slotów jest wymagana', pattern: { value: /^[0-9]{1,2}$/i, message: 'Błędna ilość slotów' } }}

                                    render={({ field, fieldState }) => (

                                       <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                                    )}
                                 />
                                 <label htmlFor="slots_numbers" className={classNames({ 'p-error': errors.slots_numbers })}>Ilość slotów*</label>
                              </span>
                              {getFormErrorMessage('slots_numbers')}


                           </Grid.Column>

                           <Grid.Column width={4} className="p-fluid">
                              <Buttonprime type="submit" label="DODAJ SZAFĘ" />

                           </Grid.Column>
                        </Grid.Row>
                     </Grid>

                  </form>
               </Segment>
               <Button fluid onClick={() => setaddServerRack(false)} >ZAMKNIJ DODAWANIE SZAFY</Button></> :
               <>
                  <Button fluid onClick={() => setaddServerRack(true)} color="blue">DODAJ SZAFĘ</Button>
                  <Grid stackable verticalAlign='bottom' centered columns={rackCountU && rackCountU.length > 0 && rackCountU.length < 4 ? rackCountU.length : 3} className='clearmp'>

                     {rackCountU ? rackCountU.map((eltmp, j) => {
                        return <Grid.Column key={j} >
                           <Header as="h4" textAlign='center'>{eltmp.cabinet_name}</Header>
                           <div className='housing' >
                              <div className='door'>
                                 <div className='handle'></div>
                              </div>
                              <div className='foot'></div>
                              <div className='foot'></div>
                              <ul className='rack'>

                                 {
                                    eltmp.cabinet_slots ? eltmp.cabinet_slots.map((el, i) => {

                                       return <li key={i} className={el.u_nr > 0 ? 'rowU' : "rowE"} style={{ height: (18 * (el.u_nr > 0 ? el.u_nr : 1)), lineHeight: (17 * (el.u_nr > 0 ? el.u_nr : 1)) + "px" }} >
                                          {el.name} <span style={{ right: 16, position: "absolute" }}> {" [S:" + (el.curr_u) + " U:" + (el.u_nr > 0 ? el.u_nr : 1) + "]"}</span>
                                          {el.u_nr > 0 ? <>
                                             <div className='light'></div>
                                             <div className='light'></div>
                                          </> : ""}
                                       </li>

                                    }) : ""
                                 }
                              </ul>
                           </div>
                           <Button fluid onClick={() => clickEditCabinets({ cabinet_name: eltmp.cabinet_name, cabinet_id: eltmp.cabinet_id,cabinet_slots:eltmp.slots_numbers_all})}>PRZEJDŹ DO EDYCJI SZAFY</Button>
                        </Grid.Column>

                     }

                     ) : <div style={{marginTop:10}}>BRAK ZDEFINIOWANYCH SZAF</div>}

                  </Grid>


               </>
         }
      </>
   );
}

export default SwitchServerRack;