import React, { useState, useEffect, useContext } from 'react';

import { cfgToast } from '../../../../myFunc';
import { Accordion, Grid, Icon, Label, List, Segment } from 'semantic-ui-react';
import { Button as ButtonPrime } from 'primereact/button';
import { ConnectWS } from '../../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../../context/authContext';

import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { confirmAlert } from 'react-confirm-alert';

function CommisionSettings(props) {
   const authContext = useContext(AuthContext);
   const [isloading, setisloading] = useState(false);
   const [activeIndex, setActiveIndex] = useState(-1);

   const [dataFileTemplates, setdataFileTemplates] = useState([]);

   const defaultValues = {
      name: "",
   }
   const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
   const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
   };

   const submit = (data) => {
      setisloading(true)
      ConnectWS("/commisions/files_template", "post", authContext.tokenApp, null, data, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            // getDataMail()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
         getFileTemplates()
      })
   }

   const defaultValues1 = {
      commision_files_template_id: 0,
      file_name: "",
      description: "",
   }
   const { control: control1, formState: { errors: errors1 }, handleSubmit: handleSubmit1, setValue: setValue1 } = useForm({ defaultValues1 });



   const submit1 = (data) => {
      setisloading(true)

      ConnectWS("/commisions/files_template_def", "post", authContext.tokenApp, null, [data], function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            // getDataMail()
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
         getFileTemplates()
      })
   }

   function getFileTemplates() {
      ConnectWS("/commisions/files_template", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            setdataFileTemplates(response.data)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setisloading(false)
      })
   }




   useEffect(() => {
      getFileTemplates()

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps

   function removeFileToList(commision_files_template_id, commision_files_template_def_id) {
      let titletmp = commision_files_template_def_id === null ? "Czy chcesz skasować szablon?" : "Czy chcesz skasować definicję pliku z szablonu?"
      confirmAlert({
         title: titletmp,
         message: 'Proszę potwierdzić',
         buttons: [
            {
               label: 'Tak',
               onClick: () => removeFileToListAcc(commision_files_template_id, commision_files_template_def_id)
            },
            {
               label: 'Nie',
               onClick: () => ''
            }
         ]
      });

      function removeFileToListAcc(commision_files_template_id, commision_files_template_def_id) {
         // kasowanie szablonu
         if (commision_files_template_id !== null && commision_files_template_def_id === null) {
            ConnectWS("/commisions/files_template", "delete", authContext.tokenApp, null, { commision_files_template_id: commision_files_template_id }, function (response, logout) {
               if (logout === 1) {
                  toast.error(response, cfgToast);
               } else if (Number(response.status) === 200) {
                  toast.success(response.data.detail, cfgToast);
               } else {
                  toast.error(response, cfgToast);
                  console.log(response);
               }
               getFileTemplates()

            })
         } else {
            //kasowanie pliku z szablonu
            ConnectWS("/commisions/files_template_def", "delete", authContext.tokenApp, null, { commision_files_template_id: commision_files_template_id, commision_files_template_def_id: commision_files_template_def_id }, function (response, logout) {
               if (logout === 1) {
                  toast.error(response, cfgToast);
               } else if (Number(response.status) === 200) {
                  toast.success(response.data.detail, cfgToast);
               } else {
                  toast.error(response, cfgToast);
                  console.log(response);
               }
               getFileTemplates()
            })
         }

      }

   }


   return (
      <>
         <Segment className='bgcard'>
            <Label color='blue' ribbon style={{ marginBottom: 15 }}>
               Dodaj nazwę szablonu
            </Label>

            <form onSubmit={handleSubmit(submit)} className="p-fluid">
               <div className="field" style={{ paddingTop: 10 }}>
                  <span className="p-float-label p-input-icon-right">
                     <i className="pi pi-file-edit" />
                     <Controller name="name" control={control} rules={{ required: 'Nazwa szablonu jest wymagana' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                     )} />
                     <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nazwa szablonu*</label>
                  </span>
                  {getFormErrorMessage('name')}
               </div>
               <ButtonPrime loading={isloading} type="submit" label="Dodaj szablon" className="mt-2 p-button" />
            </form>
         </Segment>
         <Segment className='bgcard'>
            <Label color='blue' ribbon style={{ marginBottom: 15 }}>
               Lista szablonów
            </Label>

            <Accordion fluid styled>
               {dataFileTemplates && dataFileTemplates.length > 0 ? dataFileTemplates.map((row, i) => (

                  <div key={i}>
                     <Accordion.Title
                        active={activeIndex === i}
                        index={i}
                        onClick={() => {
                           setActiveIndex(i)
                           setValue1("commision_files_template_id", row.commision_files_template_id)
                        }}
                     >
                        <Icon name='dropdown' />
                        {row.name} <Icon name="trash" onClick={() => removeFileToList(row.commision_files_template_id, null)} />
                     </Accordion.Title>
                     <Accordion.Content active={activeIndex === i}>
                        <form onSubmit={handleSubmit1(submit1)} className="p-fluid">
                           <Grid stackable style={{ marginTop: 5 }}>
                              <Grid.Row >

                                 <Grid.Column width={6} className="p-fluid">
                                    <div className="field" style={{ paddingTop: 10 }}>
                                       <span className="p-float-label p-input-icon-right">
                                          <i className="pi pi-file-edit" />
                                          <Controller name="file_name" control={control1} rules={{ required: 'Nazwa szablonu jest wymagana' }} render={({ field, fieldState }) => (
                                             <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                          )} />
                                          <label htmlFor="file_name" className={classNames({ 'p-error': errors1.file_name })}>Nazwa pliku*</label>
                                       </span>
                                       {getFormErrorMessage('file_name')}
                                    </div>
                                 </Grid.Column>
                                 <Grid.Column width={6} className="p-fluid">
                                    <div className="field" style={{ paddingTop: 10 }}>
                                       <span className="p-float-label p-input-icon-right">
                                          <i className="pi pi-file-edit" />
                                          <Controller name="description" control={control1} rules={{ required: 'Nazwa szablonu jest wymagana' }} render={({ field, fieldState }) => (
                                             <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                          )} />
                                          <label htmlFor="description" className={classNames({ 'p-error': errors1.description })}>Opis pliku*</label>
                                       </span>
                                       {getFormErrorMessage('description')}
                                    </div>
                                 </Grid.Column>
                                 <Grid.Column width={4} className="p-fluid">
                                    <ButtonPrime type="submit" label="Dodaj" className="mt-2 p-button" />
                                 </Grid.Column>
                              </Grid.Row>
                           </Grid>
                        </form>
                        <List divided >
                           {row.commision_files_template_def.length > 0 ? row.commision_files_template_def.map((row1, i) => (

                              <List.Item style={{ position: "relative" }} key={i}>
                                 <List.Icon className='cursorPointer' name='trash' size='large' verticalAlign='middle' onClick={() => removeFileToList(row1.commision_files_template_id, row1.commision_files_template_def_id)}/>
                                 <List.Content>
                                    <List.Header >Opis pliku: {row1.description}</List.Header>
                                    <List.Description>Nazwa pliku: {row1.file_name}</List.Description>
                                 </List.Content>
                              </List.Item>

                           )) : ""}
                        </List>
                     </Accordion.Content>
                  </div>
               )) : ""}
            </Accordion>
         </Segment></>
   );
}

export default CommisionSettings;