import React, { useState, useEffect, useContext } from 'react';
import { Card, Divider, Grid, Icon, Label, Message, Progress, Segment, Statistic } from 'semantic-ui-react';
import Ticker from '../hooks/Ticker';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { ConnectWS } from '../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import { cfgToast, getColorStatus } from '../myFunc';
import AuthContext from '../context/authContext';

function Dashboard(props) {
   const authContext = useContext(AuthContext);
   const [loadingcard_stats, setloadingcard_stats] = useState(false);
   const [loadinglast_activity, setloadinglast_activity] = useState(false);
   const [loadingsystem_status, setloadingsystem_status] = useState(false);
   const [loadingcommisionList, setloadingcommisionList] = useState(false);

   const [datacard_stats, setdatacard_stats] = useState(null);
   const [datalast_activity, setdatalast_activity] = useState(null);
   const [datasystem_status, setdatasystem_status] = useState(null);

   const [datacommisionList, setdatacommisionList] = useState(null);

   function getcard_stats() {
      setloadingcard_stats(true)
      ConnectWS("/infiber/card_stats", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {

         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               let arrtmp = []
               arrtmp.length = 0
               response.data.forEach((row) => {
                  if (row.name === "Urządzeń" || row.name === "Połączeń" || row.name === "Użytkowników") {
                     arrtmp.push(row)
                  }

               })
               setdatacard_stats(arrtmp)
            }
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setloadingcard_stats(false)
      })

   }

   function getlast_activity() {
      setloadinglast_activity(true)
      ConnectWS("/infiber/last_activity", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {

         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               setdatalast_activity(response.data)
            }
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setloadinglast_activity(false)
      })
   }

   function getsystem_status() {
      setloadingsystem_status(true)

      ConnectWS("/infiber/active_alarms", "get", authContext.tokenApp, null, null, function (response, logout) {
         if (logout === 1) {

         } else if (Number(response.status) === 200) {
            /*
            if(response.data.alarms.value>0){
               authContext.changeUpdataApp(authContext.updataApp+1)
            }
            */
            setdatasystem_status({ systemStatusErrorCount: response.data.alarms && response.data.alarms.value ? response.data.alarms.value : 0 })

         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setloadingsystem_status(false)
      })
   }

   function getcommisionList() {
      setloadingcommisionList(true)

      ConnectWS("/commisions", "get", authContext.tokenApp, null, { dashboard: 1 }, function (response, logout) {
         if (logout === 1) {

         } else if (Number(response.status) === 200) {
            /*
            if(response.data.alarms.value>0){
               authContext.changeUpdataApp(authContext.updataApp+1)
            }
            */
            if (response.data.length > 0) {
               setdatacommisionList(response.data)
            }


         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
         setloadingcommisionList(false)
      })


   }


   useEffect(() => {
      getcard_stats()
      getlast_activity()
      getsystem_status()
      getcommisionList()

   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const statusTemplate = (rowData) => {
      switch (rowData.level) {
         case "info": return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center" }} color='blue'>INFO</Label>;
         case "error": return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center" }} color='red'>ERROR</Label>;
         case "warn": return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center" }} color='orange'>WARNING</Label>;
         default: return "-"
      }

   }

   const statusCommision = (rowData) => {

      return <Label style={{ width: 80, height: 25, fontSize: 10, textAlign: "center", backgroundColor: getColorStatus(rowData.status_id), color: "#fff" }} title={rowData.status} >
         {rowData.status}
      </Label>

   }

   return (
      <>

         <Grid stackable style={{ margin: 0 }}>
            {datacard_stats || datasystem_status ?
               <Grid.Row stretched>
                  {datacard_stats ?
                     <Grid.Column width={10}>
                        <Segment raised color='blue' className='bgbox' loading={loadingcard_stats} >
                           <Label as='a' color='blue' ribbon>
                              STATYSTYKI
                           </Label>
                           <Card.Group centered style={{ marginTop: 0, marginBottom: 0 }}>
                              {datacard_stats ? datacard_stats.map((row, i) =>
                                 <Card color='grey' className='bgb' key={i}>
                                    <Card.Content textAlign='center' >
                                       {row.name}
                                       <Divider style={{ margin: 0, marginTop: 5, padding: 0 }} />
                                       <Card.Description >
                                          <Statistic color='grey'  >
                                             <Statistic.Value className='statisticLabel '   >   <Icon name={row.icon} /> <Ticker end={row.valueTotal} suffix="" /></Statistic.Value>
                                             <Statistic.Label style={{ fontSize: 10, fontWeight: "normal", margin: 0, padding: 0 }}>{row.label}</Statistic.Label>
                                          </Statistic>
                                       </Card.Description  >
                                       {row.progressBar ?
                                          <><Tooltip target={".tooltipApp" + i} mouseTrack mouseTrackLeft={10} />
                                             <Progress className={"tooltipApp" + i} data-pr-tooltip="Działające / Wszystkie" style={{ padding: 0, margin: 0 }} indicating value={row.value} total={row.valueTotal} progress='ratio' />
                                          </> : ""}
                                    </Card.Content>

                                 </Card>
                              ) : "Brak danych"}
                           </Card.Group>

                        </Segment>
                     </Grid.Column>
                     : ""}
                  {datasystem_status ?
                     <Grid.Column width={datacard_stats ? 6 : 16}>
                        {
                  /*
                  <Segment raised style={{ margin: 0, marginBottom: 5 }} color='grey'>
                     <Label style={{ marginBottom: 10 }} color='grey' ribbon>
                        STATUSY ZLECEŃ
                     </Label>
                     <Progress size='large' style={{ padding: 0, margin: 0 }} indicating value={9} total={10} progress='percent' title="aktywne/wszystkie" />
                  </Segment>
                  */}
                        <Segment className='bgbox' loading={loadingsystem_status} raised color={datasystem_status ? datasystem_status.systemStatusErrorCount > 0 ? 'red' : 'green' : 'grey'} ><Label color={datasystem_status ? datasystem_status.systemStatusErrorCount > 0 ? 'red' : 'green' : 'grey'} ribbon>
                           STATUS SYSTEMU
                        </Label>

                           <Message style={{ height: 'calc(100% - ' + 45 + 'px)' }} icon success={datasystem_status ? datasystem_status.systemStatusErrorCount > 0 ? false : true : false} negative={datasystem_status ? datasystem_status.systemStatusErrorCount > 0 ? true : false : false}  >
                              <Label className={`${"labelabs"} ${datasystem_status && datasystem_status.systemStatusErrorCount > 0 ? 'pulsingErr' : ''}`} color={datasystem_status ? datasystem_status.systemStatusErrorCount > 0 ? 'red' : 'green' : 'grey'}>{datasystem_status ? datasystem_status.systemStatusErrorCount : "-"}</Label>
                              <Icon name={datasystem_status && datasystem_status.systemStatusErrorCount > 0 ? 'warning circle' : 'check circle'} />
                              <Message.Content >

                                 <Message.Header>{datasystem_status ? datasystem_status.systemStatusErrorCount > 0 ? 'Wykryto awarię w systemie.' : 'Brak w systemie aktywnych awarii.' : 'Brak danych'}</Message.Header>
                                 {datasystem_status ? datasystem_status.systemStatusErrorCount > 0 ? 'Kliknij zakładkę ALARMY w menu i zobacz co się stało!' : '' : ''}
                              </Message.Content>
                           </Message>


                        </Segment>
                     </Grid.Column>
                     : ""}
               </Grid.Row>
               : ""}
            {datacommisionList && datacommisionList.length > 0 ?
               <Grid.Row stretched >

                  <Grid.Column width={16}>
                     <Segment raised color='grey' className='bgbox' loading={loadingcommisionList}>

                        <Label color='grey' ribbon>
                           OSTATNIE MODYFIKOWANE ZLECENIA
                        </Label>
                        <Divider />
                        <div>
                           <div className="card">
                              <DataTable showGridlines value={datacommisionList} stripedRows responsiveLayout="scroll">
                                 <Column headerStyle={{ width: '4rem' }} align="center" field="status" header="Status" body={statusCommision}> </Column>
                                 <Column field="client_name" header="Klient" align="center"></Column>
                                 <Column field="worker" header="Serwisant" align="center"></Column>
                                 <Column field="name" header="Nazwa zlecenia" align="center"></Column>
                                 <Column field="type" header="Typ zlecenia" align="center"></Column>
                                 <Column field="scheduled_start_at" header="Data rozpoczęcia" align="center"></Column>
                                 <Column field="scheduled_end_at" header="Data zakończenia" align="center"></Column>
                                 <Column field="modified_at" header="Data modyfikacji" align="center"></Column>
                              </DataTable>
                           </div>
                        </div>

                     </Segment>
                  </Grid.Column>

               </Grid.Row>
               : ""}
            <Grid.Row stretched >

               <Grid.Column width={16}>
                  <Segment raised color='grey' className='bgbox' loading={loadinglast_activity}>

                     <Label color='grey' ribbon>
                        OSTATNIA AKTYWNOŚĆ
                     </Label>
                     <Divider />
                     <div>
                        <div className="card">
                           <DataTable showGridlines value={datalast_activity} stripedRows responsiveLayout="scroll">
                              <Column headerStyle={{ width: '4rem' }} align="center" field="level" header="Status" body={statusTemplate}> </Column>
                              <Column field="created_at" header="Data" align="center"></Column>
                              <Column field="ip" header="IP" align="center"></Column>
                              <Column field="username" header="Użytkownik" align="center"></Column>
                              <Column field="description" header="Zadarzenie" align="center"></Column>
                           </DataTable>
                        </div>
                     </div>

                  </Segment>
               </Grid.Column>

            </Grid.Row>


         </Grid>






      </>
   );
}

export default Dashboard;