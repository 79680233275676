import React, { useState, useEffect, useContext } from 'react';


import { Button, Container, Dropdown, Grid, Icon, Label, Message, Segment, } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import TimerApp from '../../../layout/countDownTimer/TimerApp';
import useGeolocation from "react-hook-geolocation";
import AuthContext from '../../../context/authContext';
import GetMaps from '../../../layout/GetMaps';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { cfgToast } from '../../../myFunc';
import { confirmAlert } from 'react-confirm-alert';


function CurrentCommisions(props) {
   const authContext = useContext(AuthContext);
   const [commisionList, setcommisionList] = useState([]);
   const [currentCommision, setcurrentCommision] = useState(null)
   const geolocation = useGeolocation();

   const [isLoading, setisLoading] = useState(false);
   const [isLoadingS, setisLoadingS] = useState(false);

   function checkIsCommisionAV(loagingON) {
      if(loagingON){
         setisLoading(true)
      }
      
      ConnectWS("/commisions/worker_activity", "get", authContext.tokenApp, null, {}, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
         } else if (Number(response.status) === 200) {
            if (response.data.length > 0) {
               let rdsort = response.data.sort((a, b) => {
                  if (b.start_at === null) {
                     return -1;
                  }
                  return 1
               })
               let arrtmp = [];
               rdsort.forEach((row, i) => {
                  arrtmp.push({
                     key: i,
                     text: row.name,
                     value: JSON.stringify({ commision_id: row.commision_id, startDate: row.start_at, status_id: row.status_id }),
                  })

               })
               setcurrentCommision(arrtmp[0].value)
               setcommisionList(arrtmp)
            }
         } else {

            console.log(response);
         }
         if(loagingON){
            setisLoading(false)
         }


      })
   }


   function changeCommision(value) {
      console.log(value)
      setcurrentCommision(value)

   }

   function changeWorking(status_id) {

      confirmAlert({
         title: 'Proszę potwierdzić',
         message: status_id === 8 ? "Czy chcesz rozpocząć pracę?" : "Czy chcesz zakończyć pracę?",
         buttons: [
            {
               label: 'Tak',
               onClick: () => changeWorkingACC(status_id)
            },
            {
               label: 'Nie',
               onClick: () => ''
            }
         ]
      });

      function changeWorkingACC(status_id) {
         setisLoadingS(true)
         let commision_id = JSON.parse(currentCommision).commision_id;
         let lon = geolocation.longitude;
         let lat = geolocation.latitude;
         ConnectWS("/commisions/worker_activity", "post", authContext.tokenApp, null, { commision_id: commision_id, lon: lon, lat: lat, status_id: status_id }, function (response, logout) {
            if (logout === 1) {
               toast.error(response, cfgToast);
            } else if (Number(response.status) === 200) {
               if(status_id===8){
                  toast.info("Rejestracja czasu pracy rozpoczęta", cfgToast);
               }else if(status_id===9){
                  toast.info("Zakończono rejestrację czasu pracy", cfgToast);
               }
               
               checkIsCommisionAV(false)
            } else {
               toast.error(response, cfgToast);
               console.log(response);
            }
            setisLoadingS(false)
         })
      }

   }


   useEffect(() => {
      checkIsCommisionAV(true)

      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, []);// eslint-disable-line react-hooks/exhaustive-deps





   return (

      isLoading ? 
      <Container textAlign='center'><Icon name="spinner" size='large' loading/></Container> :
         commisionList.length < 1 ?
            <Message icon>
               <Icon name='info circle' />
               <Message.Content>
                  <Message.Header>Brak zlecenia</Message.Header>
                  Brak w systemie zlecenia na dzisiaj.
               </Message.Content>
            </Message>
            :
            <Segment color={currentCommision && JSON.parse(currentCommision).startDate !== null ? 'green' : 'grey'} className="bgcard" raised style={{ marginBottom: 30 }}>
               <Grid stackable>
                  <Grid.Row stretched>
                     <Grid.Column width={5}>

                        {commisionList.length > 0 ?
                           <>
                              <Label color='blue' size="large">Posiadasz bieżących zleceń: {commisionList.length}</Label>
                              <Container textAlign='center' style={{ marginTop: 5 }}>
                                 <span style={{ fontSize: 15 }}>
                                    {' '}
                                    <Dropdown
                                       inline
                                       value={currentCommision}
                                       options={commisionList}

                                       onChange={(e, d) => changeCommision(d.value)}
                                    />
                                 </span>


                                 {currentCommision && JSON.parse(currentCommision).startDate ?
                                    <>
                                       <br />
                                       Rozpocząłeś pracę:<br /> <b>{JSON.parse(currentCommision).startDate}</b>
                                       <TimerApp startDate={currentCommision ? JSON.parse(currentCommision).startDate : ""} textValue="" />
                                    </>

                                    :
                                    <Message info>
                                       <Message.Header>Jeszcze nie pracujesz</Message.Header>
                                       <p>Możesz rozpocząć pracę klikając poniższy przycisk</p>
                                    </Message>}

                              </Container>


                           </>
                           : ""}
                     </Grid.Column>

                     <Grid.Column width={11} style={{ height: 160 }} >
                        {!geolocation.error ? geolocation && geolocation.latitude ?
                           <div style={{ position: "relative" }}>
                              <div style={{ position: "absolute", zIndex: 10000, bottom: 0, backgroundColor: "white", height: 17, paddingRight: 5, opacity: "0.8", fontSize: 10, color: "#2196f3", fontWeight: "bold" }} >
                                 <Icon name='map marker' color='blue' />  Moja lokalizacja
                              </div>
                              <GetMaps myLocation={[geolocation.latitude, geolocation.longitude]} />
                           </div>
                           : <Message icon info>


                              <Icon color='blue' name='map marker' />

                              <Message.Content>
                                 <Message.Header>Czekam na pobierania lokalizacji.</Message.Header>
                                 <Icon name="spinner" loading />Proszę czekać...
                              </Message.Content>
                           </Message> :
                           <Message icon error>


                              <Icon color='red' name='map marker' />

                              <Message.Content>
                                 <Message.Header>Błąd pobierania lokalizacji.</Message.Header>
                                 Proszę zezwolić na dostęp do lokalizacji.
                              </Message.Content>
                           </Message>
                        }
                     </Grid.Column>
                  </Grid.Row>

               </Grid>
               {commisionList.length < 1 ? "" :
                  <div style={{ marginTop: 5 }}>
                     {currentCommision && JSON.parse(currentCommision).startDate ? <Button size='massive' onClick={() => changeWorking(9)} fluid color='red' loading={isLoadingS} disabled={geolocation && geolocation.latitude ? false : true}>
                        ZAKOŃCZ PRACĘ
                     </Button> :
                        <Button size='massive' fluid color='green' onClick={() => changeWorking(8)} loading={isLoadingS} disabled={geolocation && geolocation.latitude ? false : true}>
                           ROZPOCZNIJ PRACĘ
                        </Button>}
                  </div>
               }

            </Segment>

   );
}

export default CurrentCommisions;