import React, { useEffect, useState, useContext } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { AG_GRID_LOCALE_PL } from '../../AGGrid/LanguageAGG';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ConnectWS } from '../../../ConnectWS/ConnectWS';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/authContext';
import { cfgToast, changeFormatDate, changeFormatDateTime, secondsToHms } from '../../../myFunc';
import AGGridActivity from '../../AGGrid/AGGridActivity';
import moment from 'moment';


function CommissionWorkerReportDetails(props) {
   const authContext = useContext(AuthContext);
   const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
   const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
   const [rowData, setRowData] = useState(null);
   const [isWorker, setWorker] = useState(false);

   const [rowDataOvertime, setrowDataOvertime] = useState(0);
   const [rowDataWorkTime, setrowDataWorkTime] = useState(0);


   const [scheduled_start_attmp, setscheduled_start_attmp] = useState(null);
   const [scheduled_end_attmp, setscheduled_end_attmp] = useState(null);

   const [isloading1, setisloading1] = useState(false) // eslint-disable-line no-unused-vars




   const onGridReady = (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };



   function getWorkerReportD() {
      console.log(props)
      setisloading1(true)
      let worker_id = props.allInfo.worker_id
      let work_month = props.allInfo.allInfo.work_month
      let scheduled_start_at = changeFormatDate(work_month + "-01")
      let scheduled_end_at = changeFormatDate(moment(scheduled_start_at, "YYYY-MM-DD").add(1, 'month'))

      setscheduled_start_attmp(scheduled_start_at)
      setscheduled_end_attmp(scheduled_end_at)

      if (gridApi && gridApi.destroyCalled === false) {
         gridApi.showLoadingOverlay();
      }
      ConnectWS("/commisions/worker_report_details", "get", authContext.tokenApp, null, { scheduled_start_at: scheduled_start_at, scheduled_end_at: scheduled_end_at, worker_id: worker_id }, function (response, logout) {
         if (logout === 1) {
            toast.error(response, cfgToast);
            if (gridApi && gridApi.destroyCalled === false) {
               gridApi.showNoRowsOverlay();
            }
         } else if (Number(response.status) === 200) {
            if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
               if (gridApi && gridApi.destroyCalled === false) {
                  gridApi.showNoRowsOverlay();
               }
            } else {
               let arrtmp = [];
               let rowDataOvertimetmp = 0
               let rowDataWorkTimetmp = 0
               response.data.forEach((row) => {
                  if (row.overtime > 0) {
                     rowDataOvertimetmp = rowDataOvertimetmp + row.overtime
                  }
                  if (row.worktime > 0) {
                     rowDataWorkTimetmp = rowDataWorkTimetmp + row.worktime
                  }
                  arrtmp.push(row)
               })
               setrowDataOvertime(rowDataOvertimetmp)
               setrowDataWorkTime(rowDataWorkTimetmp)
               setRowData(arrtmp)
               if (gridApi && gridApi.destroyCalled === false) {
                  gridApi.hideOverlay();
               }
            }

         } else {
            toast.error(response, cfgToast);
            console.log(response);
            if (gridApi && gridApi.destroyCalled === false) {
               gridApi.showNoRowsOverlay();
            }
         }

         setisloading1(false)
      })
   }


   useEffect(() => {
      if (authContext.userInfo && null !== authContext.userInfo.worker && authContext.userInfo.worker === 1) {
         setWorker(true)
      } else {
         setWorker(false)
      }
      getWorkerReportD()
      return () => { }; // use effect cleanup to set flag false, if unmounted
   }, [authContext.updataApp]);// eslint-disable-line react-hooks/exhaustive-deps




   function isValid(d) {
      // An invalid date object returns NaN for getTime() and NaN is the only
      // object not strictly equal to itself.
      return d.getTime() === d.getTime();
   };

   const onCellValueChanged = (data) => {

      if (isWorker) {
         return
      }

      let commision_user_status_id = 0
      let admin_create_time = null

      var d = new Date(data.newValue);

      if (!isValid(d) && data.newValue !== "") {
         toast.error("Wpisz prawidłowa datę!", cfgToast);
         authContext.changeUpdataApp(authContext.updataApp + 1)
         return
      } else if (data.newValue === "") {
         admin_create_time = null
      } else {
         admin_create_time = changeFormatDateTime(d)
      }

      console.log(admin_create_time)

      if (data.colDef.headerName.toUpperCase().includes("ROZPOCZĘCIE PRACY ZMODYFIKOWANE PRZEZ ADMINA")) {
         commision_user_status_id = data.data.commision_status_start_id

      } else if (data.colDef.headerName.toUpperCase().includes("ZAKOŃCZENIE PRACY ZMODYFIKOWANE PRZEZ ADMINA")) {
         commision_user_status_id = data.data.commision_status_end_id

      }




      ConnectWS("/commisions/worker_commision_activity", "put", authContext.tokenApp, null, { commision_user_status_id: commision_user_status_id, admin_create_time: admin_create_time }, function (response, logout) {
         if (logout === 1) {
            toast.error("Brak autoryzacji 401", cfgToast);
         } else if (Number(response.status) === 200) {
            toast.success(response.data.detail, cfgToast);
            authContext.changeUpdataApp(authContext.updataApp + 1)
         } else {
            toast.error(response, cfgToast);
            console.log(response);
         }
      })


   }

   const WRovertime = props => {
      return props.data.overtime ? secondsToHms(props.data.overtime) : ""
   };

   const WRworktime = props => {
      return props.data.worktime ? secondsToHms(props.data.worktime) : ""
   };

   function retMomentH(data) {
      if (data) {
         return secondsToHms(data)
      }
      return 0
   }

   return (
      <div style={{ minHeight: props.minHeight - 60, marginTop: 5 }}>
         {/* 
            overtime  {props.allInfo.allInfo.overtime} - {retMomentH(props.allInfo.allInfo.overtime)}<br />
            worktime  {props.allInfo.allInfo.worktime} - {retMomentH(props.allInfo.allInfo.worktime)}<br />
            rowDataOvertime {rowDataOvertime} - {retMomentH(rowDataOvertime)}<br />
            rowDataWorkTime {rowDataWorkTime} - {retMomentH(rowDataWorkTime)}
            */}
         {props.allInfo && props.allInfo.allInfo.worker_name ?
            <Header block>
               <Grid columns={4} divided textAlign='center'>
                  <Grid.Row >
                     <Grid.Column>
                        <b> {props.allInfo.allInfo.worker_name}</b>
                     </Grid.Column>
                     <Grid.Column>
                        {scheduled_start_attmp} - {scheduled_end_attmp}
                     </Grid.Column>
                     <Grid.Column>
                        Czas pracy: {retMomentH(rowDataWorkTime)} ({rowDataWorkTime}s)
                     </Grid.Column>
                     <Grid.Column>
                        Nadgodziny: {retMomentH(rowDataOvertime)} ({rowDataOvertime}s)
                     </Grid.Column>
                    

                  </Grid.Row>
               </Grid>
            </Header> : ""}

         <AGGridActivity quickSearchHidden={false} gridApi={gridApi} gridColumnApi={gridColumnApi} />
         <div
            id="myGrid"
            style={{
               height: props.minHeight - 160,
               // height:  '100%',
               width: '100%',
               marginTop: 5
            }}
            className="ag-theme-balham"
         >
            <AgGridReact
               //rowHeight={rowHeightAGG}

               suppressRowClickSelection={true}
               onCellValueChanged={onCellValueChanged}
               animateRows={true}
               pagination={true}
               defaultColDef={{
                  editable: false,
                  sortable: true,
                  filter: false,
                  width: 100,
                  minWidth: 100,
                  //przyśpiesza odświeżanie
                  //wrapText: true,
                  //autoHeight: true,
                  resizable: true,
                  floatingFilter: false,
                  flex: 1,
               }}
               frameworkComponents={{
                  WRovertime: WRovertime,
                  WRworktime: WRworktime,
               }}

               getRowId={function (data) {
                  return data.data.commision_status_start_id
               }}
               localeText={AG_GRID_LOCALE_PL}
               rowData={rowData}
               onGridReady={onGridReady}
            >


               <AgGridColumn field="worker_name" headerName='Serwisant' filter='agTextColumnFilter' width={130}
                  minWidth={130}
                  flex={0} />
               <AgGridColumn field="commision_name" headerName='Zlecenie' filter='agTextColumnFilter' width={230}
                  minWidth={230}
                  flex={0} />
               <AgGridColumn field="work_start" headerName='Rozpoczęcie pracy' filter='agTextColumnFilter' width={150}
                  minWidth={150}
                  flex={0} />

               <AgGridColumn field="work_start_admin" headerName='Rozpoczęcie pracy zmodyfikowane przez admina' editable={true} filter='agTextColumnFilter' width={150}
                  minWidth={150}
                  flex={0} />
               <AgGridColumn field="work_end" headerName='Zakończenie pracy' filter='agTextColumnFilter' width={150}
                  minWidth={150}
                  flex={0} />

               <AgGridColumn field="work_end_admin" headerName='Zakończenie pracy zmodyfikowane przez admina' editable={true} filter='agTextColumnFilter' width={150}
                  minWidth={150}
                  flex={0} />


               <AgGridColumn cellRenderer='WRworktime'
                  filter='agTextColumnFilter'
                  headerName='Czas pracy'
               />
                <AgGridColumn field='worktime'
                  filter='agNumberColumnFilter'
                  headerName='Czas pracy [s]'
               />
               <AgGridColumn cellRenderer='WRovertime'
                  filter='agTextColumnFilter'
                  headerName='Czas nadgodzin'
               />
               <AgGridColumn field='overtime'
                  filter='agNumberColumnFilter'
                  headerName='Czas nadgodzin [s]'
               />


            </AgGridReact>
         </div>





      </div>
   );
}

export default CommissionWorkerReportDetails;