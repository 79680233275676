import React, { useState, useEffect } from 'react';
import styles from './Login.module.css';
import infiberlogo from '../../assets/images/in-fiberlb.png';
import { useCookies } from 'react-cookie';
import { Header, Segment, Icon, Message } from 'semantic-ui-react';
import useAuth from '../../hooks/useAuth';
import { SCezardecrypt, SCezarencrypt } from '../../myFunc';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useForm, Controller } from 'react-hook-form';
import { ConnectWS } from '../../ConnectWS/ConnectWS';
import jwt_decode from "jwt-decode";
import moment from 'moment';


function Login(props) {
    const [CheckboxS, setCheckboxS] = useState(false);
    const [isloading, setLoading] = useState(false);// eslint-disable-line no-unused-vars
    const [error, setError] = useState("");// eslint-disable-line no-unused-vars

    const [isErrorLoginCount, setisErrorLoginCount] = useState(0);
    const [isErrorLoginCountBlock, setisErrorLoginCountBlock] = useState(3);// eslint-disable-line no-unused-vars
    const [isBlockA, setisBlockA] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies(['UDAPP','_ga_google']);// eslint-disable-line no-unused-vars
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const defaultValues = {
        login: '',
        password: '',
    }
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });


    useEffect(() => {
        if (cookies.UDAPP) {
            try {
                let cookieDataTmp = JSON.parse(SCezardecrypt(JSON.stringify(cookies.UDAPP)))
                // setFormE({ ...FormE, username: cookieDataTmp.login, password: cookieDataTmp.password })
                setValue("login", cookieDataTmp.login)
                setValue("password", cookieDataTmp.password)
                setCheckboxS(true)
            } catch (err) {
                console.log(err)
            }
        }
        checkIsBlockLogin()
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, []);// eslint-disable-line react-hooks/exhaustive-deps




    const onChangeCheckbox = (checked) => {

        if (checked === true) {

        }
        else {

            removeCookie('UDAPP')
        }
        setCheckboxS(checked)

    }

    function checkIsBlockLogin(){
        if (cookies._ga_google) {
            setisBlockA(true)
        }else{
            setisBlockA(false)
        }
       
    }

    function getTimeBlock(){
        if (cookies._ga_google) {
            try {
                let cookieDataTmp = JSON.parse(SCezardecrypt(JSON.stringify(cookies._ga_google)))
                var date1 = moment(); //todays date
                var date2 = moment(cookieDataTmp.exp); // another date
                let timedifftmp = moment.duration(date2.diff(date1));
                let timediff = timedifftmp._milliseconds
                let remainingMinute = Math.floor((timediff % (1000 * 60 * 60)) / (1000 * 60))
                let remainingSecond = Math.floor((timediff % (1000 * 60)) / 1000)
                return <>{remainingMinute} min {remainingSecond} sec</>
            } catch (err) {
                return "err"
            }
        }else{
            return ""
        }
    }



    const submit = (data) => {
        setError("");

        let login = data.login
        let password = data.password

        if (login && password) {

            let expiresdate = new Date();
            let today = new Date();
            expiresdate.setDate(today.getDate() + 30);
            if (CheckboxS === true) {
                setCookie('UDAPP', SCezarencrypt(JSON.stringify({ login: login, password: password })), { path: '/', expires: expiresdate, sameSite: 'lax' });
            }


            if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
                if (login === "aa" && password === "bb") {

                } else {
                    return setError("Network error");
                }


            }

            var bodyFormData = new FormData();
            bodyFormData.append('username', login);
            bodyFormData.append('password', password);

            let tokenttmp = null
            setLoading(true)
            ConnectWS("/auth/token", "post", null, bodyFormData, null, function (response, logout) {
                if (logout === 1) {
                    console.log(response);
                    setError(response);
                    setLoading(false)
                    setisErrorLoginCount(isErrorLoginCount + 1)
                    if(isErrorLoginCount + 1>=isErrorLoginCountBlock){
                        let expiresdate = new Date();
                        let today = new Date();
                        expiresdate.setMinutes(today.getMinutes() + 1);
                        setCookie('_ga_google', SCezarencrypt(JSON.stringify({ exp: expiresdate })), { path: '/', expires: expiresdate, sameSite: 'lax' });
                        setisBlockA(true)
                    }
                } else if (Number(response.status) === 200) {
                    setisBlockA(false)
                    setisErrorLoginCount(0)
                    tokenttmp = response.data.access_token
                    let decoded = null
                    if (Number(process.env.REACT_APP_API_LOCALE) === 1) {
                        decoded = { type: 1 }
                    } else {
                        decoded = jwt_decode(tokenttmp)
                    }

                    ConnectWS("/users/me", "get", tokenttmp, null, null, function (response, logout) {
                        if (logout === 1) {
                            console.log(response);
                            setError(response);

                        } else if (Number(response.status) === 200) {
                            let userInfo = { id: response.data.id, name: response.data.name + " " + response.data.surname, worker: decoded.type === 1 ? 1 : 0 }
                            reset();
                            setAuth(true, tokenttmp, userInfo);
                        } else {

                            console.log(response);
                            setError(response);

                        }
                        setLoading(false)
                    })

                } else {
                    setLoading(false)
                    //addToast('Error przy pobieraniu funkcji: ' + response, { appearance: 'error' });
                    console.log(response);
                    setError(response);
                    // gridApi.showNoRowsOverlay();
                }

            })





        } else {
            setError("BRAK LOGINU I HASŁA");
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (

        <div className={styles.wrappergrid} >
            <div className={styles.wrappergridcontent}>

                <Segment stacked color="blue" style={{ margin: 0 }}>



                    <div style={{ textAlign: "center", margin: 20 }}><img src={infiberlogo} alt="IN_FIBER" className={styles.imagelogo} /></div>
                    <center>
                        <Header as='h4' color="grey" >
                            <Icon name='settings' />
                            <Header.Content>
                                IN-XXX-APP
                            </Header.Content>
                        </Header></center>

                    <div className="card" style={{ marginTop: 20 }}>
                        <form onSubmit={handleSubmit(submit)} className="p-fluid">

                            <div className="field">
                                <span className="p-float-label p-input-icon-right">
                                    <i className="pi pi-user" />
                                    <Controller name="login" control={control} rules={{ required: 'Login jest wymagany' }} render={({ field, fieldState }) => (
                                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                    )} />
                                    <label htmlFor="login" className={classNames({ 'p-error': errors.login })}>Login*</label>
                                </span>
                                {getFormErrorMessage('login')}
                            </div>
                            <div className="field" style={{ paddingTop: 10 }}>
                                <span className="p-float-label">
                                    <Controller name="password" control={control} rules={{ required: 'Hasło jest wymagane' }}

                                        render={({ field, fieldState }) => (

                                            <Password id={field.name} {...field} ref={(ref) => { }} feedback={false} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />

                                        )}
                                    />
                                    <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Hasło*</label>
                                </span>
                                {getFormErrorMessage('password')}
                            </div>



                            <div className="field-checkbox">
                                <Checkbox inputId="accept" name="accept" checked={CheckboxS ? true : false} onChange={(e) => onChangeCheckbox(e.checked)} />
                                <label htmlFor="accept" >Pamiętaj moje dane logowania</label>
                            </div>
                            <Button type="submit" label="Zaloguj się" className="mt-2" loading={isloading} disabled={isBlockA} />
                        </form>
                    </div>

                    {error ? (
                        <>
                            <Message negative>
                                <Message.Header><Icon name="exclamation" /> {error}</Message.Header>
                            </Message>
                        </>
                    ) : ''}

                    {isErrorLoginCount > 0 && !isBlockA ? (
                        <div style={{textAlign:"center",marginTop:5}}> Pozostało prób logowania: {isErrorLoginCountBlock - isErrorLoginCount}
                        </div>
                    ) : ''}
                    {isBlockA ? (
                        <div style={{textAlign:"center",marginTop:5}}>
                           Logowanie zablokowane <br/> pozostało: {getTimeBlock()}
                            
                        </div>
                    ) : ''}




                </Segment>

            </div>
        </div>

    );
}



export default Login;